import { createSvgIcon } from '@mui/material';
import { jsx } from '@emotion/react';

export const LogoParallelSearch = createSvgIcon(
  [
    jsx('path', { d: 'M12 4c-4.423 0-8 3.59-8 8s3.59 8 8 8V4Z', fill: '#0D283E', key: 0 }),
    jsx('path', { d: 'M12 4c4.423 0 8 3.59 8 8s-3.577 8-8 8V4Z', fill: '#219CE8', key: 1 }),
  ],
  'LogoParallelSearch'
);
