/** Type returned by search API for an individual document */
export enum DocumentType {
  CASE = 'case'
}

/**
 * Document type "namespace"
 *
 * This got copied from "lib/shared/document/document-types.ts".
 * Because of React scope and Babel namespace restrictions, we can't use the existing type file.
 */
export class DocType {
  /** Returns human-readable singular/plural representations of a document type */
  static HumanReadableNames: Map<DocumentType, { singular: string; plural: string }> = new Map([
    [DocumentType.CASE, { singular: 'case', plural: 'cases' }]
  ]);

  /**
   * Converts regular string to DocumentType type
   *
   * @throws When string can't get matched to DocumentType
   */
  static fromString(typeString: string): DocumentType {
    for (const key of Object.keys(DocumentType)) {
      // @ts-ignore
      const dt: DocumentType = DocumentType[key];
      if (dt.toString() === typeString) {
        return dt;
      }
    }
    throw new Error(`Invalid string '${typeString}' does not match a DocumentType.`);
  }
}
