/*
 * Axios interceptor service
 */
import axios from 'axios';
import { isObject, isString } from 'lodash';
import ChainedCustomError from 'typescript-chained-error';

import { CANCELLED_REQUEST } from '../../@utils/api';

class ClientAxiosError extends ChainedCustomError {
  status?: number;
  data?: any;

  constructor(message = '', status?: number, data?: any) {
    super(message);
    this.status = status;
    this.data = data;
  }
}

const axiosInterceptor = {
  setupInterceptors: () => {
    // Add a response interceptor
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (response) => {
        let errorText = 'unknown error';

        if (response.response) {
          // if (response.response.status === 401) {
          //   console.error('unauthorized');
          //   // This should be sufficent for now since we have to redirect to angular app,
          //   // but once we have a single app again, we will need to dispatch a redux action to log out
          //   // the user (ie set user to null), and then possibly redirect.
          //   window.location.href = `${config.DOMAIN}/login`;
          //   throw new axios.Cancel('Cancel due to UNAUTHORIZED');
          // }

          if (isString(response.response.data)) {
            errorText = response.response.data;
          } else if (isObject(response.response.data) && !!response.response.data.message) {
            errorText = response.response.data.message;
          } else if (
            isObject(response.response.data) &&
            isObject(response.response.data.error) &&
            !!response.response.data.error.message
          ) {
            errorText = response.response.data.error.message;
          }
        } else if (response.message === 'canceled') {
          errorText = CANCELLED_REQUEST;
        }

        const status = response.response && response.response.status;
        const data = response.response && response.response.data;
        const err = new ClientAxiosError(errorText, status, data);
        return Promise.reject(err);
      }
    );
  }
};

export default axiosInterceptor;
