import { Button, useMediaQuery, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'utils/hooks/redux';
import { selectUserQuery, setShowPSPopover, updateActiveSearchFilters } from 'store/searchSlice';
import { IN_APP_ROUTES, ModalState, SearchInputStates, SearchResultType } from '@utils';
import { selectSearchEdModalState, setSearchEdModalState, setSearchInputOpenState } from 'store/modalSlice';
import { useNavigate } from 'react-router-dom';
import { ClientAnalyticsEvent } from '@utils/analytics-events';
import AnalyticsService from 'services/analytics/AnalyticsService';

import { Heading, LinkButton, Text } from '../design_component_lib';

import './SearchEdContent.scss';

const SearchEdContent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const searchQuery = useAppSelector(selectUserQuery);
  const searchStr = useAppSelector(selectSearchEdModalState).searchStr;
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  /** Function to navigate to keyword search results */
  const continueToResults = () => {
    AnalyticsService.Instance.track(ClientAnalyticsEvent.CLICKED_PS_MODAL_LINK, { searchQuery });
    // Update filters
    dispatch(
      updateActiveSearchFilters({
        type: SearchResultType.CASE,
        tab: null,
        page: 1
      })
    );
    // Close the modal and make sure it doesn't pop up again in session
    dispatch(setSearchEdModalState({ modalState: ModalState.CLOSED, userSeen: true }));
    // Navigate to results
    navigate({
      pathname: IN_APP_ROUTES.SEARCH_RESULTS,
      search: searchStr
    });
  };

  /** Function to go back to search input with education popover */
  const goToSearchEdTour = () => {
    AnalyticsService.Instance.track(ClientAnalyticsEvent.CLICKED_PS_MODAL_CTA, { searchQuery });
    dispatch(setSearchInputOpenState(SearchInputStates.DEFAULT));
    dispatch(setShowPSPopover(true));
    dispatch(setSearchEdModalState({ modalState: ModalState.CLOSED, userSeen: true }));
  };

  return (
    <>
      <div className="search-ed">
        <div className="search-ed-heading">
          <Heading className={'search-ed-matched-results'} size={isSmDown ? 'md' : 'xl'}>
            {`We found results matching "${searchQuery}"`}
          </Heading>
          <LinkButton
            sx={{ margin: 'auto' }}
            size={isSmDown ? 'sm' : 'md'}
            label={'See Results'}
            chevron={true}
            onClick={continueToResults}
          />
        </div>
        <div className="ct-callout-box">
          <Heading className="search-ed-callout-title" size={isSmDown ? 'xl' : '2xl'}>
            Find relevant results more easily
          </Heading>
          <Text
            sx={{ lineHeight: '1.438rem' }}
            size={isSmDown ? 'sm' : 'md'}
            color="secondary"
            className="ct-callouts-sub"
          >
            Most attorneys find it more intuitive to search with regular sentences describing their facts and arguments.
          </Text>
          <Button
            sx={{ marginTop: '1.875rem', backgroundColor: '#FFFFFF' }}
            variant="outlined"
            onClick={goToSearchEdTour}
            size={isSmDown ? 'medium' : 'large'}
          >
            <Text size={isSmDown ? 'sm' : 'md'} color="inherit">
              Show Me the Easier Way
            </Text>
          </Button>
        </div>
      </div>
    </>
  );
};

export default SearchEdContent;
