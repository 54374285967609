import { Square } from '@mui/icons-material';
import classnames from 'classnames';

import { TreatmentCollection } from '../../../services/treatments/TreatmentCollection';

import './ResultTreatment.scss';

interface ResultTreatmentProps {
  citator: any;
}

/**
 * The treatment box component.
 */
export function ResultTreatment({ citator }: ResultTreatmentProps) {
  const treatmentCollection = new TreatmentCollection(citator);
  return treatmentCollection.hasAtLeastOneTreatment('negative') ? (
    <Square classes={{ root: classnames('result-treatment-box', treatmentCollection.smallSquareClass) }} />
  ) : null;
}

export default ResultTreatment;
