import { styled } from '@mui/system';

import colors from '../../../utils/themes/colors';

export const textColorList = {
  inherit: 'inherit',
  primary: colors.gray[900],
  secondary: colors.gray[700],
  error: colors.red[500],
  blue: colors.brandblue[500],
  light: colors.gray[300]
};
export type TextColor = keyof typeof textColorList;
const textSizeList = {
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
  '3xl': '1.875rem'
};
export type TextSize = keyof typeof textSizeList;
export type TextWeight = '100' | '200' | '300' | '500' | '600' | '800' | '900';
export interface TextProps {
  bold?: boolean;
  color?: TextColor;
  size?: TextSize;
  weight?: TextWeight;
}

/**
 * General text element with options for color, size, and weight
 */
export const Text = styled('span', {
  shouldForwardProp: (prop) => ['children', 'data-testid'].includes(prop as string)
})<TextProps>(({ bold, color = 'primary', size = 'md', weight }) => ({
  color: textColorList[color],
  fontSize: textSizeList[size],
  fontWeight: bold ? 'bold' : weight || 'normal'
}));

/**
 * Text elements with ellipsis
 */
export const TextEllipsis = styled(Text, { shouldForwardProp: () => true })({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%'
});
