import React from 'react';
import { JX } from '@casetext/libjx';
import { getEnrichedJxPrompt } from '@utils';

import { UiLabel } from '../../design_component_lib';
import { LOG } from '../../../services/logging/LoggingService';

interface ResultSubheaderProps {
  /** The search result item data */
  doc: SearchResult;
}

/**
 * Component to render a search result item's subheader line (citation, jx info, authors, etc.)
 *
 */
const ResultSubheader = ({ doc }: ResultSubheaderProps): JSX.Element => {
  /**
   * Converts unix timestamp to date string ("July 14, 2022")
   *
   * @param timestamp
   */
  const formatDate = (timestamp: number): string => {
    let dateString = '';
    try {
      dateString = new Date(timestamp).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
    } catch (err: any) {
      LOG.error(`ResultSubheader: Can't format date`, timestamp, err && err.message);
    }
    return dateString;
  };

  /**
   * Returns enhanced jx prompt if available ("D.D.C." -> "DC Cir. - Dist. Ct.")
   *
   * @param jxCode
   */
  const formatJx = (jxCode: string): string => {
    let pmt;
    try {
      const jxId = JX.getByCode(jxCode);
      const jxObj = JX.get(jxId || '');
      pmt = getEnrichedJxPrompt(jxObj);
    } catch (err: any) {
      LOG.error(`ResultSubheader: Can't format jx`, jxCode, err && err.message);
    }
    return pmt || jxCode;
  };

  return (
    <UiLabel as="div" size="xs" color="secondary" className="ct-search-result-subheader">
      {(!!doc.citationString || !!doc.jurisdictionCode || !!doc.jurisdiction) && (
        <span className="ct-search-result-citation">
          {doc.citationString || doc.jurisdictionCode || formatJx(doc.jurisdiction)}
        </span>
      )}
      {!!doc.citationCount && (
        <span className="ct-search-result-cite-count">Cited {doc.citationCount.toLocaleString('en-US')} times</span>
      )}
      {!!doc.insights?.totalCount && (
        <span className="ct-search-result-analyses-count">{doc.insights.totalCount} Legal Analyses</span>
      )}
      {!!doc.orgName && <span className="ct-search-result-org-name">{doc.orgName}</span>}
      {!!doc.authors && !!doc.authors.length && <span className="ct-search-result-author">{doc.authors[0]}</span>}
      {(!!doc.date || !!doc.filedDate || !!doc.created) && (
        <span className="ct-search-result-date">
          {!!doc.date || !!doc.filedDate ? 'Filed ' : ''}
          {formatDate(doc.date || doc.filedDate || doc.created)}
        </span>
      )}
      {!!doc.created && (
        <span className="ct-search-result-date">
          {'Date added: '}
          {formatDate(doc.date || doc.filedDate || doc.created)}
        </span>
      )}
    </UiLabel>
  );
};

export default ResultSubheader;
