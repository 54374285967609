import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, Grid, IconButton } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/system';
import { jxKeyFromId } from '@utils/jurisdiction';

import CheckboxTri from './CheckboxTri';

interface AccordionCheckboxProps {
  childIds?: string | TreeItem;
  countMap?: Record<string, number>;
  expanded?: boolean;
  id: string;
  isJxFilter?: boolean;
  isRoot?: boolean;
  label?: string;
  labelMap?: (key: string) => string | JSX.Element;
  onClick?: (key: string, checked: boolean) => void;
  parentId?: string;
  state: Record<string, Tristate>;
}

const BaseAccordion = styled((props: AccordionProps) => <Accordion disableGutters elevation={0} square {...props} />)({
  backgroundColor: 'white'
});

const AccordionCheckDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: 0,
  paddingLeft: theme.spacing(3.25)
}));

const AccordionCheckSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: 0,
  padding: 0,

  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    marginBottom: 0,
    marginTop: 0,

    ':hover': {
      color: theme.palette.primary.main
    }
  }
}));

/**
 * Component to generate a list of controlled checkboxes
 * @param childIds - `string`|`TreeItem` - List of children or the id of the checkbox
 * @param expanded - Forces list to be expanded
 * @param id - Identifier of the checkbox
 * @param isJxFilter - Controls whether JX specific functions are used. Defaults to false.
 * @param isRoot - Makes the checkbox label bold
 * @param label - Label for the checkbox
 * @param labelMap - `(id) => string` Function to determine label (if not provided)
 * @param onClick - `(id, checked) => void` Handler for checking/unchecking
 * @param parentId - Parent id (optional)
 * @param state - State to pull values from **SHOULD CHANGE TO SOMETHING MORE PERFORMANT**
 */
const AccordionCheckbox = ({
  childIds,
  countMap = {},
  expanded,
  id,
  isJxFilter,
  isRoot,
  label,
  labelMap,
  onClick = () => null,
  parentId,
  state
}: AccordionCheckboxProps): JSX.Element => {
  const [isExpanded, setExpanded] = useState(expanded || false);
  const checkId = parentId && isJxFilter ? jxKeyFromId(id) : id;
  const checkLabel = label || (labelMap ? labelMap(id) : '');
  const checkValue = state[checkId];
  const count = countMap[id];
  const hasChildren = childIds instanceof Object;

  const Expand = (
    <IconButton onClick={() => setExpanded((exp) => !exp)}>
      <ExpandMore fontSize="small" />
    </IconButton>
  );

  return (
    <Grid item xs={12}>
      <BaseAccordion expanded={isExpanded} TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
        <AccordionCheckSummary expandIcon={hasChildren && Expand}>
          <CheckboxTri
            checked={checkValue}
            endInset={hasChildren ? 0.5 : 5}
            id={checkId}
            isRoot={isRoot}
            label={checkLabel}
            metaLabel={count ? count.toLocaleString() : undefined}
            onClick={onClick}
            size="small"
          />
        </AccordionCheckSummary>
        {hasChildren && (
          <AccordionCheckDetails>
            {Object.entries(childIds || {}).map(([cId, cIds]) => (
              <AccordionCheckbox
                childIds={cIds}
                countMap={countMap}
                id={cId}
                isJxFilter={isJxFilter}
                isRoot={false}
                key={cId}
                labelMap={labelMap}
                onClick={onClick}
                parentId={checkId}
                state={state}
              />
            ))}
          </AccordionCheckDetails>
        )}
      </BaseAccordion>
    </Grid>
  );
};

export default AccordionCheckbox;
