/**
 * Logging Service
 */
class LoggingService {
  protected static _instance: LoggingService;
  protected shouldLog: boolean;

  /**
   * @constructor
   */
  protected constructor() {
    this.shouldLog = !process.env.REACT_APP_SUPPRESS_LOGGING || process.env.REACT_APP_SUPPRESS_LOGGING !== 'true';
  }

  /**
   * Singleton accessor.
   *
   * @constructor
   */
  public static get Instance(): LoggingService {
    return this._instance || (this._instance = new LoggingService());
  }

  public log(...args: any[]) {
    if (this.shouldLog) {
      console.log(...args);
    }
  }

  public warn(...args: any[]) {
    if (this.shouldLog) {
      console.warn(...args);
    }
  }

  public error(...args: any[]) {
    if (this.shouldLog) {
      console.error(...args);
    }
  }
}

export default LoggingService;
export const LOG = LoggingService.Instance;
