/**
 * The event names for client-side analytics events.
 *
 * @see https://docs.google.com/spreadsheets/d/1O1C_-mVlLULIZQI1fPdyqGZqQhHxUTbW1tzT28jNhjI/edit#gid=1120426822 for
 *      descriptions of each.
 */
export enum ClientAnalyticsEvent {
  /** User applied jurisdiction filter */
  APPLIED_JX_FILTER = 'appliedJurisdictionFilter',
  /** User clicked continue in additional information modal */
  CLICKED_CONTINUE_IN_ADD_INFO_MOD = 'clickedContinueInAddInfoMod',
  /** User filled out an email (valid format) and clicked continue */
  CLICKED_CONTINUE_IN_EMAIL_MOD = 'clickedContinueInEmailMod',
  /** User clicked on copy with cite cta */
  CLICKED_COPY_WITH_CITE = 'clickedCopyWithCite',
  /** User clicked on free trial CTA from a database teaser */
  CLICKED_DB_TEASER_CTA = 'clickedDBTeaserCTA',
  /** User clicked on free trial CTA from a filter teaser */
  CLICKED_FILTER_TEASER_CTA = 'clickedFilterTeaserCTA',
  /** User clicks 'Show me the easier way' CTA in a card in search results */
  CLICKED_PS_CARD_CTA = 'clickedPScardCTA',
  /** User clicks 'Show me the easier way' CTA in Parallel Search ed modal */
  CLICKED_PS_MODAL_CTA = 'clickedPSModalCTA',
  /** User clicks 'see results' in Parallel Search ed modal */
  CLICKED_PS_MODAL_LINK = 'clickedPSModalLink',
  /** User clicked on a a primary law database in the search results sidebar */
  CLICKED_PRIMARY_LAW = 'clickedPrimaryLaw',
  /** User clicked on a search result header */
  CLICKED_RESULT_HEADER = 'clickedResultsHeader',
  /** User clicked on a search result snippet */
  CLICKED_RESULT_SNIPPET = 'clickedResultsSnippet',
  /** User clicked on search filter */
  CLICKED_SEARCH_FILTER = 'clickedSearchFilter',
  /** User clicked on a secondary source in the search result sidebar */
  CLICKED_SECONDARY_SOURCE = 'clickedSecondarySources',
  /** User clicked share on top right of search results page */
  CLICKED_SHARE_CTA = 'clickedShareCTA',
  /** User clicked on a specialized database in the search result sidebar */
  CLICKED_SPECIALIZED_DB = 'clickedSpecializedDB',
  /** User clicks out of the educational modal */
  CLOSED_PS_MODAL = 'closedPSModal',
  /** User collapsed a database list */
  COLLAPSED_DB_LIST = 'collapsedDatabaseList',
  /** User expanded a database list */
  EXPANDED_DB_LIST = 'expandedDatabaseList',
  /** The regi form is fired */
  HIT_REGISTER_FORM = 'hitRegiForm',
  /** The regi wall is fired */
  HIT_REGISTER_WALL = 'hitRegiWall',
  /** The sign form is fired */
  HIT_SIGN_IN_FORM = 'hitSignInForm',
  /** User clicked on a search result title */
  OPENED_CASE_RESULT = 'openedCaseResult',
  /** User opened the JX Modal in the Search Input Componend */
  OPENED_JX_MODAL = 'openedJXModal',
  /** User performed a search */
  SEARCHED = 'searched',
  /** User clicked on sharing button */
  SELECTED_SHARING = 'selectedSharing',
  /** User successfully signed in */
  SIGNED_IN = 'signedIn',
  /** User submitted a query */
  SUBMITTED_QUERY = 'submittedQuery'
}

/**
 * A whitelist of events that get special treatment. At the point an event is fired, it can be fired client-side or
 * tunnelled through the server.
 *
 * For events in this whitelist:
 *
 *     1. If the event is fired client-side, it gets fired as normal.
 *     2. If the event is fired server-side (the `viaServer` flag is `true`), then it will be fired via the server as
 *     normal, but a client-side variant (with a suffixed event name) will also be fired.
 *
 * This allows 3rd party tools that can only respond to client-side events, i.e. Intercom, to work for events that would
 * otherwise only be seen server-side.
 */
const ALWAYS_TRIGGERED_CLIENT_SIDE: ClientAnalyticsEvent[] = [];

export const ALWAYS_TRIGGERED_CLIENT_SIDE_SET = new Set<ClientAnalyticsEvent | string>(ALWAYS_TRIGGERED_CLIENT_SIDE);

/**
 * The suffix applied to event names when a whitelisted event is fired `viaServer` but also fired clientside.
 * @see _ALWAYS_TRIGGERED_CLIENT_SIDE.
 */
export const CLIENT_VARIANT_EVENT_NAME_SUFFIX = '-client';
