import React from 'react';
import classnames from 'classnames';
import { LinearProgress } from '@mui/material';

import { Text, TextColor } from '../design_component_lib/text/Text';

import './Loader.scss';

interface LoaderProps {
  label?: string;
  labelColor?: TextColor;
  noDelay?: boolean;
}

const Loader = ({ label, labelColor, noDelay }: LoaderProps): JSX.Element => (
  <div className={classnames('ct-loader', { 'no-delay': noDelay })}>
    <Text color={labelColor || 'primary'} size="xs">
      {label || 'Loading...'}
    </Text>
    <div className="loader-container">
      <LinearProgress />
    </div>
  </div>
);

export default Loader;
