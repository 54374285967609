import React from 'react';
import { Breadcrumbs, Link } from '@mui/material';
import { ArrowBack, NavigateNext } from '@mui/icons-material';
import { styled } from '@mui/system';

import { Heading, LinkButton, UiLabel } from '../design_component_lib';
import colors from '../../utils/themes/colors';

interface BreadcrumbItem {
  label: string;
  path?: string;
}

interface ResultsHeaderProps {
  actions?: React.ReactNode;
  backAction?: () => void;
  backLabel?: string;
  breadcrumbs?: BreadcrumbItem[];
  reverse?: boolean;
  subtitle?: string | React.ReactNode;
  title: string | JSX.Element;
}

const ActionContainer = styled('div')(({ theme }) => ({
  columnGap: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row'
}));

const Subtitle = styled('div')(({ theme }) => ({
  columnGap: theme.spacing(1.5),
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  paddingTop: theme.spacing(0.5),
  rowGap: theme.spacing(0.5)
}));

const Title = styled('div', { shouldForwardProp: (prop) => prop !== 'pad' })<{ pad?: boolean }>(({ pad, theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  paddingTop: pad ? theme.spacing(1) : 0,
  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    rowGap: theme.spacing(1)
  }
}));

const TitleSubtitle = styled('div')<{ reverse?: boolean }>(({ reverse }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: reverse ? 'column-reverse' : 'column'
}));

/**
 * Component to display header information related to searches
 *
 * @param actions - `ReactNode` components to show in the actions container
 * @param backAction - Function to apply to Back button
 * @param backLabel - Label to use for Back button (defaults to `Back`)
 * @param breadcrumbs - Array of breadcrumb items (`[{ label: 'Root', path: '/' }]`)
 * @param reverse - Reverses order of Title and Subtitle
 * @param subtitle - `ReactNode` components to show below the title
 * @param title - Text to display in the header
 */
const ResultsHeader = ({
  actions,
  backAction,
  backLabel = 'Back',
  breadcrumbs,
  reverse,
  subtitle,
  title
}: ResultsHeaderProps): JSX.Element => (
  <div className="ct-results-header">
    {!!backAction ? (
      <LinkButton color="secondary" label={backLabel} onClick={backAction} startIcon={<ArrowBack fontSize="small" />} />
    ) : (
      !!breadcrumbs &&
      !!breadcrumbs.length && (
        <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
          {breadcrumbs.map(({ label, path }, idx, { length }) =>
            idx !== length - 1 ? (
              <Link color={colors.gray[900]} key={`crumb-${idx}`} href={path} underline="hover">
                {label}
              </Link>
            ) : (
              <UiLabel color="secondary" key="crumb-last" size="sm" weight="medium">
                {label}
              </UiLabel>
            )
          )}
        </Breadcrumbs>
      )
    )}
    <Title pad={!!backAction || (!!breadcrumbs && !!breadcrumbs.length)}>
      <TitleSubtitle reverse={reverse}>
        <Heading size="xl" weight="bold">
          {title}
        </Heading>
        {!!subtitle &&
          (typeof subtitle === 'string' ? (
            <UiLabel color="secondary" size="sm">
              {subtitle}
            </UiLabel>
          ) : (
            <Subtitle>{subtitle}</Subtitle>
          ))}
      </TitleSubtitle>
      {!!actions && <ActionContainer>{actions}</ActionContainer>}
    </Title>
  </div>
);

export default ResultsHeader;
