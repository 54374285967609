import React from 'react';
import { ClientAnalyticsEvent } from '@utils/analytics-events';
import AnalyticsService from 'services/analytics/AnalyticsService';
import { ResultHeader, ResultSnippets, ResultSubheader, ResultSummary } from '.';
import { useAppDispatch, useAppSelector } from 'utils/hooks/redux';
import { setSignInModalState } from 'store/modalSlice';
import { selectUser } from 'store/user';

import { getAngularAppUrl, SearchResultType, ModalState } from '../../../@utils';
import CopyCite from '../CopyCite';

import './Result.scss';
interface ResultProp {
  /** The search result item data */
  doc: SearchResult;
  /** Index of item within list of all search results */
  index: number;
  /** Type of search result */
  type: SearchResultType;
  /** URL search params */
  searchParams: URLSearchParams;
}

interface DocTargetUrlData {
  /** URL including search string and fragment (e.g. '/case/some-doc?q=banana#p9') */
  href: string;
  /** URL pathname (e.g. '/case/some-doc') */
  path: string;
  /** URL search (e.g. 'q=banana') */
  params: string;
  /** URL search string, beginning with a `?` (e.g. '?q=banana') */
  search: string;
  /** URL fragment identifier, beginning with a `#` (e.g. #p9) */
  anchor?: string;
}

/**
 * Component to render an individual search results item
 *
 * @param isAuthedUser to display the sign in modal on result clicks
 *
 */
const Result = ({ doc, searchParams, type, index }: ResultProp): JSX.Element => {
  const dispatch = useAppDispatch();
  /** The doc data to pass into sub-components. For now keeping it simple */
  const resultDoc = doc;
  const isAuthedUser = useAppSelector(selectUser) !== null ? true : false;

  /**
   * Function to generate the target document url, path, and params.
   *
   * @param anchorId Anchor id (usually a specific page number within that document)
   */
  const getDocumentTargetUrl = (anchorId?: string): DocTargetUrlData => {
    let params: string;
    let path: string;
    const newSearchParams = new URLSearchParams(searchParams.toString());

    const destinationDocType = resultDoc.type;
    // make sure we dont ssr render these pages for performance
    newSearchParams.set('ssr', 'false');
    newSearchParams.set('scrollTo', 'true');
    //  disable text highlighting for parallel searches
    newSearchParams.set('find', '');
    params = newSearchParams?.toString();
    path = getAngularAppUrl(`/${destinationDocType}/${resultDoc.slug}`);

    const anchor = !!anchorId ? `#${anchorId}` : '';
    const search = !!params ? `?${params}` : '';
    const href = `${path}${search}${anchor}`;
    return { href, path, params, search, anchor };
  };

  /**
   * Returns result title text
   */
  const getTitleText = (): string => {
    return resultDoc.title || resultDoc.caseName;
  };

  /**
   * Generates snippet data from highlights
   *
   * @param snippets List of text snippets
   * @param addEllipsis If TRUE snippet will get wrapped in `…`
   */
  const getSimpleSnippetData = (snippets: string[], addEllipsis = false): SearchResultSnippet[] => {
    const ellipsis = addEllipsis ? '…' : '';
    return snippets.map((text: string) => ({ text: ellipsis + text + ellipsis }));
  };

  /**
   * Function to get the snippet data with an href link to snippet in the document for different kinds of result types
   * The `href` link is used to allow the user to open the snippet in a new window/tab.
   */
  const getSnippetsDataWithHref = (): SearchResultSnippet[] => {
    const snippets = getSnippetsData();
    return snippets.map((s) => ({
      ...s,
      href: getDocumentTargetUrl(s.anchorId).href
    }));
  };

  /** Function to get the snippet data for different kinds of result types */
  const getSnippetsData = (): SearchResultSnippet[] => {
    if (
      !!resultDoc.paragraphs &&
      ((!!resultDoc.paragraphs.superSnippet && !!resultDoc.paragraphs.superSnippet.length) ||
        (!!resultDoc.paragraphs.rows && !!resultDoc.paragraphs.rows.length))
    ) {
      const results: any = [];
      if (!!resultDoc.paragraphs.superSnippet && !!resultDoc.paragraphs.superSnippet.length) {
        resultDoc.paragraphs.superSnippet.forEach((item: any) => {
          results.push(item);
        });
      }
      if (!!resultDoc.paragraphs.rows && !!resultDoc.paragraphs.rows.length) {
        resultDoc.paragraphs.rows.forEach((item: any) => {
          results.push(item);
        });
      }
      return results;
    } else if (!!resultDoc.highlights?.length) {
      // modify highlights to return similar structure as paragraph rows and super snippet
      return getSimpleSnippetData(resultDoc.highlights, true).map((snippetData) => {
        return {
          ...snippetData,
          anchorId: resultDoc.anchor
        };
      });
    } else if (!!resultDoc.snippets?.length) {
      // "snippets" are available for "binder" results
      return getSimpleSnippetData(resultDoc.snippets, true);
    } else {
      return [];
    }
  };
  /**
   * Tracks click on result item (header or snippet)
   *
   * @param event client analytics event to track
   * @param anchorId  Anchor id (usually a specific page number within that document)
   */
  const trackClick = (event: ClientAnalyticsEvent, anchorId?: string) => {
    AnalyticsService.Instance.track(event, {
      resultIndex: index,
      resultUrl: getDocumentTargetUrl(anchorId)
    });
  };

  const resultSnippetClick = () => {
    trackClick(ClientAnalyticsEvent.CLICKED_RESULT_SNIPPET, resultDoc.anchor);
    if (!isAuthedUser) {
      dispatch(setSignInModalState(ModalState.OPEN));
    }
  };

  const resultHeaderClick = () => {
    trackClick(ClientAnalyticsEvent.CLICKED_RESULT_HEADER, resultDoc.anchor);
    if (!isAuthedUser) {
      dispatch(setSignInModalState(ModalState.OPEN));
    }
  };

  return (
    <div className="ct-search-result-container">
      <div className="ct-search-result-header-container">
        <ResultHeader
          prompt={getTitleText()}
          citator={resultDoc.citator}
          onHeaderClick={resultHeaderClick}
          href={isAuthedUser ? getDocumentTargetUrl(resultDoc.anchor).href : ''}
          isAuthedUser={isAuthedUser}
        />
      </div>
      <ResultSubheader doc={resultDoc} />
      {!!resultDoc.summaries?.rows?.length && (
        <ResultSummary summary={resultDoc.summaries.rows[0]} type={resultDoc.type} />
      )}
      <ResultSnippets
        type={type}
        onSnippetsClick={resultSnippetClick}
        snippets={getSnippetsDataWithHref()}
        isAuthedUser={isAuthedUser}
      />
      <CopyCite doc={doc} isAuthedUser={isAuthedUser} />
    </div>
  );
};

export default Result;
