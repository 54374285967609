import React, { useState, useEffect } from 'react';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { IN_APP_ROUTES, ModalState } from '@utils';
import { useAppDispatch, useAppSelector } from 'utils/hooks/redux';
import { setShareModalState } from 'store/modalSlice';
import { selectAbTestVariation } from 'store/user';

import SearchInput from './search_modal/SearchInput';

import './SearchHeader.scss';
import { ReactComponent as CasetextSvg } from '../../assets/saps_logo_results.svg';

/** The Search Header component. */
const SearchHeader = () => {
  const location = useLocation();
  const theme = useTheme();
  const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const abTestVariation = useAppSelector(selectAbTestVariation);
  const [activePage, setActivePage] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (activePage !== location.pathname) {
      setActivePage(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleShareClick = async () => {
    dispatch(setShareModalState(ModalState.OPEN));
  };

  return (
    <div data-testid="casetext-search-header" className="ct-search-header">
      {!isLgDown && (
        <div>
          <Link to={`/${abTestVariation ? `?exp=${abTestVariation}` : ''}`}>
            <CasetextSvg className="saps-logo-svg" />
          </Link>
          <Button className="share-btn" onClick={handleShareClick}>
            Share
          </Button>
        </div>
      )}
      <SearchInput isSearchResultsPage={activePage === IN_APP_ROUTES.SEARCH_RESULTS} />
    </div>
  );
};

export default SearchHeader;
