import { styled } from '@mui/system';

import colors from '../../../utils/themes/colors';

const labelColorList = {
  inherit: null,
  primary: colors.gray[900],
  secondary: colors.gray[700],
  'primary-blue': colors.brandblue[500],
  warning: colors.yellow[900]
};
export type LabelColor = keyof typeof labelColorList;
const labelSizeList = { xs: '0.75rem', sm: '0.875rem', base: '1rem' };
export type LabelSize = keyof typeof labelSizeList;
const labelLineHeightList = { xs: 1.33, sm: 1.43, base: 1.5 };
export type LabelLineHeight = keyof typeof labelLineHeightList;
const labelWeightList = { regular: 'normal', medium: '500', bold: '700' };
export type LabelWeight = keyof typeof labelWeightList;
export interface LabelProps {
  color?: LabelColor;
  size?: LabelSize;
  weight?: LabelWeight;
}

/**
 * General label element with options for color, size, and weight
 */
export const UiLabel = styled('span', { shouldForwardProp: (prop) => prop === 'children' })<LabelProps>(
  ({ color = 'primary', size = 'base', weight = 'regular' }) => ({
    color: color === 'inherit' ? color : labelColorList[color],
    fontSize: labelSizeList[size],
    fontWeight: labelWeightList[weight],
    lineHeight: labelLineHeightList[size]
  })
);
