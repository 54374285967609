import { FILTER_LABELS } from 'components/search/ResultsFilters';
import { Category, ResultFilter } from 'components/search/ResultsSidebar';
import { LOG } from 'services/logging/LoggingService';
import { TeaserType } from 'views/Teaser';

import { SearchResultType } from './search';

/* List of Hubspot Multiselect Teaser Fields
Database - Primary Law - Statutes
Database - Primary Law - Regulations
Database - Primary Law - Rules
Database - Primary Law - Admin Material
Database - Secondary Source - Federal Briefs
Database - Secondary Source - California Briefs & Forms
Database - Secondary Source - Practice guides
Database - Secondary Source - Legal Analyses
Database - Specialized Databases - Black Letter Law
Database - Specialized Databases - Holdings
Filter - Advanced Filters
Filter - Dates
Filter - Published/unpublished
Filter - Search within
Filter - All filters (mobile) */

// Creating local enum in case TeaserType changes
enum HubspotTeaserType {
  DATABASE = 'Database',
  FILTER = 'Filter'
}

enum HubspotCategoryType {
  PRIMARY_LAW = 'Primary Law',
  SECONDARY = 'Secondary Source',
  SPECIALIZED_DB = 'Specialized Databases'
}

export enum HubspotFilterType {
  ADVANCED = 'Advanced Filters',
  ALL_MOBILE = 'All filters (mobile)',
  DATES = 'Dates',
  PUB = 'Published/unpublished',
  SEARCH_WITHIN = 'Search within'
}

enum HubspotDatabaseType {
  ADMIN = 'Admin Material',
  ANALYSES = 'Legal Analyses',
  BLL = 'Black Letter Law',
  CAL_BRIEFS = 'California Briefs & Forms',
  FED_BRIEFS = 'Federal Briefs',
  GUIDES = 'Practice guides',
  HOLD = 'Holdings',
  REGS = 'Regulations',
  RULES = 'Rules',
  STATUTES = 'Statutes'
}

export interface TeaserCrmData {
  teaserType: TeaserType;
  database?: ResultFilter;
  filterType?: FILTER_LABELS | HubspotFilterType.ALL_MOBILE;
}

/**
 * Converts the type of teaser hit along with specific parameters into a Hubspot
 * multi-select string, predefined here: https://github.com/casetext/SAPS/issues/182.
 * Uses locally-scoped enums to make sure that if outside values change, Hubspot CRM
 * recording is safe.
 *
 * @param teaserType either filter or database
 * @param database Optional parameter, required if teaserType is database.
 * @param filterType Optional paramater, required if teaserType if filter
 * @returns A Hubspot CRM-tailored string, like the batch of comments at top of file
 */
export const getTeaserHitString = ({ teaserType, database, filterType }: TeaserCrmData): string => {
  const firstStringChunk = teaserType === TeaserType.DATABASE ? HubspotTeaserType.DATABASE : HubspotTeaserType.FILTER;
  let secondStringChunk;
  let thirdStringChunk;

  switch (teaserType) {
    case TeaserType.DATABASE:
      secondStringChunk = !!database ? getDatabaseCategoryString(database.category) : null;
      thirdStringChunk = !!database ? getDatabaseNameString(database.id) : null;
      if (!!secondStringChunk && !!thirdStringChunk) {
        return `${firstStringChunk} - ${secondStringChunk} - ${thirdStringChunk}`;
      }
      break;
    case TeaserType.FILTER:
      secondStringChunk = !!filterType ? getFilterTypeString(filterType) : null;
      if (!!secondStringChunk) {
        return `${firstStringChunk} - ${secondStringChunk}`;
      }
      break;
    default:
      LOG.error(`Could not generate Hubspot CRM Teaser string`);
  }
  return '';
};

/**
 * Helper function to get the Hubspot CRM string for the category of Database user clicked on.
 * Ie the middle part of the string: 'Database - Primary Law - Rules'
 *
 * @param category the database category, either primary, secondary, or specialized db
 * @returns The Hubspot-optimized string for the category
 */
const getDatabaseCategoryString = (category: Category): string => {
  return category === Category.PRIMARY_LAW
    ? HubspotCategoryType.PRIMARY_LAW
    : category === Category.SECONDARY_SOURCES
    ? HubspotCategoryType.SECONDARY
    : HubspotCategoryType.SPECIALIZED_DB;
};

/**
 * Helper function to get the Hubspot CRM string for the type of Database user clicked on.
 * Ie the last part of the string: 'Database - Primary Law - Rules'
 *
 * @param database the database, one of the search result types
 * @returns The Hubspot-optimized string for the database
 */
const getDatabaseNameString = (database: SearchResultType): string => {
  switch (database) {
    case SearchResultType.ADMIN_LAW:
      return HubspotDatabaseType.ADMIN;
    case SearchResultType.ANALYSIS:
      return HubspotDatabaseType.ANALYSES;
    case SearchResultType.BLL:
      return HubspotDatabaseType.BLL;
    case SearchResultType.BRIEF:
      return HubspotDatabaseType.FED_BRIEFS;
    case SearchResultType.GAVELYTICS:
      return HubspotDatabaseType.CAL_BRIEFS;
    case SearchResultType.GUIDE:
      return HubspotDatabaseType.GUIDES;
    case SearchResultType.HOLDING:
      return HubspotDatabaseType.HOLD;
    case SearchResultType.REGULATION:
      return HubspotDatabaseType.REGS;
    case SearchResultType.RULE:
      return HubspotDatabaseType.RULES;
    case SearchResultType.STATUTE:
      return HubspotDatabaseType.STATUTES;
    default:
      return '';
  }
};

/**
 * Helper function to get the Hubspot CRM string for the type of filter user clicked on.
 * Ie the last part of the string: 'Filter - Advanced Filters'
 * Since the Hubspot CRM string 'Filter - All filters (mobile)' cannot be captured by the
 * FILTER_LABELS enum, I'll pass that in directly in that use case.
 *
 * @param filterType the type of filter, one of the non-jx filters in search results
 * @returns The Hubspot-optimized string for the filter
 */
const getFilterTypeString = (filterType: FILTER_LABELS | HubspotFilterType): string => {
  if (filterType === HubspotFilterType.ALL_MOBILE) {
    return HubspotFilterType.ALL_MOBILE;
  }
  switch (filterType) {
    case FILTER_LABELS.ADVANCED_FILTERS:
      return HubspotFilterType.ADVANCED;
    case FILTER_LABELS.DATES:
      return HubspotFilterType.DATES;
    case FILTER_LABELS.PUBLISHED:
      return HubspotFilterType.PUB;
    case FILTER_LABELS.SEARCH_WITHIN:
      return HubspotFilterType.SEARCH_WITHIN;
    default:
      return '';
  }
};
