import { CiteFormatter } from '@casetext/libjx';
import { ToastState } from '@utils';
import { AppDispatch } from 'store/store';
import { setToast } from 'store/toastSlice';

/**
 * Citation format for Copy Cite button
 */
export enum CITATION_FORMAT {
  BLUEBOOK = 'bluebook'
}

/**
 * Converts single to double quotes, takes care of edge cases,
 * etc. to make parenthetical citations clean
 *
 * @param selectedText the result snippet's highlighted text
 * @returns a clean parenthetical citation
 */
const cleanCitation = (selectedText: string) => {
  const quote = '"';
  const allQuotes = /"/g;
  const matches = selectedText.match(allQuotes);
  const numQuotes = matches ? matches.length : 0;
  // If uneven number of quotation marks, add a quote to either the front or back. Handles edge cases.
  if (numQuotes % 2 !== 0) {
    // Edge case where the start of the highlight unintentionally captures the end of a previous quote.
    // Ie: ..." <mySelectedText>
    // We want to remove that quote, not pertinent to our highlight
    if (selectedText.startsWith(quote) && selectedText.startsWith(' ', 1)) {
      selectedText = selectedText.substring(2);
    } else if (selectedText.startsWith(quote)) {
      selectedText = selectedText.concat(quote);
    } else if (selectedText.endsWith(quote)) {
      selectedText = `'${selectedText}`;
    }
  }
  // Replace all double quotes in selection with single quotes.
  selectedText = selectedText.replace(allQuotes, "'");
  return `("${selectedText}")`;
};

/**
 * Generates citation snippets (title, suffix)
 * @param citationFormat The format the resulting snippets should be conform to (i.e. bluebook)
 * @param citationData The citation information (titel, jx, page number, etc.)
 * @param pageNum the page number of the result snippet
 * @param caseText the selected text from the result snippet
 *
 * @returns the full citation for the case, ready to be copied to the clipboard
 */
export const getCitation = (citationFormat: string, citationData: any, pageNum: number, caseText: string): string => {
  const data = { ...citationData };
  data.page = pageNum.toString();
  const formattedCaseText = cleanCitation(caseText);

  let formattedData;
  try {
    formattedData = CiteFormatter.get(
      citationFormat,
      data.documentType,
      data.jxCode,
      data.longJx,
      data.title,
      data.date,
      data.citation,
      data.docket,
      data.page,
      data.footnoteNumber
    );
  } catch (err: any) {
    console.error(err && err.message);
  }
  if (!!formattedData) {
    return formattedData.title + formattedData.titleSuffix + ' ' + formattedCaseText;
  }
  return '';
};
// Use MDN's Clipboard API
export const addToClipboard = async (dispatch: AppDispatch, selectedText: string): Promise<void> => {
  navigator.clipboard
    .writeText(selectedText)
    .then(() => {
      dispatch(setToast({ toastStatus: ToastState.SUCCESS, toastMessage: 'Copied' }));
    })
    .catch((err: any) => {
      console.log(`Error copying to clipboard:`, err);
      dispatch(setToast({ toastStatus: ToastState.ERROR, toastMessage: 'Error Copying' }));
    });
};
