import { Button, useTheme, useMediaQuery } from '@mui/material';
import { ClientAnalyticsEvent } from '@utils/analytics-events';
import { addToClipboard } from '@utils/copy-cite-utils';
import AnalyticsService from 'services/analytics/AnalyticsService';

import { useAppDispatch } from 'utils/hooks/redux';

import { Heading, LinkButton, Text } from '../design_component_lib';

import './ShareContent.scss';

/**
 * Component that allows users to share SAPS link
 *
 * @returns Child of Modal component a component to allow the user to copy SAPS URL
 */
const ShareContent = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const title = 'Share Parallel Search';
  const subTitle = 'Help your colleagues research smarter and faster';
  const buttonText = 'Copy Link';
  const SAPS_URL = 'https://parallelsearch.casetext.com';

  const handleShareClick = () => {
    addToClipboard(dispatch, SAPS_URL);
    AnalyticsService.Instance.track(ClientAnalyticsEvent.CLICKED_SHARE_CTA);
  };

  return (
    <>
      <div className="share">
        <div className="share-heading">
          <Heading sx={{ justifyContent: 'center', marginBottom: '0.75rem' }} size={isSmDown ? 'lg' : '3xl'}>
            {title}
          </Heading>
          <Heading size={isSmDown ? 'sm' : 'lg'} color="secondary">
            {subTitle}
          </Heading>
        </div>
        <div className="share-link-text">
          <LinkButton
            sx={{ color: 'black' }}
            size={isSmDown ? 'sm' : 'md'}
            label={SAPS_URL}
            onClick={handleShareClick}
          />
        </div>
        <Button
          sx={{
            width: '100%',
            maxWidth: '29.125rem',
            marginTop: '1rem'
          }}
          onClick={handleShareClick}
          variant="contained"
          size="extra-large"
        >
          <Text color="inherit">{buttonText}</Text>
        </Button>
      </div>
    </>
  );
};

export default ShareContent;
