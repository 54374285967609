import { useEffect, useState } from 'react';
import { Alert, Button, Collapse, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import format from 'date-fns/format';
import { Link, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'utils/hooks/redux';
import { resendEmail } from '@utils/user';
import { ABExperiments } from '@utils';
import { selectAbTestVariation, setAbTestVariation } from 'store/user';

import { SearchInput } from '../components2';
import { UiLabel } from '../components2/design_component_lib';

import { ReactComponent as CasetextSvg } from '../assets/saps_logo_homepage.svg';
import './Homepage2.scss';

enum QUERY_PARAMS {
  EMAIL = 'email',
  AUTH_FAILURE = 'auth_failure'
}

/** The homepage component. */
const Homepage2 = () => {
  const [searchParams] = useSearchParams();
  const currentYear = format(new Date(), 'yyyy');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [authFailure, setAuthFailure] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const abTestVariation = useAppSelector(selectAbTestVariation);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setAbTestVariation(ABExperiments.HOMEPAGE_EXP_2));
    const hasAuthFailure = searchParams.get(QUERY_PARAMS.AUTH_FAILURE) === 'true';
    const email = searchParams.get(QUERY_PARAMS.EMAIL);
    setAuthFailure(hasAuthFailure);
    setEmail(email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-testid="casetext-homepage" className="ct-homepage-main2">
      <div className="ct-hompage-header">
        <Link to={`/${abTestVariation ? `?exp=${abTestVariation}` : ''}`}>
          <CasetextSvg className="saps-logo-svg" />
        </Link>
      </div>
      {isMobile ? (
        <Container className="ct-homepage-content">
          <div className="mobile-sidebar">
            <div className="header">We are revolutionizing legal research</div>
            <div className="subheader">
              Instead of using keywords and connectors try searching with a full sentence.
            </div>
          </div>
          <SearchInput isHomepage={true} />
          <div className="ps-example">
            <UiLabel color="secondary">Example:</UiLabel>
            <UiLabel className="example-text" color="secondary">
              Target’s employees were uncompensated while waiting for loss prevention inspections before leaving work
            </UiLabel>
          </div>
          <Collapse in={authFailure} className="auth-alert">
            <Alert
              severity="error"
              action={
                <Button
                  className="resend-button"
                  color="inherit"
                  size="small"
                  onClick={() => !!email && resendEmail(dispatch, email)}
                >
                  Resend link
                </Button>
              }
            >
              Unable to verify user
            </Alert>
          </Collapse>
        </Container>
      ) : (
        <Grid container className="homepage-grid">
          <Grid item md={5}>
            <div className="desktop-sidebar">
              <div className="header">We are revolutionizing legal research</div>
              <div className="subheader">
                Instead of using keywords and connectors try searching with a full sentence.
              </div>
            </div>
          </Grid>
          <Grid item md={7}>
            <Container className="ct-homepage-content" maxWidth="md">
              <UiLabel className="big-header" color="primary">
                Find a case that says...
              </UiLabel>
              <SearchInput isHomepage={true} />
              <div className="ps-example">
                <UiLabel color="secondary">Example:</UiLabel>
                <UiLabel className="example-text" color="secondary">
                  Target’s employees were uncompensated while waiting for loss prevention inspections before leaving
                  work
                </UiLabel>
              </div>
              <Collapse in={authFailure} className="auth-alert">
                <Alert
                  severity="error"
                  action={
                    <Button
                      className="resend-button"
                      color="inherit"
                      size="small"
                      onClick={() => !!email && resendEmail(dispatch, email)}
                    >
                      Resend link
                    </Button>
                  }
                >
                  Unable to verify user
                </Alert>
              </Collapse>
            </Container>
          </Grid>
        </Grid>
      )}
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-links">
            <a href="https://casetext.com/terms" target="terms-of-service" rel="noopener noreferrer">
              Terms
            </a>
            <a href="https://casetext.com/privacy" target="privacy-policy" rel="noopener noreferrer">
              Privacy
            </a>
          </div>
          <div className="footer-copyright">© {currentYear} Casetext Inc.</div>
        </div>
      </div>
    </div>
  );
};

export default Homepage2;
