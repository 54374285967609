import React from 'react';

import './ParallelSearchPopover.scss';

interface ParallelSearchPopoverProps {
  arrowDirection: 'top' | 'bottom';
}

const TopArrowIcon = () => {
  return (
    <svg width={41} viewBox="0 0 61 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.5 0l29.878 33H.622L30.5 0z" fill="#F2F7FB" />
    </svg>
  );
};

const BottomArrowIcon = () => {
  return (
    <svg width={41} viewBox="0 0 61 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.5 33L60.378 0H.622L30.5 33z" fill="#F2F7FB" />
    </svg>
  );
};

const ParallelSearchPopover = ({ arrowDirection }: ParallelSearchPopoverProps): JSX.Element => {
  return (
    <div className="ct-ps-popover">
      {arrowDirection === 'top' && <TopArrowIcon />}
      <div className="popover-body">
        <div className="header">Rewrite your query as a full sentence and find on-point cases in seconds</div>
        <div className="example">
          <div className="desktop-example-copy">Example:</div>
          <div className="example-body">
            <span className="mobile-example-copy">For example, “</span>
            An ex-spouse moving in with a new romantic interest is sufficient to terminate support payments.
            <span className="mobile-example-copy">”</span>
          </div>
        </div>
      </div>
      {arrowDirection === 'bottom' && <BottomArrowIcon />}
    </div>
  );
};

export default ParallelSearchPopover;
