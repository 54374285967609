import React from 'react';
import {
  Link,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { DocType, DocumentType, getAngularAppUrl, sanitizeSearchResultText } from '@utils';

import colors from '../../../utils/themes/colors';

interface ResultSummaryProps {
  summary: SearchResultSummary;
  /** Type of result document */
  type: DocumentType;
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}.${tooltipClasses.tooltipArrow}.${tooltipClasses.tooltipPlacementRight}`]: {
    marginLeft: 8
  }
});

/**
 * Component to display the judge's summary for a search result item
 *
 */
const ResultSummary = ({ summary, type }: ResultSummaryProps): JSX.Element => {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  /**
   * Function to generate tooltip content
   * (It's safe to assume that all (linked) summary documents are of type "case")
   */
  const getToolTipContent = () => {
    const docType = (DocType.HumanReadableNames.get(type) || {}).singular || 'case';
    return (
      <>
        <Typography
          component="span"
          sx={{ color: colors.gray[400], fontSize: '0.75rem', lineHeight: 1.5, display: 'block' }}
        >
          How this {docType} was summarized in
        </Typography>
        <Link
          href={getAngularAppUrl(`/case/${summary.slug}?ssr=false`)}
          variant="body1"
          color="white"
          underline="hover"
        >
          <em className="summary-tooltip-link-title">
            {summary.title}
            {!!summary.citationString ? ', ' : ''}
          </em>
          {!!summary.citationString && <span>{summary.citationString}</span>}
        </Link>
      </>
    );
  };

  return (
    <div className="ct-search-result-summary">
      <CustomWidthTooltip
        title={getToolTipContent()}
        arrow
        placement="right"
        disableHoverListener={!summary.title}
        leaveDelay={175}
        sx={{
          [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: isSmDown ? 150 : 426
          }
        }}
      >
        <span className="ct-result-summary-prefix">
          <span className="summary-prefix-pmt">Judge's Summary</span>
        </span>
      </CustomWidthTooltip>
      <Typography component="span" sx={{ color: colors.gray[700], fontSize: '0.75rem', lineHeight: 1.5 }}>
        {' '}
        —{' '}
      </Typography>
      <span
        className="ct-result-summary-snippet ct-highlightable"
        dangerouslySetInnerHTML={{ __html: sanitizeSearchResultText(summary.text || '') }}
      />
    </div>
  );
};

export default ResultSummary;
