import React from 'react';

import { useAppSelector } from '../../utils/hooks/redux';
import { selectUserQuery } from '../../store/searchSlice';
import { Text } from '../design_component_lib/text/Text';

import './FullPageLoader.scss';

const FullPageLoader = (): JSX.Element => {
  const userQuery = useAppSelector(selectUserQuery);

  return (
    <div className="ct-full-loader">
      <Text className="general-text" size="2xl" weight="600" color="primary">
        Looking for sentences similar to:
      </Text>
      <Text className="user-query" color="primary">
        “{userQuery}”...
      </Text>
      <div className="loading-box">
        <div className="top-bar" />
        <div className="bottom-bar" />
      </div>
      <div className="loading-box opacity-6">
        <div className="top-bar" />
        <div className="bottom-bar" />
      </div>
      <div className="loading-box opacity-3">
        <div className="top-bar" />
        <div className="bottom-bar" />
      </div>
    </div>
  );
};

export default FullPageLoader;
