import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

interface JxButtonProps {
  selectedJXs: string[];
}

const JxButton = ({ selectedJXs, ...props }: ButtonProps & JxButtonProps): JSX.Element => {
  /**
   * Function to get the button text.
   */
  const getButtonText = () => {
    let btnTxt = '';

    if (selectedJXs.length > 1) {
      btnTxt = `${selectedJXs.length} Jurisdictions`;
    } else if (selectedJXs.length === 1) {
      btnTxt = `1 Jurisdiction`;
    } else {
      btnTxt = 'All State & Federal';
    }

    return btnTxt;
  };

  return (
    <Button
      className="ct-jx-button"
      data-testid="jx-button"
      endIcon={<KeyboardArrowDown color="primary" />}
      variant="text"
      aria-label={getButtonText()}
      {...props}
    >
      {getButtonText()}
    </Button>
  );
};

export default JxButton;
