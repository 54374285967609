import sanitizeHtml from 'sanitize-html';
import { isArray, isBoolean } from 'lodash';

/**
 * Search result type.
 */
export enum SearchResultType {
  ADMIN_LAW = 'admin-law',
  ANALYSIS = 'analysis',
  BINDER = 'binder',
  BLL = 'bll',
  BRIEF = 'brief',
  CASE = 'case',
  GAVELYTICS = 'gavelytics-brief',
  HOLDING = 'holding',
  GUIDE = 'guide',
  REGULATION = 'regulation',
  RULE = 'rule',
  STATUTE = 'statute'
}

export enum SearchTab {
  PARALLEL = 'ps'
}

export enum SearchType {
  PARALLEL = 'Parallel Search',
  KEYWORD = 'keyword search'
}

/**
 * Result sorting options
 */
export enum SortOptions {
  CITE_COUNT = 'cite-count',
  DATE_ASC = 'date-ascending',
  DATE_DESC = 'date-descending',
  RELEVANCE = 'relevance'
}

/** Singular/plural labels for `caseResultType` */
export const SearchResultTypeLabel: Map<SearchResultType, { singular: string; plural: string }> = new Map([
  [SearchResultType.CASE, { singular: 'case', plural: 'cases' }]
]);

/**
 * Function to transform a search param filter value to a string.
 *
 * @param val - The value to transform
 */
export const searchParamValueToStr = (val: string | string[] | number | boolean): string => {
  let newValAsStr: string;

  if (isArray(val)) {
    newValAsStr = val.join(',');
  } else if (isBoolean(val)) {
    newValAsStr = val ? 'true' : 'false';
  } else {
    newValAsStr = `${val}`;
  }

  return newValAsStr;
};

/**
 * Types on suggestions from server
 */
export enum SearchSuggestionType {
  CASE = 'case'
}

/**
 * Function to sanitize text of search results data and use as dangerouslySetInnerHTML
 * @param text
 */
export const sanitizeSearchResultText = (text: string): string => {
  return sanitizeHtml(text, {
    allowedTags: ['b', 'i', 'em', 'strong']
  });
};
