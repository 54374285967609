import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Button, Collapse, Grid, useMediaQuery, useTheme } from '@mui/material';
import classnames from 'classnames';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { isEqual, startCase } from 'lodash';
import { resendEmail } from '@utils/user';
import ResultsSidebar from 'components/search/ResultsSidebar';
import MobileResultsFilter from 'components/mobile/MobileResultsFilters';
import { selectUser } from 'store/user';

import {
  ApiStatus,
  IN_APP_ROUTES,
  SearchResultType,
  SearchResultTypeLabel,
  sortedSearchStr,
  SortOptions
} from '../@utils';
import {
  FullPageLoader,
  MetadataSearchType,
  NoResults,
  Result,
  ResultsHeader,
  ResultsFilters,
  SearchError,
  SearchHeader
} from '../components';
import {
  performSearch,
  selectActiveSearchFilters,
  selectSearchResultsStatus,
  selectSearchResults,
  selectUserQuery,
  setUserQuery,
  updateActiveSearchFilters,
  selectHidePSEducationalCard
} from '../store/searchSlice';
import { selectSelectedJXs, setSelectedJXs } from '../store/jurisdictionSlice';
import { useAppDispatch, useAppSelector } from '../utils/hooks/redux';
import Teaser, { TeaserType } from './Teaser';
import ParallelSearchCard, { PSCardType } from '../components/search/ParallelSearchEducationalCard';

import './SearchResults.scss';

enum SEARCH_QUERY_PARAMS {
  JURISDICTIONS = 'jxs',
  PAGE = 'p',
  QUERY = 'q',
  SORT = 'sort',
  TAB = 'tab',
  TYPE = 'type'
}

enum AUTH_FAILURE_QUERY_PARAMS {
  EMAIL = 'email',
  AUTH_FAILURE = 'auth_failure'
}

/** The Search Results component. */
const SearchResults = () => {
  // Global properties
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  // General search properties
  const activeFilters = useAppSelector(selectActiveSearchFilters);
  const selectedJXs = useAppSelector(selectSelectedJXs);
  const userQuery = useAppSelector(selectUserQuery);
  const searchResults = useAppSelector(selectSearchResults);
  const searchResultsStatus = useAppSelector(selectSearchResultsStatus);
  const hidePSEducationalCard = useAppSelector(selectHidePSEducationalCard);

  // User Properties
  const [authFailure, setAuthFailure] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const isAuthedUser = useAppSelector(selectUser) !== null ? true : false;

  // Success Card Properties
  const userParallelSearchCount = useAppSelector(selectUser)?.usage?.count;
  const [psSuccessCardHidden, hidePsSuccessCard] = useState(false);

  // Need to handle query params on mount (in the case this page was navigated to directly)
  useEffect(() => {
    const hasAuthFailure = searchParams.get(AUTH_FAILURE_QUERY_PARAMS.AUTH_FAILURE) === 'true';
    const email = searchParams.get(AUTH_FAILURE_QUERY_PARAMS.EMAIL);
    setAuthFailure(hasAuthFailure);
    setEmail(email);

    handleLocationChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle updates that should kick off a new search
  useEffect(() => {
    const { page, sort, type, tab } = activeFilters;

    if (!!userQuery && !!activeFilters.type) {
      const queryParams: { [key: string]: any } = {
        [SEARCH_QUERY_PARAMS.QUERY]: userQuery,
        [SEARCH_QUERY_PARAMS.JURISDICTIONS]: (!!selectedJXs && selectedJXs.join(',')) || undefined,
        [SEARCH_QUERY_PARAMS.PAGE]: page,
        [SEARCH_QUERY_PARAMS.SORT]: sort,
        [SEARCH_QUERY_PARAMS.TAB]: tab,
        [SEARCH_QUERY_PARAMS.TYPE]: type
      };
      const searchStr = sortedSearchStr(queryParams);

      if (searchStr !== location.search) {
        // call navigate to update URL and push to history, so back button works.
        // NOTE: this will NOT remount the component if its already mounted.
        navigate({
          pathname: IN_APP_ROUTES.SEARCH_RESULTS,
          search: searchStr
        });
      }
      // Extra check to ensure searches only run for cases
      if (type === SearchResultType.CASE) {
        // Perform search
        dispatch(performSearch());
      }
    }

    // We dont care about changes to location here. And they cause unnecessary re-renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, selectedJXs, userQuery]);

  // capture location changes and search if needed
  useEffect(() => {
    handleLocationChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   * Function to handle location changes.
   * This function will compare current redux state with what is passed in as query params.
   * If changes are detected, it will update redux, which will cause the side effect of kicking off a new search.
   */
  const handleLocationChange = () => {
    const jxsParam = searchParams.get(SEARCH_QUERY_PARAMS.JURISDICTIONS);
    const pageParam = searchParams.get(SEARCH_QUERY_PARAMS.PAGE);
    const queryParam = searchParams.get(SEARCH_QUERY_PARAMS.QUERY);
    const sortParam = searchParams.get(SEARCH_QUERY_PARAMS.SORT);
    const tabParam = searchParams.get(SEARCH_QUERY_PARAMS.TAB);
    const typeParam = Object.values(SearchResultType).includes(
      searchParams.get(SEARCH_QUERY_PARAMS.TYPE) as SearchResultType
    )
      ? searchParams.get(SEARCH_QUERY_PARAMS.TYPE)
      : SearchResultType.CASE;

    // parse values that match the activeFilter
    const jxs = (!!jxsParam && jxsParam.split(',')) || [];
    const page = (!!pageParam && +pageParam) || 1;
    const sort = (sortParam as SortOptions) || SortOptions.RELEVANCE;

    // NOTE: we do NOT need to send and search request in this useEffect.
    // Since we are potentially updating redux slices, the changes will get
    // picked up by the useEffect below and kick off the search.
    if (userQuery !== queryParam) {
      dispatch(setUserQuery(queryParam));
    }

    // lists need to be sorted for isEqual to work correctly.
    const sortedJxs = [...jxs].sort();
    const sortedCurrentJxs = [...selectedJXs].sort();
    if (!isEqual(sortedJxs, sortedCurrentJxs)) {
      dispatch(setSelectedJXs(jxs));
    }

    if (
      page !== activeFilters.page ||
      sort !== activeFilters.sort ||
      tabParam !== activeFilters.tab ||
      typeParam !== activeFilters.type
    ) {
      dispatch(
        updateActiveSearchFilters({
          page,
          sort,
          tab: tabParam,
          type: typeParam
        })
      );
    }
  };

  /**
   * Gets the title for the ResultsHeader based on type and count
   *
   * @param type - The type of search result
   * @param count - The number of results found
   * @returns Title string
   */
  const resultsTitle = (type: string, count: number): JSX.Element => {
    const resultType = type as SearchResultType;
    // Defaut page size for movant backend -- backend won't return more than this
    const maxCountLabel = 25;

    /**
     * Displays the number of results found
     * @returns - The number of results found
     */
    const countStr = () => {
      return count === maxCountLabel ? `Top ${maxCountLabel}` : count;
    };

    const pluralSingular = count === 1 ? 'singular' : 'plural';

    return <>{`${countStr()} ${startCase((SearchResultTypeLabel.get(resultType) || {})[pluralSingular])}`}</>;
  };

  const navigateToType = (type: SearchResultType) => {
    dispatch(updateActiveSearchFilters({ type }));
  };

  const activeFilterType = activeFilters.type as string;
  const hasSearchResults = searchResults?.results?.[activeFilterType]?.rows?.length > 0;
  const isTeaserContent = activeFilterType !== SearchResultType.CASE;
  const count = searchResults?.results?.case?.rows?.length || 0;
  const currentContentType = searchParams.get(SEARCH_QUERY_PARAMS.TYPE) as SearchResultType;

  return (
    <div
      data-testid="casetext-search-results"
      className={classnames('ct-search-results-main', { 'is-mobile': isSmDown })}
    >
      {searchResultsStatus === ApiStatus.LOADING ? (
        <FullPageLoader />
      ) : (
        <>
          {!isSmDown && <SearchHeader />}
          {isSmDown && !!isAuthedUser && (
            <MobileResultsFilter onChange={navigateToType} currentContentType={currentContentType} />
          )}
          <div className={classnames('ct-search-results-content')}>
            <Grid container>
              {!isMdDown && !!isAuthedUser && (
                <Grid item md={3} lg={3}>
                  <div className="desktop-sidebar-container">
                    <ResultsSidebar onChange={navigateToType} currentContentType={currentContentType} />
                  </div>
                </Grid>
              )}
              <Grid item xs={12} md={!isAuthedUser ? 12 : 9} lg={!isAuthedUser ? 12 : 9}>
                <div
                  id="ct-search-results-container"
                  className={classnames('ct-search-results-container', {
                    'is-mobile': isSmDown,
                    'center-content': !isAuthedUser || isMdDown
                  })}
                >
                  <div className="ct-search-results-container-content">
                    <Collapse in={authFailure} className="auth-alert">
                      <Alert
                        severity="error"
                        action={
                          <Button
                            className="resend-button"
                            color="inherit"
                            size="small"
                            onClick={() => !!email && resendEmail(dispatch, email)}
                          >
                            Resend link
                          </Button>
                        }
                      >
                        Unable to verify user
                      </Alert>
                    </Collapse>
                    {isTeaserContent ? (
                      <div className="ct-teaser-container">
                        <Teaser type={TeaserType.DATABASE} />
                      </div>
                    ) : (
                      <>
                        {searchResultsStatus === ApiStatus.SUCCESS && (
                          <>
                            {userParallelSearchCount === 1 &&
                              !!hasSearchResults &&
                              searchResults.engine === 'parallel-search' &&
                              !psSuccessCardHidden && (
                                <ParallelSearchCard type={PSCardType.SUCCESS} hidePsSuccessCard={hidePsSuccessCard} />
                              )}
                            <ResultsHeader
                              subtitle={!!searchResults && <MetadataSearchType type={searchResults.engine} />}
                              title={resultsTitle(activeFilterType, count)}
                            />
                            {!isSmDown && !!isAuthedUser && <ResultsFilters />}
                          </>
                        )}
                        {searchResultsStatus === ApiStatus.FAILURE && <SearchError />}
                        {searchResultsStatus === ApiStatus.SUCCESS && !hasSearchResults && <NoResults />}
                        {searchResultsStatus === ApiStatus.SUCCESS && hasSearchResults && (
                          <>
                            <ol className="search-results-list list-unstyled">
                              {searchResults.results[activeFilterType].rows.map((item: any, idx: number) => {
                                return (
                                  <Fragment key={`result-${activeFilterType}-${item.slug || item.id}-${idx}`}>
                                    <li>
                                      <Result
                                        doc={item}
                                        type={activeFilterType as SearchResultType}
                                        searchParams={searchParams}
                                        index={idx}
                                      />
                                    </li>
                                    {idx === 1 && searchResults.engine === 'search-api' && !hidePSEducationalCard && (
                                      <li key="ParallelSearchCard-Education">
                                        <ParallelSearchCard type={PSCardType.EDUCATIONAL} />
                                      </li>
                                    )}
                                  </Fragment>
                                );
                              })}
                            </ol>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
};

export default SearchResults;
