import React from 'react';
import { Flare, SupportAgent, TimerOutlined } from '@mui/icons-material';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { getAngularAppUrl } from '@utils';
import AnalyticsService from 'services/analytics/AnalyticsService';
import { ClientAnalyticsEvent } from '@utils/analytics-events';

import { Heading, Text } from '../components/design_component_lib';

import { ReactComponent as CasetextSvg } from '../assets/logo_dark.svg';
import './Teaser.scss';

export interface TeaserProps {
  type: TeaserType | undefined;
}

export enum TeaserType {
  DATABASE = 'db',
  FILTER = 'filter'
}

enum TeaserTitle {
  FILTER = 'Filter your results and find on-point case law quickly',
  DATABASE = 'Access statutes, regulations, and other databases'
}

/**
 * Enum to easily change teaser text
 */
enum TeaserContent {
  BUTTON_TEXT = 'Try Free for 14 Days',
  POINT_ONE_TITLE = 'Find the law you need in minutes, not hours',
  POINT_ONE_SUBTITLE = 'Unlock a powerful suite of research tools and boost your firm’s efficiency.',
  POINT_TWO_TITLE = 'Search the way you think with intuitive, modern tools',
  POINT_TWO_SUBTITLE = 'Skip the tricky boolean and keywords.',
  POINT_THREE_TITLE = 'Get excellent support',
  POINT_THREE_SUBTITLE = 'Rely on our friendly, accessible support team.'
}

// Structure of 'callout'/bulletpoint for the teaser
interface TeaserCallout {
  text: [TeaserContent, TeaserContent] /* First is bullet point title, second is subtitle */;
  icon: JSX.Element;
}

/**
 * Teaser component to funnel users to start a trial.
 *
 * @param type for analytics, either teaser accessed from database or from search filters
 * @returns a teaser page to allow the user to enter the casetext trial page
 */
const Teaser = ({ type }: TeaserProps): JSX.Element => {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  /**
   * Get trial link
   */
  const trialLink = () => {
    type === TeaserType.FILTER
      ? AnalyticsService.Instance.track(ClientAnalyticsEvent.CLICKED_FILTER_TEASER_CTA)
      : AnalyticsService.Instance.track(ClientAnalyticsEvent.CLICKED_DB_TEASER_CTA);
    window.location.href = getAngularAppUrl('/trial');
  };

  // Array of 'callouts', or the bulletpoints of the teaser
  const callouts: TeaserCallout[] = [
    {
      text: [TeaserContent.POINT_ONE_TITLE, TeaserContent.POINT_ONE_SUBTITLE],
      icon: <TimerOutlined className="ct-callouts-icon" />
    },
    {
      text: [TeaserContent.POINT_TWO_TITLE, TeaserContent.POINT_TWO_SUBTITLE],
      icon: <Flare className="ct-callouts-icon" />
    },
    {
      text: [TeaserContent.POINT_THREE_TITLE, TeaserContent.POINT_THREE_SUBTITLE],
      icon: <SupportAgent className="ct-callouts-icon" />
    }
  ];

  return (
    <div className="teaser">
      <CasetextSvg className="teaser-logo" />
      <Heading className="teaser-title" size={isSmDown ? 'xl' : '3xl'}>
        {type === TeaserType.FILTER ? TeaserTitle.FILTER : TeaserTitle.DATABASE}
      </Heading>
      <Button
        sx={{ maxWidth: '14.375rem', width: '100%' }}
        variant="contained"
        onClick={trialLink}
        size={isSmDown ? 'medium' : 'extra-large'}
      >
        <Text color="inherit">{TeaserContent.BUTTON_TEXT}</Text>
      </Button>
      <div className="teaser-callouts-box">
        {callouts.map((callout, index) => {
          return (
            <div className="teaser-callouts-container" key={index}>
              <div className="teaser-callouts">
                {callout.icon}
                <Text size={isSmDown ? 'sm' : 'lg'} weight="500" color="primary">
                  {callout.text[0]}
                </Text>
              </div>
              <Text size={isSmDown ? 'xs' : 'md'} color="secondary" className="teaser-callouts-sub">
                {callout.text[1]}
              </Text>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Teaser;
