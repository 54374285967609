import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import jurisdictionReducer from './jurisdictionSlice';
import searchReducer from './searchSlice';
import userReducer from './user';
import modalReducer from './modalSlice';
import toastReducer from './toastSlice';

export const reducer = {
  jurisdictionState: jurisdictionReducer,
  searchState: searchReducer,
  modalState: modalReducer,
  userState: userReducer,
  toastState: toastReducer
};

export const store = configureStore({ reducer });

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
