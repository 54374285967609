/**
 * Search modal states
 */
export enum SearchInputStates {
  NONE = 'none',
  DEFAULT = 'default',
  JX = 'jx'
}

/**
 * Modal states
 */
export enum ModalState {
  OPEN = 'open',
  CLOSED = 'closed'
}

export enum SignInModalBody {
  EMAIL = 'email',
  ADDITIONAL_INFO = 'additionalInfo',
  VERIFY_EMAIL = 'verifyEmail'
}
