import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastState } from '@utils/toast';

import { RootState } from './store';

const initialState: Toast = {
  toastStatus: ToastState.CLOSED,
  toastMessage: ''
};

/**
 * Toast state -- success / error (open) or closed
 */
export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToast: (state, { payload }: PayloadAction<Toast>) => {
      state.toastStatus = payload.toastStatus;
      state.toastMessage = payload.toastMessage;
      return state;
    }
  }
});

export const { setToast } = toastSlice.actions;
export const selectToastStatus = (state: RootState) => state.toastState.toastStatus;
export const selectToastMessage = (state: RootState) => state.toastState.toastMessage;
export default toastSlice.reducer;
