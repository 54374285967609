import { Close } from '@mui/icons-material';
import { Button, IconButton, styled } from '@mui/material';
import { SearchInputStates } from '@utils';
import { ClientAnalyticsEvent } from '@utils/analytics-events';
import classNames from 'classnames';
import AnalyticsService from 'services/analytics/AnalyticsService';
import { setSearchInputOpenState } from 'store/modalSlice';
import { selectUserQuery, setHidePSEducationalCard, setShowPSPopover } from 'store/searchSlice';
import { useAppDispatch, useAppSelector } from 'utils/hooks/redux';

import './ParallelSearchCard.scss';

export enum PSCardType {
  EDUCATIONAL = 'educational',
  SUCCESS = 'success'
}
interface PSCardProps {
  type: PSCardType;
  hidePsSuccessCard?: (psSuccessCardState: boolean) => void;
}

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.common.white,
  '&:hover': {
    background: theme.palette.brightblue['100']
  }
}));

const ParallelSearchCard = ({ type, hidePsSuccessCard }: PSCardProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const searchQuery = useAppSelector(selectUserQuery);

  const isEd = type === PSCardType.EDUCATIONAL;

  const showPSPopover = () => {
    // Handle event
    dispatch(setSearchInputOpenState(SearchInputStates.DEFAULT));
    dispatch(setShowPSPopover(true));
    dispatch(setHidePSEducationalCard(true));
    // Analytics
    AnalyticsService.Instance.track(ClientAnalyticsEvent.CLICKED_PS_CARD_CTA, { searchQuery });
  };

  const handleClose = () => {
    if (isEd) {
      dispatch(setHidePSEducationalCard(true));
    } else if (!!hidePsSuccessCard) {
      hidePsSuccessCard(true);
    }
  };

  return (
    <div
      className={classNames('ct-ps-card', {
        ed: isEd,
        success: !isEd
      })}
    >
      <IconButton edge="end" data-testid="modal-close" aria-label="close" className="close-btn" onClick={handleClose}>
        <Close htmlColor="black" />
      </IconButton>
      <div className="card-header">
        {isEd ? 'Find relevant results more quickly' : 'Welcome to the legal research revolution!'}
      </div>
      <div className="card-body">
        {isEd
          ? 'Our users report saving an average of 11.5 hours per month on legal research by searching with plain English sentences.'
          : 'Below you will find cases that contain sentences similar to the one you entered.'}
      </div>
      {isEd && (
        <div className="card-cta">
          <StyledButton onClick={showPSPopover}>Show Me the Faster Way</StyledButton>
        </div>
      )}
    </div>
  );
};

export default ParallelSearchCard;
