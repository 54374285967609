import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserVerificationStatus } from '@utils/user';

import { ApiStatus, HTTP_END_POINTS } from '../@utils/api';
import { RootState } from './store';
import AnalyticsService from '../services/analytics/AnalyticsService';

const initialState: UserStore = {
  createUserInformation: null,
  createUserStatus: null,
  user: null,
  verificationStatus: null,
  abTestVariation: null
};

// User async actions
/**
 * Fetch the current authenticated user
 */
export const fetchAuthenticatedUser = createAsyncThunk<UserData, void, { state: RootState }>(
  'user/fetchAuthenticatedUser',
  async (params, thunkAPI) => {
    try {
      const resp = await axios.get(HTTP_END_POINTS.USER_ME);
      const user = resp.data;

      // dont wait on promise
      AnalyticsService.Instance.identify(user.email, {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        position: user.position,
        psSearchCount: user.usage.count
      });

      return user;
    } catch (err: any) {
      throw thunkAPI.rejectWithValue(`User Fetch Failed. ${err && err.message}`);
    }
  }
);

/**
 * Create users that are not known users.
 */
export const fetchVerificationStatus = createAsyncThunk<UserVerificationStatus, string, { state: RootState }>(
  'user/fetchVerificationStatus',
  async (email: string, thunkAPI) => {
    try {
      const nextUrl =
        !!window.location && !!window.location.pathname
          ? `${window.location.pathname}${window.location.search || ''}`
          : undefined;
      const resp = await axios.get(HTTP_END_POINTS.VERIFY_USER, { params: { email, next_url: nextUrl } });
      return resp.data;
    } catch (err: any) {
      throw thunkAPI.rejectWithValue(`Verify User Fetch Failed. ${err && err.message}`);
    }
  }
);

/**
 * Authenticate user
 */
export const authenticateUser = createAsyncThunk<void, UserAuthParams, { state: RootState }>(
  'user/authenticateUser',
  async (params: UserAuthParams, thunkAPI) => {
    try {
      // this feels like a hack, but we cant submit the post with a ajax request. it wont follow the redirects.
      // so we need to create form and programmatically submit it.
      const form = document.createElement('form');
      const email = document.createElement('input');
      const verificationCode = document.createElement('input');
      const userId = document.createElement('input');
      const redirect = document.createElement('input');
      const password = document.createElement('input');
      const userStatus = document.createElement('input');

      form.method = 'POST';
      form.action = HTTP_END_POINTS.USER_AUTH;

      email.value = params.email;
      email.name = 'email';
      form.appendChild(email);

      verificationCode.value = params.verificationCode;
      verificationCode.name = 'verificationCode';
      form.appendChild(verificationCode);

      userId.value = params.userId;
      userId.name = 'userId';
      form.appendChild(userId);

      redirect.value = params.nextUrl;
      redirect.name = 'redirect';
      form.appendChild(redirect);

      userStatus.value = params.userStatus;
      userStatus.name = 'userStatus';
      form.appendChild(userStatus);

      password.value = 'dummy-xxx';
      password.name = 'password';
      form.appendChild(password);
      form.style.display = 'none';

      document.body.appendChild(form);

      form.submit();
    } catch (err: any) {
      throw thunkAPI.rejectWithValue(`User auth Failed. ${err && err.message}`);
    }
  }
);

/**
 * Fetch the current authenticated user
 */
export const createUser = createAsyncThunk<void, void, { state: RootState }>('user/createUser', async (_, thunkAPI) => {
  try {
    const userState = thunkAPI.getState().userState;
    const nextUrl =
      !!window.location && !!window.location.pathname
        ? `${window.location.pathname}${window.location.search || ''}`
        : undefined;
    await axios.post(HTTP_END_POINTS.CREATE_USER, { ...userState.createUserInformation, nextUrl });
  } catch (err: any) {
    throw thunkAPI.rejectWithValue(`User Fetch Failed. ${err && err.message}`);
  }
});

/**
 * User state
 */
export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUser: (state, { payload }) => {
      state.user = !!payload ? { ...payload } : null;
      return state;
    },
    setAbTestVariation: (state, { payload }) => {
      state.abTestVariation = payload;
    },
    setCreateUserInformation: (state, { payload }) => {
      state.createUserInformation = !!payload ? { ...payload } : null;
      return state;
    }
  },
  extraReducers: (builder) => {
    // fetchAuthenticatedUser
    builder.addCase(fetchAuthenticatedUser.fulfilled, (state, { payload }) => {
      state.user = { ...payload };
      return state;
    });
    builder.addCase(fetchAuthenticatedUser.rejected, (state, action) => {
      state.user = null;
      return state;
    });
    // fetchVerificationStatus
    builder.addCase(fetchVerificationStatus.fulfilled, (state, { payload }) => {
      state.verificationStatus = payload;
      return state;
    });
    builder.addCase(fetchVerificationStatus.rejected, (state, action) => {
      state.verificationStatus = null;
      return state;
    });
    // createUser
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.createUserStatus = ApiStatus.SUCCESS;
      return state;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.createUserStatus = ApiStatus.FAILURE;
      return state;
    });
  }
});

export const { setUser, setCreateUserInformation, setAbTestVariation } = userSlice.actions;
export const selectUser = (state: RootState): UserData | null => state.userState.user;
export const selectVerificationStatus = (state: RootState): UserData | null => state.userState.verificationStatus;
export const selectCreateUserStatus = (state: RootState): UserData | null => state.userState.createUserStatus;
export const selectCreateUserInformation = (state: RootState): UserData | null => state.userState.createUserInformation;
export const selectAbTestVariation = (state: RootState): string | null => state.userState.abTestVariation;

export default userSlice.reducer;
