import React from 'react';
import { Popper, ClickAwayListener, Fade, Paper, PopperProps, styled, Button } from '@mui/material';

interface FilterPopperProps extends PopperProps {
  onApply?: () => void;
  onClickAway?: () => void;
}

const ApplyButton = styled(Button)(({ theme }) => ({
  alignSelf: 'flex-end',
  marginTop: theme.spacing(2)
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.gray[300]}`,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2)
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.speedDial
}));

const FilterPopper = ({ children, onApply, onClickAway = () => null, ...props }: FilterPopperProps): JSX.Element => (
  <StyledPopper className="ct-filter-popper" disablePortal placement="bottom-start" transition {...props}>
    {({ TransitionProps }) => (
      <ClickAwayListener onClickAway={onClickAway}>
        <Fade {...TransitionProps} unmountOnExit>
          <StyledPaper className="ct-filter-popper-paper" elevation={2}>
            {children}
            {!!onApply && (
              <ApplyButton data-testid="ct-filter-apply" onClick={onApply} size="small" variant="contained">
                Apply
              </ApplyButton>
            )}
          </StyledPaper>
        </Fade>
      </ClickAwayListener>
    )}
  </StyledPopper>
);

export default FilterPopper;
