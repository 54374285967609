import { Jurisdiction, JX } from '@casetext/libjx';
import { get, set } from 'lodash';

export type JxSpecial = keyof typeof JX_SPECIAL;
export type JxTopLevel = keyof typeof JX_TOP_LEVEL;

type JxTree = Record<JxTopLevel, TreeItem>;
export const JX_TREE: JxTree = {
  STATE: {},
  FEDERAL: {}
};

type JxKeys = Record<JxSpecial | JxTopLevel, string[]>;
export const JX_KEYS: JxKeys = {
  BANKRUPTCY: [],
  CIRCUIT: [],
  DISTRICT: [],
  FEDERAL: [],
  STATE: [],
  SUPREME: []
};

export enum JX_SPECIAL {
  BANKRUPTCY = 'BANKRUPTCY',
  CIRCUIT = 'CIRCUIT',
  DISTRICT = 'DISTRICT',
  SUPREME = 'SUPREME'
}

export enum JX_TOP_LEVEL {
  FEDERAL = 'FEDERAL',
  STATE = 'STATE'
}

const isJxTopLevelId = (jxId?: string): boolean => {
  const root = jxId?.toUpperCase() as JX_TOP_LEVEL;
  return Object.values(JX_TOP_LEVEL).includes(root);
};

const SECONDARY_SOURCE_SUFFIX = 'secsrcs';
const EXCLUDED_FEDERAL_JXS = ['ustc', 'fedstat', 'adminmat', 'fedreg'];
const CODIFIED_TYPE = 'codified';

/** Generation function to populate JX_KEYS nad JX_TREE with all Jurisdictions from libjx */
const generateAllKeys = (jx: Jurisdiction) => {
  const { bankruptcy, children, circuit, district, id, supreme, type, isGavelyticsJx, isGuideJx } = jx;
  const root = id?.toUpperCase() as JX_TOP_LEVEL;
  const isRoot = Object.values(JX_TOP_LEVEL).includes(root);
  const jxKey = isRoot ? root : jxKeyFromId(id!);
  const jxPath = jxKey.replace(/-/g, '.');
  const isFederal = jxPath.startsWith(JX_TOP_LEVEL.FEDERAL);

  if (
    EXCLUDED_FEDERAL_JXS.includes(id!) ||
    id?.endsWith(SECONDARY_SOURCE_SUFFIX) ||
    type === CODIFIED_TYPE ||
    !!isGavelyticsJx ||
    !!isGuideJx
  ) {
    return;
  }

  set(JX_TREE, jxPath, jxKey);
  if (!isRoot) {
    JX_KEYS[isFederal ? JX_TOP_LEVEL.FEDERAL : JX_TOP_LEVEL.STATE].push(jxKey);
  }
  if (children) {
    Object.values(children).forEach((child) => generateAllKeys(child));
  } else {
    if (bankruptcy) JX_KEYS.BANKRUPTCY.push(jxKey);
    if (circuit) JX_KEYS.CIRCUIT.push(jxKey);
    if (district) JX_KEYS.DISTRICT.push(jxKey);
    if (supreme) JX_KEYS.SUPREME.push(jxKey);
  }
};

/**
 * Gets all child JX keys based on the given JX key
 *
 * @param jxKey - Current JX key (ex: `STATE-al-alcode`)
 * @returns Array of JX keys
 */
export const getChildJxKeys = (jxKey: string): string[] => {
  const keyPath = jxKey.replace(/-/g, '.');
  const childTree = get(JX_TREE, keyPath, {});
  const childKeys = Object.entries(childTree instanceof Object ? childTree : {}).flatMap(([jxId, jxChild]) => {
    if (jxChild instanceof Object) {
      const childKey = jxKeyFromId(jxId);
      return [childKey, ...getChildJxKeys(childKey)];
    } else {
      return jxChild as string;
    }
  });
  return childKeys;
};

/**
 * Reduce list of selected JX keys to valid JX ids
 *
 * @param jxObj - Object containing JX keys and values
 * @returns Array of JX ids
 */
export const getJxIdsFromKeys = (jxObj: Record<string, boolean | null>): string[] =>
  Object.entries(jxObj)
    .reduce<string[]>((arr, [key, value]) => [...arr, ...(value && !isJxSpecial(key) ? [key] : [])], [])
    .reduce<string[]>((arr, jxKey, _, base) => {
      const parent = jxKey.split('-');
      const jxId = parent.pop();
      const parentKey = parent.join('-');
      return [...arr, ...(base.includes(parentKey) ? [] : [jxId as string])];
    }, []);

/**
 * Gets the JX key for a Jurisdiction ID
 *
 * @param id - Jurisdiction ID
 * @returns JX key string
 */
export const jxKeyFromId = (id: string): string => {
  const { parent = null } = Object.keys(JX_TOP_LEVEL).includes(id.toUpperCase()) ? {} : JX.get(id);
  return !!parent && id !== parent.id ? `${jxKeyFromId(parent.id!)}-${id}` : id.toUpperCase();
};

/**
 * Converts Jurisdiction IDs to JX keys
 *
 * @param ids - Array of JX IDs
 * @returns Array of JX keys
 */
export const jxKeysFromIds = (ids: string[]): string[] => ids.map((id) => jxKeyFromId(id));

/**
 * Determines if the JX key is a special JX
 *
 * @param jxKey - Current JX key
 * @returns boolean
 */
export const isJxSpecial = (jxKey: string): boolean => Object.keys(JX_KEYS).includes(jxKey);

generateAllKeys(JX.all.federal);
generateAllKeys(JX.all.state);

/**
 * Helper function to get a specific name variation for a jx item
 *
 * @param jxData A (libJx) jurisdiction item
 * @param variation 'name'|'alt'|'abbrev'
 * @param fallbackPrompt String used in case prompt couldn't get generated from jx data
 */
const getJxPrompt = (jxData: Jurisdiction, variation?: 'name' | 'alt' | 'abbrev', fallbackPrompt = ''): string => {
  if (jxData && variation) {
    switch (variation.toLowerCase()) {
      case 'name':
        return jxData.name || fallbackPrompt;
      case 'alt':
        return jxData.alt || jxData.label || jxData.name || fallbackPrompt;
      case 'abbrev':
        return jxData.abbrev || jxData.alt || jxData.label || jxData.code || fallbackPrompt;
      default:
        return jxData.alt || jxData.abbrev || jxData.name || fallbackPrompt;
    }
  }
  // used for special filters (bankruptcy, supreme, etc.)
  return fallbackPrompt || '';
};

/**
 * Returns enhanced version of a jx prompt, including parent level info
 * (e.g. data for "bankrndny" -> "2nd Cir. - Bankr. ND NY", data for "flsct" -> "FL - Supr. Ct.")
 *
 * @param jx - The jx data
 */
export const getEnrichedJxPrompt = (jx?: Jurisdiction): string => {
  if (!jx || !jx.id) {
    return '';
  } else {
    let title = '';

    // Get parent prompt (ignore top level parents (federal, state))
    const parentPrompts: string[] = [];
    const findRecursive = (opt?: Jurisdiction) => {
      const parentId = opt && opt.parent && opt.parent.id;
      if (!!parentId && !isJxTopLevelId(parentId)) {
        parentPrompts.push(getJxPrompt(JX.get(parentId), 'abbrev', 'jx'));
        findRecursive(opt && opt.parent);
      }
    };
    findRecursive(jx);

    // If we have more than one parent, get the outermost one.
    const parentPrompt = parentPrompts.pop();
    let childNameVariation: any = 'alt';

    if (parentPrompt) {
      title = parentPrompt + ' - ' + title;
      childNameVariation = 'abbrev';
    }
    title = title + (getJxPrompt(JX.get(jx.id), childNameVariation, jx.name) || 'filter');
    if (!!jx.children) {
      title = title + ' - All';
    }

    return title;
  }
};

/**
 * Gets the JX name
 *
 * @param id - Jurisdiction ID
 */
export const getJxName = (id: string): string => {
  return JX.get(id).name;
};

/**
 * Describes the properties on `JX.all`.
 */
export interface JXRoot {
  state: Jurisdiction;
  federal: Jurisdiction;
}

/**
 * This is duplicated from lib/shared/jx-utils/jx-utils.ts
 * The given Jurisdiction node is not includes in the resulting array. Only the children.
 *
 * @param node The Jurisdiction to iterate over it's children.
 * @param predicate A function that returns `true` if the given child jurisdiction should be included in the array of results.
 * @returns An array of child `Jurisdiction`s that evaluate to `true` in the given `predicate` function.
 */
export const filterChildren = (node: Jurisdiction, predicate: (childNode: Jurisdiction, level: number) => boolean) =>
  deepFilterChildren(node, predicate, false);

/**
 * This is duplicated from lib/shared/jx-utils/jx-utils.ts
 * The given jurisdiction node is not includes in the resulting array. Only the children.
 *
 * @param node The jurisdiction to recursively traverse it's children.
 * @param predicate A function that returns `true` if the given child jurisdiction at the given level of the tree should be included in the array of results.
 * @returns An array of child `Jurisdiction`s that evaluate to `true` in the given `predicate` function.
 */
export const deepFilterChildren = (
  node: Jurisdiction,
  predicate: (childNode: Jurisdiction, level: number) => boolean,
  // Private internal arguments
  isDeep = true,
  members: Jurisdiction[] = [],
  level = 0
) => {
  if (node.children) {
    for (const child of Object.keys(node.children)) {
      const childNode = node.children[child];
      if (predicate(childNode, level)) {
        members.push(childNode);
      }
      if (isDeep) {
        deepFilterChildren(childNode, predicate, isDeep, members, level + 1);
      }
    }
  }
  return members;
};
