import {
  Button,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  useTheme,
  useMediaQuery,
  FormHelperText
} from '@mui/material';
import { getAngularAppUrl, SignInModalBody } from '@utils';
import { resendEmail, UserPosition, UserVerificationStatus } from '@utils/user';
import { useState } from 'react';
import classnames from 'classnames';
import { useAppDispatch } from 'utils/hooks/redux';
import { createUser, fetchVerificationStatus, setCreateUserInformation } from 'store/user';
import AnalyticsService from 'services/analytics/AnalyticsService';
import { ClientAnalyticsEvent } from '@utils/analytics-events';

import { LinkButton, Text } from '../design_component_lib';

import { ReactComponent as EmailSentSvg } from '../../assets/email_sent.svg';
import './SignInContent.scss';

interface ValidationErrors {
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  position?: string;
}

const EMAIL_REGEX = new RegExp(
  '^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
);

export const PHONE_REGEX = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;

/**
 * Modal for promting user to SignIn or create account.
 * @param SignInModalProps
 * @returns
 */
const SignInContent = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [modalBody, setModalBody] = useState(SignInModalBody.EMAIL);
  const [isKnownUser, setIsKnownUser] = useState(false);
  const [info, setInfo] = useState<UserData>({
    firstName: '',
    lastName: '',
    phone: '',
    position: '',
    email: ''
  });
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  let buttonDisabled = true;

  const validateAdditionalInfo = (): boolean => {
    const { firstName, lastName, position, phone } = info;
    const newErrors: ValidationErrors = {};
    if (!phone) {
      newErrors.phone = 'Phone Number is required';
    } else if (!PHONE_REGEX.test(phone)) {
      newErrors.phone = 'Phone Number is invalid';
    }
    if (!firstName) {
      newErrors.firstName = 'First Name is required';
    }
    if (!lastName) {
      newErrors.lastName = 'Last Name is required';
    }
    if (!position) {
      newErrors.position = 'Position is required';
    }

    setValidationErrors(newErrors);
    return !Object.keys(newErrors).length;
  };

  const setModalBodyState = () => {
    const { firstName, lastName, position, email } = info;
    switch (modalBody) {
      case SignInModalBody.EMAIL:
        if (!EMAIL_REGEX.test(info.email)) {
          setValidationErrors({ email: 'Email is required' });
          return;
        }

        dispatch(fetchVerificationStatus(email)).then((status) => {
          const userStatus = status.payload;
          AnalyticsService.Instance.track(ClientAnalyticsEvent.CLICKED_CONTINUE_IN_EMAIL_MOD, {
            email,
            emailType: userStatus
          });
          setIsKnownUser(userStatus === UserVerificationStatus.KNOWN_USER);
          setModalBody(
            userStatus === UserVerificationStatus.UNKNOWN_USER
              ? SignInModalBody.ADDITIONAL_INFO
              : SignInModalBody.VERIFY_EMAIL
          );
        });
        break;
      case SignInModalBody.ADDITIONAL_INFO:
        if (validateAdditionalInfo()) {
          AnalyticsService.Instance.track(ClientAnalyticsEvent.CLICKED_CONTINUE_IN_ADD_INFO_MOD, {
            fullName: `${firstName} ${lastName}`,
            email,
            position
          });
          dispatch(setCreateUserInformation(info));
          dispatch(createUser()).then(() => setModalBody(SignInModalBody.VERIFY_EMAIL));
        }
        break;
    }
  };

  /**
   * Function to allow the user to change their
   * email without erasing all other data
   */
  const changeEmail = () => {
    setModalBody(SignInModalBody.EMAIL);
    setInfo((prevState) => ({
      ...prevState,
      email: ''
    }));
  };

  /**
   * Called as the user fills out
   * fields in the modal to update state
   */
  const handleInfoChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePositionChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  /**
   * Function to return the corrct copy to the modal body.
   */
  const getModalText = () => {
    switch (modalBody) {
      case SignInModalBody.EMAIL:
        if (EMAIL_REGEX.test(info.email)) {
          buttonDisabled = false;
        }
        return (
          <TextField
            error={!!validationErrors.email}
            helperText={validationErrors.email}
            variant="outlined"
            label="Your email address"
            className="input-field"
            inputProps={{ className: 'input' }}
            value={info.email}
            fullWidth={true}
            name="email"
            onChange={handleInfoChange}
            onKeyDown={(key) => key.key === 'Enter' && setModalBodyState()}
          />
        );
      case SignInModalBody.ADDITIONAL_INFO:
        if (!!info.firstName && !!info.lastName && !!info.phone && !!info.position) {
          buttonDisabled = false;
        }
        return (
          <div className="form-body">
            <div className={classnames('name-field', { 'is-mobile': isSmDown })}>
              <TextField
                error={!!validationErrors.firstName}
                helperText={validationErrors.firstName}
                variant="outlined"
                label="First Name"
                className="input-field"
                inputProps={{ className: 'input' }}
                value={info.firstName}
                onChange={handleInfoChange}
                name="firstName"
                fullWidth={true}
                onKeyDown={(key) => key.key === 'Enter' && setModalBodyState()}
              />
              <TextField
                error={!!validationErrors.lastName}
                helperText={validationErrors.lastName}
                variant="outlined"
                label="Last Name"
                className="input-field"
                inputProps={{ className: 'input' }}
                value={info.lastName}
                onChange={handleInfoChange}
                name="lastName"
                fullWidth={true}
                onKeyDown={(key) => key.key === 'Enter' && setModalBodyState()}
              />
            </div>

            <TextField
              error={!!validationErrors.phone}
              helperText={validationErrors.phone}
              variant="outlined"
              label="Phone Number"
              className="input-field"
              inputProps={{ className: 'input' }}
              value={info.phone}
              onChange={handleInfoChange}
              name="phone"
              fullWidth={true}
              onKeyDown={(key) => key.key === 'Enter' && setModalBodyState()}
            />
            <FormControl fullWidth={true} error={!!validationErrors.position} className="input-field">
              <InputLabel>Position</InputLabel>
              <Select
                error={!!validationErrors.position}
                variant="outlined"
                label="Position"
                inputProps={{ className: 'input' }}
                value={info.position}
                onChange={handlePositionChange}
                name="position"
              >
                {Object.values(UserPosition).map((position) => {
                  return (
                    <MenuItem key={position} value={position}>
                      {position}
                    </MenuItem>
                  );
                })}
              </Select>
              {validationErrors.position ? <FormHelperText>{validationErrors.position}</FormHelperText> : null}
            </FormControl>
          </div>
        );
      case SignInModalBody.VERIFY_EMAIL:
        return (
          <div className="verification-sent verification-content">
            <EmailSentSvg className="icon" />
            <>
              <div className="title">{`Check your email to ${isKnownUser ? 'sign in' : 'verify your account'}`}</div>
              <div className="byline">
                We sent a sign-in link to {info.email}.<br />
                If you did not receive an email, check your spam folder,{' '}
                <LinkButton
                  className="link-button"
                  onClick={() => resendEmail(dispatch, info.email)}
                  label={'resend the link'}
                  size={isSmDown ? 'sm' : 'md'}
                  sx={{ verticalAlign: 'baseline' }}
                />
                {', '}
                <LinkButton
                  className="link-button"
                  onClick={changeEmail}
                  label={'change your email'}
                  size={isSmDown ? 'sm' : 'md'}
                  sx={{ verticalAlign: 'baseline' }}
                />
                {', or '}
                <LinkButton
                  aria-label="contact support"
                  href={getAngularAppUrl('/contact-us')}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="link-button"
                  label={'contact us'}
                  size={isSmDown ? 'sm' : 'md'}
                  sx={{ verticalAlign: 'baseline', cursor: 'pointer' }}
                />
                .
              </div>
            </>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <>
      <div className="signin">
        {modalBody !== 'verifyEmail' && (
          <div className="signin-heading">
            <Text className="heading-text" size={isSmDown ? 'sm' : 'lg'} color="secondary">
              Thank you for trying Parallel Search!
            </Text>
            <Text className="heading-text" size={isSmDown ? '2xl' : '3xl'}>
              To continue, tell us a little about yourself
            </Text>
          </div>
        )}

        <div className="signin-body">
          {getModalText()}
          {modalBody !== 'verifyEmail' && (
            <Button
              className="continue-button"
              onClick={setModalBodyState}
              variant="contained"
              fullWidth={true}
              disabled={buttonDisabled}
              size="extra-large"
            >
              Continue
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default SignInContent;
