import React from 'react';
import { Checkbox, CheckboxProps, FormControlLabel, styled } from '@mui/material';

import { UiLabel } from '../design_component_lib';

interface CheckboxTriProps extends Pick<CheckboxProps, 'size'> {
  checked: Tristate;
  endInset?: number;
  id: string;
  isRoot?: boolean;
  label: string | JSX.Element;
  metaLabel?: string;
  onClick?: (key: string, checked: boolean) => void;
}

interface CheckboxTriLabelProps extends Pick<CheckboxTriProps, 'endInset' | 'isRoot'> {}

const Label = styled(FormControlLabel, {
  shouldForwardProp: (prop) => !['endInset', 'isRoot'].includes(prop as string)
})<CheckboxTriLabelProps>(({ endInset, isRoot, theme }) => ({
  flex: 1,
  marginRight: endInset ? theme.spacing(endInset) : 0,

  '.ct-checkbox-tri-label': {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    fontWeight: isRoot ? 'bold' : undefined,
    justifyContent: 'space-between',

    '.meta-label': {
      marginLeft: theme.spacing(1)
    }
  }
}));

/**
 * Component to render a controlled tri-state Checkbox
 *
 * @param checked - State of the checkbox (`true`, `false`, or `null`)
 * @param endInset - `number` Margin to offset the end of the checkbox (uses `theme.spacing`)
 * @param id - Unique identifier of the field
 * @param isRoot - `boolean` Makes label bold if true
 * @param label - `string` | `JSX.Element` to use for the label
 * @param metaLabel - Additional information to display on the label
 * @param onClick - onClick handler for the checkbox
 * @param size - Size of the Checkbox component
 */
export const CheckboxTri = ({
  checked,
  id,
  label,
  metaLabel,
  onClick = () => null,
  size,
  ...labelProps
}: CheckboxTriProps): JSX.Element => (
  <Label
    classes={{ label: 'ct-checkbox-tri-label', root: 'ct-checkbox-tri' }}
    control={
      <Checkbox
        className="ct-checkbox-tri"
        checked={!!checked}
        indeterminate={checked === null}
        onChange={(_, chk) => onClick(id, chk)}
        size={size}
      />
    }
    label={
      <>
        {label}
        {metaLabel && (
          <UiLabel className="meta-label" color="inherit" size="sm">
            {metaLabel}
          </UiLabel>
        )}
      </>
    }
    {...labelProps}
  />
);

export default CheckboxTri;
