import { useEffect, useState } from 'react';
import { Alert, Button, Collapse, Container } from '@mui/material';
import format from 'date-fns/format';
import { Link, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'utils/hooks/redux';
import { resendEmail } from '@utils/user';
import { ABExperiments } from '@utils';
import { selectAbTestVariation, setAbTestVariation } from 'store/user';

import { SearchInput } from '../components1';
import { UiLabel } from '../components1/design_component_lib';

import { ReactComponent as CasetextSvg } from '../assets/saps_logo_homepage.svg';
import './Homepage1.scss';

enum QUERY_PARAMS {
  EMAIL = 'email',
  AUTH_FAILURE = 'auth_failure'
}

/** The homepage component. */
const Homepage1 = () => {
  const [searchParams] = useSearchParams();
  const currentYear = format(new Date(), 'yyyy');

  const [authFailure, setAuthFailure] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const abTestVariation = useAppSelector(selectAbTestVariation);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setAbTestVariation(ABExperiments.HOMEPAGE_EXP_1));
    const hasAuthFailure = searchParams.get(QUERY_PARAMS.AUTH_FAILURE) === 'true';
    const email = searchParams.get(QUERY_PARAMS.EMAIL);
    setAuthFailure(hasAuthFailure);
    setEmail(email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-testid="casetext-homepage" className="ct-homepage-main1">
      <div className="ct-hompage-header">
        <Link to={`/${abTestVariation ? `?exp=${abTestVariation}` : ''}`}>
          <CasetextSvg className="saps-logo-svg" />
        </Link>
      </div>
      <Container className="ct-homepage-content" maxWidth="md">
        <UiLabel className="big-header" color="primary">
          Find a case that says...
        </UiLabel>
        <SearchInput isHomepage={true} />
        <div className="ps-example">
          <UiLabel color="secondary">Example:</UiLabel>
          <UiLabel className="example-text" color="secondary">
            Target’s employees were uncompensated while waiting for loss prevention inspections before leaving work
          </UiLabel>
        </div>
        <Collapse in={authFailure} className="auth-alert">
          <Alert
            severity="error"
            action={
              <Button
                className="resend-button"
                color="inherit"
                size="small"
                onClick={() => !!email && resendEmail(dispatch, email)}
              >
                Resend link
              </Button>
            }
          >
            Unable to verify user
          </Alert>
        </Collapse>
      </Container>
      <div className="social-proof">
        <div className="name">William Fritz, Fritz Law Offices:</div>
        <div className="quote">
          “You have to try it to really appreciate it. It literally changes the way you practice law.”
        </div>
      </div>
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-links">
            <a href="https://casetext.com/terms" target="terms-of-service" rel="noopener noreferrer">
              Terms
            </a>
            <a href="https://casetext.com/privacy" target="privacy-policy" rel="noopener noreferrer">
              Privacy
            </a>
          </div>
          <div className="footer-copyright">© {currentYear} Casetext Inc.</div>
        </div>
      </div>
    </div>
  );
};

export default Homepage1;
