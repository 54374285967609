import axios from 'axios';

/**
 * Analytics service.
 * This service will determine if analytics actions can be sent from the client
 * or have to get tunneled to the server.
 *
 * We can't use a convenient "const AnalyticsServiceInstance = AnalyticsService.Instance" because it will
 * call the constructor as soon as the service gets imported, which is bad for testing
 * (initial axios request can't get intercepted in tests and will hit _real_ segment API url).
 * So we'll have to call the (stored) instance manually by using
 *   AnalyticsService.Instance.track
 *   AnalyticsService.Instance.identify
 *
 * Note: track and identify won't wait for the adBlocker check to finish, meaning:
 * a call to one of them right after initialization (before the check is done) will go through the server,
 * although after the adBlocker check is done, the result might be that no adBlocker is active and client side handling
 * could get used (which then will get used for every following call).
 * By doing so, `track` and `identify` can stay synchronous and don't need any promise handling.
 */
import { LOG } from '../logging/LoggingService';
import { ALWAYS_TRIGGERED_CLIENT_SIDE_SET, CLIENT_VARIANT_EVENT_NAME_SUFFIX } from '../../@utils/analytics-events';
import { HTTP_END_POINTS } from '../../@utils';

interface MyWindow extends Window {
  analytics?: any;
}

declare let window: MyWindow;

class AnalyticsService {
  protected static _instance: AnalyticsService;
  private adBlockerEnabled = true;
  /** The promise created during the init process */
  private initialized: Promise<void> | null = null;

  /**
   * The URL we will ping to determine if an ad blocker (or firm network)
   *  is preventing us from contacting the analytics server.
   */
  protected static readonly PING_ANALYTICS_URL = 'https://api.segment.io/v1/t';

  private static getDefaultAnalyticsData(data: any) {
    const eventData = data || {};
    eventData.appType = 'SAPS';
    return eventData;
  }

  private static async pingAnalytics() {
    return axios.get(AnalyticsService.PING_ANALYTICS_URL);
  }

  /**
   * @constructor
   */
  protected constructor() {}

  /**
   * Singleton accessor.
   *
   * @constructor
   */
  public static get Instance(): AnalyticsService {
    return this._instance || (this._instance = new AnalyticsService());
  }

  /**
   * Calls `doInit()` once, only if it hasn't been called before, and only resolves when this class is initialized.
   *
   * @returns a Promise that resolves when this service is initialized.
   */
  protected async ensureInit(): Promise<void> {
    this.initialized = this.initialized || this.init();
    await this.initialized;
  }

  /**
   * Store adBlocker status
   * @protected
   */
  protected async setAdBlockerStatus() {
    try {
      await AnalyticsService.pingAnalytics();
      this.adBlockerEnabled = false;
    } catch (err) {
      this.adBlockerEnabled = true;
      LOG.warn('Ad blocker present. Using tunneling.');
    }
  }

  async init(): Promise<void> {
    await this.setAdBlockerStatus();

    const notLoaded = !!window.analytics && window.analytics.load;
    if (notLoaded && process.env.REACT_APP_MOVANT_SEGMENT_CLIENT_KEY) {
      window.analytics.load(process.env.REACT_APP_MOVANT_SEGMENT_CLIENT_KEY);
    }
  }

  /**
   * Track event
   * @param message
   * @param data
   * @param viaServer
   */
  async track(message: string, data?: any, viaServer?: boolean): Promise<[void, void]> {
    await this.ensureInit();

    const eventData = AnalyticsService.getDefaultAnalyticsData(data);
    const hasAnalyticsTrack = !!window && !!window.analytics && !!window.analytics.track;
    let serverPromise: Promise<void> | undefined;
    let clientPromise: Promise<void> | undefined;
    let clientEventName = message;
    let forceClientEvent = false;

    if (this.adBlockerEnabled || !!viaServer || !hasAnalyticsTrack) {
      // WARNING: This is a one-off exception to always having axios as a redux action
      serverPromise = axios
        .post(HTTP_END_POINTS.TRACK_ANALYTICS, { eventName: message, eventData })
        .then((response) => response.data)
        .catch((err) => {
          LOG.error('Error tracking event', err);
          return null;
        });
      if (ALWAYS_TRIGGERED_CLIENT_SIDE_SET.has(message)) {
        clientEventName = `${message}${CLIENT_VARIANT_EVENT_NAME_SUFFIX}`;
        forceClientEvent = true;
      }
    }

    if (((!this.adBlockerEnabled && !viaServer) || forceClientEvent) && hasAnalyticsTrack) {
      clientPromise = new Promise<void>((resolve) => {
        window.analytics.track(clientEventName, eventData, undefined, resolve);
      });
    }

    // We will usually have serverPromise or clientPromise, and in special exceptions both.
    return Promise.all([serverPromise, clientPromise]);
  }

  /**
   * Identify user
   * @param email
   * @param data
   */
  async identify(email: string, data?: any): Promise<void> {
    await this.ensureInit();

    if (!!email) {
      const userTraits = { ...(data || {}), email };
      const hasAnalyticsIdentify = !!window && !!window.analytics && !!window.analytics.identify;
      if (this.adBlockerEnabled || !hasAnalyticsIdentify) {
        // WARNING: This is a one-off exception to always having axios as a redux action
        axios
          .post(HTTP_END_POINTS.IDENTIFY_ANALYTICS, { email, userTraits })
          .then((response) => response.data)
          .catch((err) => {
            LOG.error('Error identifying user', err);
            return null;
          });
      } else {
        window.analytics.identify(email, userTraits);
      }
    }
  }

  /**
   * Track page
   *
   * @param category
   * @param name
   * @param properties
   */
  async page(category?: string, name?: string, properties?: any): Promise<void> {
    await this.ensureInit();

    const hasAnalyticsPage = !!window && !!window.analytics && !!window.analytics.page;
    if (this.adBlockerEnabled || !hasAnalyticsPage) {
      // WARNING: This is a one-off exception to always having axios as a redux action
      axios
        .post(HTTP_END_POINTS.PAGE_ANALYTICS, {
          category,
          name,
          properties
        })
        .then((response) => response.data)
        .catch((err) => {
          LOG.error('Error analytic page', err);
          return null;
        });
    } else {
      window.analytics.page();
    }
  }
}

export default AnalyticsService;
