import React from 'react';
import { AppBar, Toolbar } from '@mui/material';

import { SearchInput } from '../index';

import './MobileAppBar.scss';

/**
 * Mobile App Bar header
 */
const MobileAppBar = (): JSX.Element => {
  return (
    <AppBar
      position="fixed"
      classes={{
        root: 'ct-mobile-toolbar',
        positionFixed: 'ct-fixed-toolbar'
      }}
    >
      <Toolbar disableGutters={true}>
        <div className="search-container">
          <SearchInput isSearchResultsPage={true} />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default MobileAppBar;
