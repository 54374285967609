import React, { useEffect, useState } from 'react';
import { ThemeProvider, useMediaQuery, useTheme } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import {
  selectSearchEdModalState,
  selectShareModalState,
  selectSignInModalState,
  selectTeaserModalState
} from 'store/modalSlice';
import StatusToast from 'components/StatusToast';
import Modal, { ModalType } from 'components/modal/Modal';

import { Routing } from '../components/routing/Routing';
import { fetchAuthenticatedUser } from '../store/user';
import { mainTheme } from '../utils/themes';
import { useAppDispatch, useAppSelector } from '../utils/hooks/redux';
import { MobileAppBar } from '../components';
import { selectSearchResultsStatus } from '../store/searchSlice';
import { ApiStatus } from '../@utils';

import './App.scss';

interface MyWindow extends Window {
  dataLayer?: any;
}
declare let window: MyWindow;

function App() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isHomepage, setIsHomepage] = useState<boolean>(false);
  const [isVerify, setIsVerify] = useState<boolean>(false);
  const isSearchEdModalOpen = useAppSelector(selectSearchEdModalState);
  const isSignInModalOpen = useAppSelector(selectSignInModalState);
  const isShareModalOpen = useAppSelector(selectShareModalState);
  const isTeaserModalOpen = useAppSelector(selectTeaserModalState);
  const searchResultsStatus = useAppSelector(selectSearchResultsStatus);

  useEffect(() => {
    dispatch(fetchAuthenticatedUser());
    setTimeout(() => {
      // Set up google optimize. We dont _have_ to use custom events, but in the event we want to
      // we have this setup.
      if (!!window.dataLayer && !!window.dataLayer.push) {
        // Activate the event with JavaScript for Google optimize with a Custom event
        // https://support.google.com/optimize/answer/7008840?hl=en#custom-event
        window.dataLayer.push({ event: 'optimize.activate' });
      }
    }, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={mainTheme}>
      <div className="saps-app">
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <div className="saps-main">
            {isMobile && !isHomepage && !isVerify && searchResultsStatus !== ApiStatus.LOADING && <MobileAppBar />}
            <Routing setIsHomepage={setIsHomepage} setIsVerify={setIsVerify} />
            <StatusToast />
            <Modal
              isModalOpen={isTeaserModalOpen.modalState}
              teaserType={isTeaserModalOpen.teaserType}
              type={ModalType.TEASER}
            />
            <Modal isModalOpen={isShareModalOpen} type={ModalType.SHARE} />
            <Modal isModalOpen={isSignInModalOpen} removeCloseBtn={true} type={ModalType.SIGN_IN} />
            <Modal isModalOpen={isSearchEdModalOpen.modalState} type={ModalType.SEARCH_ED} />
          </div>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
