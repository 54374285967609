import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import classnames from 'classnames';
import { useMediaQuery, useTheme } from '@mui/material';
import Homepage1 from 'views/Homepage1';
import Homepage2 from 'views/Homepage2';
import { ABExperiments } from '@utils';

import Homepage from '../../views/Homepage';
import SearchResults from '../../views/SearchResults';
import Verify from '../../views/Verify';
import { IN_APP_ROUTES } from '../../@utils/routes';
import AnalyticsService from '../../services/analytics/AnalyticsService';

import './Routing.scss';

interface RoutingProps {
  setIsHomepage: (isHomepage: boolean) => void;
  setIsVerify: (isVerify: boolean) => void;
}

function HomepageABTest(): JSX.Element {
  const [searchParams] = useSearchParams();
  if (searchParams.get('exp') === ABExperiments.HOMEPAGE_EXP_1) {
    return <Homepage1 />;
  } else if (searchParams.get('exp') === ABExperiments.HOMEPAGE_EXP_2) {
    return <Homepage2 />;
  }
  return <Homepage />;
}

/**
 * The application routing component. All routes defined here.
 */
export function Routing({ setIsHomepage, setIsVerify }: RoutingProps) {
  const location = useLocation();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [activePage, setActivePage] = useState<string | null>(null);

  useEffect(() => {
    if (activePage !== location.pathname) {
      setActivePage(location.pathname);
      setIsHomepage(location.pathname === IN_APP_ROUTES.HOMEPAGE);
      setIsVerify(location.pathname === IN_APP_ROUTES.VERIFY);
      AnalyticsService.Instance.page();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div
      className={classnames('saps-routing', {
        'is-mobile': isSmDown,
        'is-search-results': activePage === IN_APP_ROUTES.SEARCH_RESULTS
      })}
    >
      <Routes>
        <Route path={IN_APP_ROUTES.HOMEPAGE} element={<HomepageABTest />} />
        <Route path={IN_APP_ROUTES.SEARCH_RESULTS} element={<SearchResults />} />
        <Route path={IN_APP_ROUTES.VERIFY} element={<Verify />} />
        <Route path="*" element={<Navigate to={IN_APP_ROUTES.HOMEPAGE} replace={true} />} />
      </Routes>
    </div>
  );
}
