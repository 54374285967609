import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SearchResultType, SortOptions, searchParamValueToStr } from '@utils';
import AnalyticsService from 'services/analytics/AnalyticsService';
import { ClientAnalyticsEvent } from '@utils/analytics-events';

import { ApiStatus, HTTP_END_POINTS } from '../@utils/api';
import { RootState } from './store';
import { CITATION_FORMAT, getCitation } from '../@utils/copy-cite-utils';
import { fetchAuthenticatedUser } from './user';

const initialState: SearchStore = {
  showPSPopover: false,
  hidePSEducationalCard: false,
  userQuery: '',
  activeSearchFilters: {
    page: 1,
    sort: SortOptions.RELEVANCE,
    tab: null,
    type: SearchResultType.CASE
  },
  searchResults: {
    status: ApiStatus.NONE,
    results: null
  }
};

// search async actions
/**
 * perform a check engine query
 */
export const checkEngine = createAsyncThunk<string, void, { state: RootState }>(
  'search/checkEngine',
  async (_: void, thunkAPI) => {
    const searchState = thunkAPI.getState().searchState;
    const userQuery = searchState.userQuery;

    // Get search type (search-api / parallel search) by querying movant backend proxy
    const resp = await axios.get(`${HTTP_END_POINTS.MOVANT_BACKEND_PROXY}/engine?q=${userQuery}`);
    return resp.data.engine;
  }
);

/**
 * perform a search
 */
export const performSearch = createAsyncThunk<any, void, { state: RootState }>(
  'search/performSearch',
  async (_: void, thunkAPI) => {
    const searchState = thunkAPI.getState().searchState;
    const jurisdictionState = thunkAPI.getState().jurisdictionState;
    const userQuery = searchState.userQuery;
    const selectedJxs = jurisdictionState.selectedJXs;
    const activeSearchFilters = searchState.activeSearchFilters;
    const activeFiltersStrs: { [key: string]: string } = {};
    let resp;

    for (const [key, val] of Object.entries(activeSearchFilters)) {
      if (val !== null && val !== undefined) {
        activeFiltersStrs[key] = searchParamValueToStr(val);
      }
    }

    const queryParams = {
      q: userQuery,
      jxs: !!selectedJxs && selectedJxs.length ? selectedJxs.join(',') : undefined,
      ...activeFiltersStrs
    };

    resp = await axios.get(HTTP_END_POINTS.SEARCH, {
      params: queryParams
    });

    AnalyticsService.Instance.track(ClientAnalyticsEvent.SEARCHED, {
      searchQuery: userQuery,
      searchType: resp?.data?.engine,
      jxs: !!selectedJxs && selectedJxs.length ? selectedJxs.join(',') : '',
      queryTypes: resp?.data?.results?.queryTypes
    });

    /**
     * We need to fetch the authenticated user after every search to update the user's search count.
     * For the Parallel Search Education Card behavior, where we only show the card on user's first parallel search.
     */
    thunkAPI.dispatch(fetchAuthenticatedUser());

    return { ...resp.data };
  }
);

/**
 * Fetch the cite info and copy a cite to clip board
 */
export const copyCiteToClipboard = createAsyncThunk<string, CopyCiteParams, { state: RootState }>(
  'search/copyCiteToClipboard',
  async (params: CopyCiteParams) => {
    const resp = await axios.get(HTTP_END_POINTS.CITE_INFO(params.docSlug));
    const citeData = resp.data;
    const { pageNum, citationText } = params;

    const caseCitation = getCitation(CITATION_FORMAT.BLUEBOOK, citeData, pageNum, citationText);

    return caseCitation;
  }
);

/**
 * Search state
 */
export const searchSlice = createSlice({
  name: 'search',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetSearch: (state) => {
      state.searchResults = initialState.searchResults;
    },
    setShowPSPopover: (state, { payload }) => {
      state.showPSPopover = payload;
    },
    setHidePSEducationalCard: (state, { payload }) => {
      state.hidePSEducationalCard = payload;
    },
    resetSearchResults: (state) => {
      state.searchResults.results = null;
      return state;
    },
    setUserQuery: (state, { payload }) => {
      state.userQuery = payload;
      return state;
    },
    setActiveSearchFilters: (state, { payload }) => {
      state.activeSearchFilters = { ...payload };
      return state;
    },
    updateActiveSearchFilters: (state, { payload }) => {
      const pageResetIfNotPaged = payload.page || 1;
      state.activeSearchFilters = { ...state.activeSearchFilters, ...payload, page: pageResetIfNotPaged };
      return state;
    }
  },
  extraReducers: (builder) => {
    // performSearch
    builder.addCase(performSearch.fulfilled, (state, { payload }) => {
      state.searchResults = {
        results: { ...payload },
        status: ApiStatus.SUCCESS
      };
      return state;
    });
    builder.addCase(performSearch.pending, (state) => {
      state.searchResults.status = ApiStatus.LOADING;
      return state;
    });
    builder.addCase(performSearch.rejected, (state, action) => {
      state.searchResults = {
        results: null,
        status: ApiStatus.FAILURE
      };
    });
  }
});

export const {
  resetSearch,
  resetSearchResults,
  setUserQuery,
  setActiveSearchFilters,
  updateActiveSearchFilters,
  setHidePSEducationalCard,
  setShowPSPopover
} = searchSlice.actions;
export const selectActiveSearchFilters = (state: RootState) => state.searchState.activeSearchFilters;
export const selectSearchResults = (state: RootState) => state.searchState.searchResults.results;
export const selectSearchResultsStatus = (state: RootState) => state.searchState.searchResults.status;
export const selectUserQuery = (state: RootState) => state.searchState.userQuery;
export const selectShowPSPopover = (state: RootState) => state.searchState.showPSPopover;
export const selectHidePSEducationalCard = (state: RootState) => state.searchState.hidePSEducationalCard;
export const selectSearchResultsJxCount = (state: RootState) =>
  (state.searchState.searchResults.results?.results?.jurisdictions as { count: number; jurisdiction: string }[]) || [];

export default searchSlice.reducer;
