import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Alert } from '@mui/material';

import { Loader } from '../components';
import { useAppDispatch } from '../utils/hooks/redux';
import { authenticateUser } from '../store/user';

import './Verify.scss';
import { ReactComponent as CasetextSvg } from '../assets/logo_dark.svg';

enum VERIFY_QUERY_PARAMS {
  EMAIL = 'email',
  NEXT_URL = 'nextUrl',
  USER_ID = 'userId',
  USER_STATUS = 'userStatus',
  VERIFICATION_CODE = 'verificationCode'
}

/** The verify component. */
const Verify = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [missingParams, setMissingParams] = useState(false);

  useEffect(() => {
    const email = searchParams.get(VERIFY_QUERY_PARAMS.EMAIL);
    const nextUrl = searchParams.get(VERIFY_QUERY_PARAMS.NEXT_URL) || '/';
    const userId = searchParams.get(VERIFY_QUERY_PARAMS.USER_ID);
    const verificationCode = searchParams.get(VERIFY_QUERY_PARAMS.VERIFICATION_CODE);
    const userStatus = searchParams.get(VERIFY_QUERY_PARAMS.USER_STATUS) || '';

    if (!!email && !!nextUrl && !!userId && verificationCode) {
      dispatch(
        authenticateUser({
          email,
          nextUrl,
          userId,
          verificationCode,
          userStatus
        })
      );
    } else {
      setMissingParams(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="saps-verify-main">
      <div className="ct-hompage-header">
        <CasetextSvg className="casetext-logo-svg" />
      </div>
      {!missingParams ? <Loader label="Verifying account..." /> : <Alert severity="error">Missing params</Alert>}
    </div>
  );
};

export default Verify;
