import { Close, KeyboardArrowDown } from '@mui/icons-material';
import { Button, Dialog, dialogClasses, IconButton, styled } from '@mui/material';
import { ModalState, SearchResultType } from '@utils';
import { useState } from 'react';
import { HubspotFilterType } from '@utils/crm-data';
import { addTeaserHit } from 'store/crmSlice';
import { TeaserType } from 'views/Teaser';

import ResultsSidebar from '../search/ResultsSidebar';
import { Text } from '../design_component_lib';
import { useAppDispatch } from '../../utils/hooks/redux';
import { setTeaserModalState } from '../../store/modalSlice';

import './MobileResultsFilters.scss';

interface MobileResultsFilterProps {
  currentContentType: SearchResultType;
  onChange: (newContentType: SearchResultType) => void;
}

/**
 * Function to get the display value for the button
 */
export const getButtonText = (currentContentType: SearchResultType): string => {
  let str = '';

  switch (currentContentType) {
    case SearchResultType.CASE:
      str = 'Cases';
      break;
    case SearchResultType.STATUTE:
      str = 'Statutes';
      break;
    case SearchResultType.REGULATION:
      str = 'Regulations';
      break;
    case SearchResultType.RULE:
      str = 'Rules';
      break;
    case SearchResultType.BRIEF:
      str = 'Briefs';
      break;
    case SearchResultType.GAVELYTICS:
      str = 'California Briefs & Forms';
      break;
    case SearchResultType.ADMIN_LAW:
      str = 'Admin. Materials';
      break;
    case SearchResultType.BLL:
      str = 'Black Letter Law';
      break;
    case SearchResultType.HOLDING:
      str = 'Holdings';
      break;
    case SearchResultType.ANALYSIS:
      str = 'Legal Analyses';
      break;
    case SearchResultType.GUIDE:
      str = 'Practice Guides';
      break;
    default:
      str = 'Cases';
      break;
  }

  return str;
};

const StyledDialog = styled(Dialog)({
  [`.${dialogClasses.paper}`]: {
    height: '90vh'
  }
});

/**
 * Function to display the results filters (database and filters) on mobile devices
 *
 * @param currentContentType currently selected database
 * @param onChange function to handle changing the database when user clicks on another one
 *
 * @returns mobile results filter component
 */
const MobileResultsFilter = ({ currentContentType, onChange }: MobileResultsFilterProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  /**
   * Handle filters click
   */
  const handleClick = () => {
    dispatch(setTeaserModalState({ modalState: ModalState.OPEN, teaserType: TeaserType.FILTER }));
    // Track teaser hit in hubspot CRM
    dispatch(addTeaserHit({ teaserType: TeaserType.FILTER, filterType: HubspotFilterType.ALL_MOBILE }));
  };

  return (
    <>
      <div className="ct-mobile-results-filters filters-btn-container">
        <Button endIcon={<KeyboardArrowDown />} onClick={() => setIsOpen(true)} variant="text">
          {getButtonText(currentContentType)}
        </Button>
        <div className="popover-filters-container-space" />
        <Button disableRipple endIcon={<KeyboardArrowDown />} onClick={handleClick} variant="text">
          Filters
        </Button>
      </div>
      <StyledDialog
        onClose={() => setIsOpen(false)}
        open={isOpen}
        fullWidth
        maxWidth="md"
        scroll="paper"
        transitionDuration={200}
      >
        <div className="filter-modal-header">
          <Text className="modal-header-text" size="xl" color="primary" weight="600">
            Content Types
          </Text>
          <IconButton className="close-btn" aria-label="close" onClick={() => setIsOpen(false)}>
            <Close />
          </IconButton>
        </div>
        <ResultsSidebar
          currentContentType={currentContentType}
          hideTooltips={true}
          onChange={(newContentType) => {
            setIsOpen(false);
            onChange(newContentType);
          }}
        />
      </StyledDialog>
    </>
  );
};

export default MobileResultsFilter;
