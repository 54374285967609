import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, IconButton, Button } from '@mui/material';
import { Heading } from 'components/design_component_lib';

import { Text } from '../../design_component_lib';

import './ErrorModal.scss';

export interface ErrorModalProps {
  isModalOpen: boolean;
  headerText: string;
  modalText: string | JSX.Element;
  needSupport: boolean;
  setModalState: () => void;
}

/**
 * Modal for displaying error messages with contact support link
 * @param ErrorModalProps
 * @returns
 */
const ErrorModal = ({ isModalOpen, setModalState, headerText, modalText, needSupport }: ErrorModalProps) => {
  /**
   * Function to return the corrct copy to the modal body depending on if the
   * support email is needed or not.
   */
  const getModalText = () => {
    if (needSupport) {
      return (
        <>
          {`${modalText} Please try again in a few minutes. If the problem persists, `}
          <a
            href="mailto:support@casetext.com?subject=Support question"
            target="_new"
            className="contact-support"
            aria-label="contact support"
          >
            <span>contact support.</span>
          </a>
        </>
      );
    } else {
      return modalText;
    }
  };

  return (
    <Dialog className="ct-error-modal" open={isModalOpen} onClose={setModalState}>
      <DialogContent className="ct-error">
        <IconButton
          onClick={setModalState}
          edge="end"
          data-testid="error-close"
          aria-label="close"
          className="close-btn"
        >
          <Close htmlColor="black" />
        </IconButton>
        <Heading>
          <Text data-testid="error-header" size="2xl">
            {headerText}
          </Text>
        </Heading>
        <Text data-testid="error-body" className="ct-error-text">
          {getModalText()}
        </Text>
        <Button data-testid="error-submit" onClick={setModalState} variant="contained">
          Got it
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorModal;
