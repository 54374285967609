import { ChevronRight, Search } from '@mui/icons-material';
import { ListItem, ListItemIcon } from '@mui/material';
import { SearchSuggestionType } from '@utils/search';
import ellipsize from 'ellipsize';
import sanitizeHtml from 'sanitize-html';
import classnames from 'classnames';

import { Text } from '../../design_component_lib';

export interface SearchSuggestionProps {
  type: SearchSuggestionType | 'search';
  title: string;
  isSelected: boolean;
  currentIndex?: number;
  onClickAction: () => void;
  testId?: string;
}

const MAX_TEXT_LENGTH = 120;

const SearchSuggestion = ({
  type,
  title,
  onClickAction,
  testId,
  isSelected,
  currentIndex
}: SearchSuggestionProps): JSX.Element => {
  // it is freaking impossible to get this ellipsis on this text using css cause of the flex box configuration with MUI.
  // so taking a brute force approach here.
  const ellipsizedText = ellipsize(title, MAX_TEXT_LENGTH);

  /**
   * Function to return the suggestion icon based on type.
   */
  const getIcon = () => {
    let icon;

    switch (type) {
      case 'search':
      default:
        icon = <Search fontSize="small" />;
    }

    return icon;
  };

  /**
   * Function to sanitize text and use as dangerouslySetInnerHTML
   * @param text
   */
  const sanitizeText = (text: string) => {
    return sanitizeHtml(text, {
      allowedTags: ['b', 'i', 'em', 'strong']
    });
  };

  return (
    <ListItem
      button
      classes={{
        root: `ct-suggestion-list-item ${type === 'search' ? 'ct-search' : ''}`,
        selected: 'ct-suggestion-selected'
      }}
      disableRipple={true}
      onClick={onClickAction}
      data-testid={testId}
      selected={isSelected}
      data-currentidx={`suggestion-${currentIndex}`}
    >
      <ListItemIcon classes={{ root: 'ct-suggestion-list-icon' }}>{getIcon()}</ListItemIcon>
      <div className={classnames('ct-suggestion-list-item-text-container', { 'ct-search': type === 'search' })}>
        <Text
          className={classnames('ct-suggestion-list-item-text', { 'ct-search': type === 'search' })}
          size="sm"
          color="primary"
        >
          <span dangerouslySetInnerHTML={{ __html: sanitizeText(ellipsizedText) }} />
        </Text>
      </div>
      <ListItemIcon classes={{ root: 'ct-suggestion-list-icon chevron' }}>
        <ChevronRight fontSize="small" />
      </ListItemIcon>
    </ListItem>
  );
};

export default SearchSuggestion;
