export const HTTP_END_POINTS = {
  CITE_INFO: (slug: string) => `/__search/cite/${slug}`,
  CREATE_USER: '/__user/create_new',
  IDENTIFY_ANALYTICS: '/__i',
  PAGE_ANALYTICS: '/__p',
  MOVANT_BACKEND_PROXY: '/__movant-backend',
  TRACK_ANALYTICS: '/__t',
  USER_AUTH: '/__auth/magic_link',
  USER_ME: '/__user/me',
  SEARCH: '/__search/unified',
  UPDATE_CRM_TEASER: '/__user/me/crm_teaser',
  VERIFY_USER: '/__user/verify/'
};

export const CANCELLED_REQUEST = 'force canceled by app';

export enum ApiStatus {
  NONE = '',
  SUCCESS = 'success',
  FAILURE = 'failure',
  LOADING = 'loading'
}
