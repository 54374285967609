import { styled } from '@mui/system';
import { Link } from '@mui/material';

import colors from '../../../utils/themes/colors';

const headingColorList: Record<string, string> = {
  inherit: 'inherit',
  primary: colors.gray[900],
  secondary: colors.gray[700],
  visited: colors.purple[900],
  'primary-blue': colors.brandblue[500]
};
export type HeadingColor = keyof typeof headingColorList;
const headingSizeList: Record<string, string> = { lg: '1.125rem', xl: '1.25rem', '2xl': '1.5rem', '3xl': '1.875rem' };
export type HeadingSize = keyof typeof headingSizeList;
const headingLineHeightList: Record<string, number> = { lg: 1.44, xl: 1.4, '2xl': 1.33, '3xl': 1.33 };
export type HeadingLineHeight = keyof typeof headingLineHeightList;
const headingWeightList: Record<string, string> = { regular: 'normal', medium: '500', bold: '700' };
export type HeadingWeight = keyof typeof headingWeightList;
export interface HeadingProps {
  color?: HeadingColor;
  size?: HeadingSize;
  weight?: HeadingWeight;
}

/**
 * General heading element with options for color, size, and weight
 */
export const Heading = styled('span', { shouldForwardProp: (prop) => prop === 'children' })<HeadingProps>(
  ({ color = 'primary', size = 'lg', weight = 'regular' }) => ({
    color: color === 'inherit' ? color : headingColorList[color],
    fontSize: headingSizeList[size],
    fontWeight: headingWeightList[weight],
    lineHeight: headingLineHeightList[size],
    margin: 0,
    display: 'flex',
    alignItems: 'center'
  })
);

/**
 * Link heading element with options for color, size, and weight
 */
export const HeadingLink = styled(Link, {
  name: 'HeadingLink',
  slot: 'Wrapper'
})((props: any) => ({
  fontSize: headingSizeList[props.size || 'lg'],
  fontWeight: headingWeightList[props.weight || 'regular'],
  lineHeight: headingLineHeightList[props.size || 'lg'],
  color: props.color === 'inherit' ? props.color : headingColorList[props.color],
  textAlign: 'left',
  margin: 0,
  display: 'flex',
  alignItems: 'center'
})) as any;

const upHeadingColorList: Record<string, string> = {
  inherit: 'inherit',
  primary: colors.gray[900],
  secondary: colors.gray[700]
};
export type UpHeadingColor = keyof typeof upHeadingColorList;
const upHeadingSizeList: Record<string, string> = { xs: '0.75rem', sm: '0.875rem', base: '1rem' };
export type UpHeadingSize = keyof typeof upHeadingSizeList;
const upHeadingLineHeightList: Record<string, number> = { xs: 1.33, sm: 1.43, base: 1.5 };
export type UpHeadingLineHeight = keyof typeof upHeadingLineHeightList;
export interface UpHeadingProps {
  color?: UpHeadingColor;
  size?: UpHeadingSize;
}

/**
 * General uppercase heading element with options for color and size
 */
export const UppercaseHeading = styled('span', { shouldForwardProp: (prop) => prop === 'children' })<UpHeadingProps>(
  ({ color = 'primary', size = 'base' }) => ({
    color: color === 'inherit' ? color : upHeadingColorList[color],
    fontSize: upHeadingSizeList[size],
    fontWeight: 'bold',
    lineHeight: upHeadingLineHeightList[size],
    textTransform: 'uppercase',
    margin: 0
  })
);
