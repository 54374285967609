import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTeaserHitString, TeaserCrmData } from '@utils/crm-data';

import { HTTP_END_POINTS } from '../@utils/api';
import { RootState } from './store';

// User async actions
/**
 * Add information about the teaser a user landed on to Hubspot
 */
export const addTeaserHit = createAsyncThunk<void, TeaserCrmData, { state: RootState }>(
  'crm/addTeaserHit',
  async (params, thunkAPI) => {
    const data = { ...params };
    const teaserHitString = getTeaserHitString(data);
    try {
      await axios.put(HTTP_END_POINTS.UPDATE_CRM_TEASER, {
        teaser: teaserHitString
      });
    } catch (err: any) {
      throw thunkAPI.rejectWithValue(`Failed to add teaser hit to Hubspot CRM. ${err && err.message}`);
    }
  }
);
