import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { useAppDispatch } from 'utils/hooks/redux';
import { selectToastMessage, selectToastStatus, setToast } from 'store/toastSlice';
import { useSelector } from 'react-redux';
import { ToastState } from '@utils';

/**
 * Component to display toasts/snackbars
 * @returns Alert nested in Snackbar
 */
const StatusToast = () => {
  const dispatch = useAppDispatch();
  const toastStatus = useSelector(selectToastStatus);
  const toastMessage = useSelector(selectToastMessage);

  const handleClose = () => {
    dispatch(setToast({ toastStatus: ToastState.CLOSED, toastMessage }));
  };

  return (
    <Snackbar open={toastStatus !== ToastState.CLOSED} onClose={handleClose} autoHideDuration={3000}>
      <Alert
        onClose={handleClose}
        severity={toastStatus === ToastState.ERROR ? ToastState.ERROR : ToastState.SUCCESS}
        sx={{ width: '100%' }}
      >
        {toastMessage}
      </Alert>
    </Snackbar>
  );
};

export default StatusToast;
