import { useEffect, useRef, useState } from 'react';

/**
 * https://usehooks.com/useKeyPress/
 * Utility function to check for key input.
 * @param targetKey
 */
export function useKeyPress(targetKey: string) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState<boolean>(false);
  // If pressed key is our target key then set to true
  function downHandler({ key }: any) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }
  // If released key is our target key then set to false
  const upHandler = ({ key }: any) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };
  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return keyPressed;
}

/**
 * Utility function to keep track of the previous value of a value in a functional cmp.
 * @param value
 */
export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * https://usehooks.com/useOnClickOutside/
 * Utility function to check for click outside ref.
 * @param ref - reference to element to check for click outside
 * @param handler
 */
export function useOnClickOutside(ref: any, ref2: any, preventAction: boolean, handler: any) {
  useEffect(() => {
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!!ref2.current) {
        if (!ref.current || ref.current.contains(event.target) || ref2.current.contains(event.target)) {
          return;
        }
      } else {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
      }

      if (preventAction) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
      ref.current.blur();
      handler(event);
    };
    document.addEventListener('click', listener, true);
    document.addEventListener('touch', listener, true);
    return () => {
      document.removeEventListener('click', listener, true);
      document.removeEventListener('touch', listener, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, handler]);
}
