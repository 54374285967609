import React, { useEffect, useState } from 'react';
import { ArrowForward, Search } from '@mui/icons-material';
import { Button, List, styled, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IN_APP_ROUTES, SearchResultType, SearchInputStates, sortedSearchStr, ModalState } from '@utils';
import classnames from 'classnames';
import { ClientAnalyticsEvent } from '@utils/analytics-events';

import { ErrorModal, JxButton } from '../../';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks/redux';
import { selectSelectedJXs } from '../../../store/jurisdictionSlice';
import {
  checkEngine,
  selectActiveSearchFilters,
  setUserQuery,
  updateActiveSearchFilters
} from '../../../store/searchSlice';
import {
  selectSearchEdModalState,
  selectSearchInputOpenState,
  setSearchEdModalState,
  setSearchInputOpenState
} from '../../../store/modalSlice';
import SearchSuggestion, { SearchSuggestionProps } from './SearchSuggestion';
import AnalyticsService from '../../../services/analytics/AnalyticsService';

import './SearchInputWithSuggestions.scss';

interface SearchInputWithSuggestionsProps {
  currentSearchText: string;
  isSearchResultsPage?: boolean;
  isHomepage?: boolean;
  isMobile?: boolean;
  isMedium?: boolean;
  jxButtonClick: () => void;
  handleTextChange: (searchText: string) => void;
  closeModal: () => void;
  searchAutoFocus: boolean;
}

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.common.white,
  '&:hover': {
    background: theme.palette.brightblue['100']
  }
}));

/**
 * The search with suggestions component. This contains the search input text area, along
 * with the suggestions dropdown to navigate to a results page.
 */
const SearchInputWithSuggestions = ({
  isMobile,
  isSearchResultsPage,
  isHomepage,
  currentSearchText,
  handleTextChange,
  jxButtonClick,
  closeModal,
  searchAutoFocus
}: SearchInputWithSuggestionsProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedJXs = useAppSelector(selectSelectedJXs);
  const activeFilters = useAppSelector(selectActiveSearchFilters);
  const [userInputText, setUserInputText] = useState<string>('');
  const [errorProps, setErrorProps] = useState({
    isModalOpen: false,
    headerText: '',
    modalText: '',
    needSupport: false
  });
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const searchInputOpenState = useAppSelector(selectSearchInputOpenState);
  const isSearchInputOpen = searchInputOpenState !== SearchInputStates.NONE;

  const MULTILINE_MIN_ROWS = 1;
  const MULTILINE_MAX_ROWS = !isSearchInputOpen ? 1 : 6;

  const userHasSeenSearchEdModal = useAppSelector(selectSearchEdModalState).userSeen;

  useEffect(() => {
    if (!!currentSearchText) {
      setUserInputText(currentSearchText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Function to handle text input change
   *
   * @param event - the change event
   */
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value.trim();
    setUserInputText(newText);
    handleTextChange(newText);
  };

  /**
   * Function to handle opening the search input
   */
  const openSearchInput = () => {
    if (searchInputOpenState === SearchInputStates.NONE) {
      setUserInputText(currentSearchText);
      handleTextChange(currentSearchText);
      dispatch(setSearchInputOpenState(SearchInputStates.DEFAULT));
    }
  };

  /**
   * Function to handle key presses.
   * We only want to capture enter, arrow up, and arrow down for navigating the suggestions
   *
   * @param event - the key press event
   */
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const suggestedItemLength = !!userInputText ? 1 : 0;
    const lastIndex = suggestedItemLength - 1;

    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      // unfocus all elements
      const tmp = document.createElement('input');
      document.body.appendChild(tmp);
      tmp.focus();
      document.body.removeChild(tmp);

      navigateToResults(userInputText);
    } else if (event.key === 'ArrowUp') {
      const prev = selectedIndex === 0 ? lastIndex : selectedIndex - 1;

      event.preventDefault();
      event.stopPropagation();

      setSelectedIndex(prev);
    } else if (event.key === 'ArrowDown') {
      const next = selectedIndex === lastIndex ? 0 : selectedIndex + 1;

      event.preventDefault();
      event.stopPropagation();

      setSelectedIndex(next);
    } else {
      if (searchInputOpenState === SearchInputStates.NONE) {
        dispatch(setSearchInputOpenState(SearchInputStates.DEFAULT));
      }
    }
  };

  /**
   * Function to navigate to the results page with user inputted query.
   *
   * @param query - The users query.
   * @param type - the type of results
   */
  const navigateToResults = async (query: string, type?: SearchResultType) => {
    if (!query) {
      return;
    }
    const typeParam = type || SearchResultType.CASE;
    const queryParams: { [key: string]: any } = {
      ...activeFilters,
      tab: null, // will clear any forced searches
      q: query,
      type: typeParam,
      jxs: (!!selectedJXs && selectedJXs.join(',')) || undefined,
      p: 1 // always restart with page 1
    };
    const searchStr = sortedSearchStr(queryParams);
    dispatch(setUserQuery(query));

    AnalyticsService.Instance.track(ClientAnalyticsEvent.SUBMITTED_QUERY, {
      searchQuery: query,
      jxs: !!selectedJXs && selectedJXs.length ? selectedJXs.join(',') : ''
    });

    if (isHomepage && !userHasSeenSearchEdModal) {
      const asyncResp = await dispatch(checkEngine());
      const engine = asyncResp.payload;
      if (engine === 'search-api') {
        dispatch(setSearchEdModalState({ modalState: ModalState.OPEN, userSeen: true, searchStr }));
        closeModal();
        return;
      }
    }

    dispatch(
      updateActiveSearchFilters({
        type: typeParam,
        tab: null,
        page: 1
      })
    );
    closeModal();
    navigate({
      pathname: IN_APP_ROUTES.SEARCH_RESULTS,
      search: searchStr
    });
  };

  /**
   * Function to create the sorted list items for the suggestions list.
   */
  const createSuggestionsListItems = () => {
    const searchListItem: (SearchSuggestionProps & { key: string }) | null = !!userInputText
      ? {
          key: 'user-text-item',
          testId: 'search-listitem',
          type: 'search',
          title: userInputText,
          isSelected: false,
          onClickAction: () => navigateToResults(userInputText)
        }
      : null;

    const sortedList = [searchListItem];

    // reset the selected index if we have less results
    if (selectedIndex > sortedList.length - 1) {
      setSelectedIndex(0);
    }

    // We need to map these props to the actual component but all pick the correct selected item.
    return sortedList.map((listItemProps: SearchSuggestionProps | null, index: number) => {
      if (!!listItemProps) {
        if (index === selectedIndex) {
          listItemProps.isSelected = true;
        }

        listItemProps.currentIndex = index;
      }

      return !!listItemProps ? <SearchSuggestion {...listItemProps} /> : null;
    });
  };

  const searchSuggestionsContainer = (
    <div
      className={classnames('ct-search-suggestions-container', {
        'is-mobile': isMobile,
        'is-serp': isSearchResultsPage
      })}
    >
      <List className="ct-search-suggestions-list" data-testid="ct-search-suggestions-list">
        {createSuggestionsListItems()}
      </List>
    </div>
  );

  /**
   * Function to set error state inside ErrorModal
   */
  const setIsErrorState = () => {
    setErrorProps({ ...errorProps, isModalOpen: !errorProps.isModalOpen });
  };

  const textAreaContainer = (
    <div
      className={classnames('ct-text-area-container', {
        'is-mobile': isMobile,
        'search-open': isSearchInputOpen,
        'is-serp': isSearchResultsPage
      })}
    >
      <TextField
        id="search-input-multiline"
        name="search-input"
        classes={{
          root: classnames('ct-search-text-area', { 'is-large': isHomepage })
        }}
        label=""
        placeholder="E.g., “Planting a garden is not sufficient to satisfy the requirements of squatter’s rights.”"
        multiline
        minRows={MULTILINE_MIN_ROWS}
        maxRows={MULTILINE_MAX_ROWS}
        defaultValue={currentSearchText} // not a controlled component, need to use value if we want controlled
        onChange={handleInputChange}
        fullWidth={true}
        variant="standard"
        data-testid="search-input-multiline"
        autoFocus={isMobile ? false : searchAutoFocus}
        onFocus={openSearchInput}
        onKeyDown={handleKeyPress}
      />
    </div>
  );

  const jxButtonContainer = (
    <div
      className={classnames('ct-jx-button-container', {
        'search-open': isSearchInputOpen,
        'is-serp': isSearchResultsPage,
        'is-mobile': isMobile
      })}
    >
      <JxButton onClick={jxButtonClick} selectedJXs={selectedJXs} />
    </div>
  );

  return (
    <>
      <div
        className={classnames('ct-white-rounded-corners', {
          'is-mobile': isMobile,
          'is-serp': isSearchResultsPage,
          'search-open': isSearchInputOpen
        })}
      >
        <div
          className={classnames('ct-search-input-w-suggestions', {
            'is-mobile': isMobile,
            'is-serp': isSearchResultsPage,
            'search-open': isSearchInputOpen
          })}
        >
          {isMobile ? (
            <>
              <div className="ct-search-bar-mobile">
                <div
                  className={classnames('ct-search-input-container-mobile', {
                    'search-open': isSearchInputOpen,
                    'is-serp': isSearchResultsPage
                  })}
                >
                  {!isHomepage && (
                    <div
                      className={classnames('ct-icon-container-mobile', {
                        'search-open': isSearchInputOpen,
                        'is-serp': isSearchResultsPage
                      })}
                    >
                      <Search fontSize="small" />
                    </div>
                  )}
                  <div className="ct-search-text-container-mobile">{textAreaContainer}</div>
                </div>
              </div>
              {isSearchInputOpen && !isHomepage && jxButtonContainer}
              {userInputText?.length > 0 && isSearchInputOpen && !isHomepage && searchSuggestionsContainer}
            </>
          ) : (
            <>
              {!isHomepage && (
                <div className={classnames('ct-icon-container', { 'search-open': isSearchInputOpen })}>
                  <Search fontSize="small" />
                </div>
              )}
              {textAreaContainer}
              {!isHomepage && jxButtonContainer}
            </>
          )}
        </div>
        {userInputText?.length > 0 && isSearchInputOpen && !isHomepage && searchSuggestionsContainer}
        <ErrorModal {...errorProps} setModalState={setIsErrorState} />
      </div>
      {isHomepage && (
        <div className="search-button-container">
          <StyledButton className="search-button" onClick={() => navigateToResults(userInputText)}>
            Find On-Point Cases
            <ArrowForward fontSize="small" />
          </StyledButton>
        </div>
      )}
    </>
  );
};

export default SearchInputWithSuggestions;
