import { createTheme, typographyClasses } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { Shadows } from '@mui/material/styles/shadows';

import { default as colors } from './colors';

const { brandblue, brightblue, gray, green, red, yellow } = colors;

declare module '@mui/material/styles' {
  interface Palette extends Record<keyof typeof colors, ColorPartial> {}
}

// Typescript requires that we use module augmentation
declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    'extra-large': true;
  }
}

export const mainTheme = createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        action: {
          alignItems: 'center',
          paddingTop: 0
        },
        standard: ({ ownerState, theme }) => ({
          border: `1px solid ${theme.palette[ownerState?.severity || 'success'].main}`
        })
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: brandblue[600],
          borderBottom: gray[300],
          color: gray[900],
          boxShadow: 'none'
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { size: 'extra-large' },
          style: { height: '3.438rem', fontSize: '1.125rem' }
        }
      ],
      defaultProps: {
        disableElevation: true,
        variant: 'outlined'
      },
      styleOverrides: {
        root: {
          textTransform: 'inherit'
        },
        outlinedSecondary: {
          borderColor: gray[300],

          ':hover': {
            borderColor: gray[500]
          }
        },
        textPrimary: {
          ':hover': {
            color: brandblue[600]
          }
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '0.5rem'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: gray[900]
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          borderColor: gray[300],
          padding: '0.625rem'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0 1.5rem 1.5rem'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: gray[300]
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: brandblue[800]
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: brandblue[800]
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          [`& .${typographyClasses.root}`]: {
            fontWeight: 'bold',
            fontSize: '14px'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-adornedEnd': {
            paddingRight: '0.5rem'
          },
          '&.MuiInputBase-adornedEnd > input': {
            paddingRight: '0.25rem'
          },
          '&.MuiInputBase-adornedStart': {
            paddingLeft: '0.5rem'
          },
          '&.MuiInputBase-adornedStart > input': {
            paddingLeft: '0.25rem'
          }
        },
        inputSizeSmall: {
          fontSize: '0.75rem'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: gray[300]
          },
          '&:not(.Mui-focused):hover .MuiOutlinedInput-notchedOutline': {
            borderColor: gray[500]
          }
        },
        input: {
          padding: '0.5rem 0.75rem'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.125rem',
          fontWeight: 500
        },
        outlined: {
          fontSize: '1em'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          transition: ['color 0.2s', 'text-decoration-color 0.2s'].join()
        },
        underlineHover: {
          textDecorationColor: 'transparent',
          textDecorationLine: 'underline'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        gutters: {
          margin: '6px 0'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'initial'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        inset: {
          paddingLeft: '1.5rem'
        }
      }
    },
    MuiPopover: {
      defaultProps: {
        elevation: 2
      }
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: brightblue[50],
          borderRadius: '.25rem',
          border: `1px solid ${brightblue[500]}`,
          color: brightblue[900]
        },
        message: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: brandblue[800],
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          fontSize: '0.75rem',
          lineHeight: '1.125rem',
          maxWidth: '12rem'
        },
        arrow: {
          color: brandblue[800]
        },
        popper: {
          '& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementRight': {
            marginLeft: '1.375rem'
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: ['Inter', 'sans-serif'].join()
        }
      }
    }
  },
  palette: {
    error: {
      main: red[500]
    },
    info: {
      main: brightblue[500]
    },
    primary: {
      main: brandblue[500]
    },
    secondary: {
      main: gray[700]
    },
    success: {
      main: green[500]
    },
    warning: {
      main: yellow[500]
    },
    ...colors
  },
  shadows: [
    'none',
    '0 1px 1px rgba(33, 33, 33, 0.08)',
    '0 0.125rem 0.5rem rgba(33, 33, 33, 0.16)',
    '0 0.25rem 1rem rgba(33, 33, 33, 0.24)',
    ...Array(21).fill('none')
  ] as Shadows,
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join()
  }
});

// We can't use `export * as colors from './colors';` because it
// causes the linter to crash with an unhelpful error
export { colors };
