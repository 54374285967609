import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchInputStates, ModalState } from '@utils/modal';

import { RootState } from './store';
import AnalyticsService from '../services/analytics/AnalyticsService';
import { ClientAnalyticsEvent } from '../@utils/analytics-events';

const initialState: ModalStore = {
  searchEdModalState: { modalState: ModalState.CLOSED, userSeen: false },
  searchInputOpenState: SearchInputStates.NONE,
  shareModalState: ModalState.CLOSED,
  signInModalState: ModalState.CLOSED,
  teaserModalState: { modalState: ModalState.CLOSED, teaserType: null }
};

/**
 * Modal state
 */
export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setSearchEdModalState: (state, { payload }: PayloadAction<SearchEdModal>) => {
      state.searchEdModalState.modalState = payload.modalState;
      state.searchEdModalState.userSeen = payload.userSeen;
      state.searchEdModalState.searchStr = payload.searchStr;

      if (payload.modalState === ModalState.CLOSED) {
        AnalyticsService.Instance.track(ClientAnalyticsEvent.CLOSED_PS_MODAL);
      }

      return state;
    },
    setSearchInputOpenState: (state, { payload }) => {
      state.searchInputOpenState = payload;
      return state;
    },
    setShareModalState: (state, { payload }) => {
      state.shareModalState = payload;
    },
    setSignInModalState: (state, { payload }) => {
      state.signInModalState = payload;
      return state;
    },
    setTeaserModalState: (state, { payload }: PayloadAction<TeaserModal>) => {
      state.teaserModalState.modalState = payload.modalState;
      state.teaserModalState.teaserType = payload.teaserType;
      return state;
    }
  }
});

export const {
  setSearchEdModalState,
  setSearchInputOpenState,
  setShareModalState,
  setSignInModalState,
  setTeaserModalState
} = modalSlice.actions;
export const selectSearchEdModalState = (state: RootState) => state.modalState.searchEdModalState;
export const selectSearchInputOpenState = (state: RootState) => state.modalState.searchInputOpenState;
export const selectShareModalState = (state: RootState) => state.modalState.shareModalState;
export const selectSignInModalState = (state: RootState) => state.modalState.signInModalState;
export const selectTeaserModalState = (state: RootState) => state.modalState.teaserModalState;

export default modalSlice.reducer;
