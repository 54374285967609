interface BaseTreatment {
  citation?: string;
  date?: Date;
  jurisdiction?: string;
  slug?: string;
  title?: string;
}

interface MentioningTreatment extends BaseTreatment {
  mentioning: MentioningTreatment;
}

interface CitatorTreatment extends BaseTreatment {
  treatmentCode: string;
  /**
   * The category, negative/positive/warning/etc.
   * This field should always be present on documents, but is missing from certain search result data where this type is
   * used.  See note at
   * https://github.com/casetext/maat/blob/227bae6/src/app/document/treatments/treatments-collection.ts#L85-L87.
   */
  treatmentCategory?: TreatmentCategory;
  passageId?: string;
  mentioning?: MentioningTreatment;
  mentioningTreatments?: MentioningTreatment[];
}
type TreatmentCategory =
  | 'caution'
  | 'negative'
  | 'positive'
  | 'codified'
  | 'warning'
  | 'distinguish'
  | 'question'
  | 'comparison'
  | 'modified';

type TreatmentColor = 'negative' | 'warning-distinguish-question-comparison' | 'modified-caution' | 'codified-positive';

class TreatmentCode {
  buttonCopy: string;
  description: string;
  constructor(buttonCopy: string, description: string) {
    this.buttonCopy = buttonCopy;
    this.description = description;
  }
}

export class TreatmentCollection {
  /*
    Contains an array of treatments and properties calculated from the collection.
    Re-usable across different components that need this logic.
  */
  static readonly TREATMENT_CODES: { [key: string]: TreatmentCode } = {
    aff: new TreatmentCode('Positive History', 'Affirmed'),
    cod: new TreatmentCode('Codified', 'Codified'),
    bc: new TreatmentCode('Warning', 'But Cf.'),
    bs: new TreatmentCode('Warning', 'But See'),
    cmp: new TreatmentCode('Compared', 'Compared'),
    co: new TreatmentCode('Warning', 'Contra'),
    d: new TreatmentCode('Distinguished', 'Distinguished'),
    dpb: new TreatmentCode('Depublished', 'Depublished'),
    i_md: new TreatmentCode('Caution', 'Negative treatment on appeal'),
    i_ovr: new TreatmentCode('Caution', 'Overruled'),
    i_povr: new TreatmentCode('Caution', 'Overruled in part'),
    i_rev: new TreatmentCode('Caution', 'Reversed'),
    i_rg: new TreatmentCode('Caution', 'Review Granted'),
    i_sani: new TreatmentCode('Caution', 'Superseded by statute as noted'),
    i_sup: new TreatmentCode('Caution', 'Superseded'),
    i_vc: new TreatmentCode('Caution', 'Vacated'),
    i_wd: new TreatmentCode('Caution', 'Negative treatment on appeal'),
    md: new TreatmentCode('Modified', 'Modified'),
    nsh: new TreatmentCode('Negative History', 'Negative Subsequent History'),
    ovr: new TreatmentCode('Overruled', 'Overruled'),
    povr: new TreatmentCode('Overruled in part', 'Overruled in part'),
    q: new TreatmentCode('Questioned', 'Questioned'),
    rev: new TreatmentCode('Reversed', 'Reversed'),
    rg: new TreatmentCode('Review Granted', 'Review Granted'),
    sani: new TreatmentCode('Superseded', 'Superseded by statute as noted'),
    sup: new TreatmentCode('Superseded', 'Superseded'),
    vc: new TreatmentCode('Vacated', 'Vacated'),
    wd: new TreatmentCode('Negative Treatment', 'Negative treatment on appeal'),
    rd: new TreatmentCode('Review Denied', 'Petition for Review Denied'),
    rm: new TreatmentCode('Remanded', 'Remanded'),
    dm: new TreatmentCode('Dismissed', 'Dismissed'),
    pd: new TreatmentCode('Publication Denied', 'Publication Request Denied'),
    dd: new TreatmentCode('Depublication Denied', 'Depublication Request Denied'),
    positive: new TreatmentCode('Positive Treatment', 'Positive Treatment'),
    negative: new TreatmentCode('Negative Treatment', 'Negative Treatment'),
    distinguish: new TreatmentCode('Distinguished', 'Distinguished')
  };

  treatments: CitatorTreatment[];
  buttonCopy: string;
  flagColor: TreatmentColor | null;
  smallSquareClass: string;

  constructor(citatorTreatments: CitatorTreatment[]) {
    this.treatments = citatorTreatments || [];
    this.buttonCopy = '';
    this.flagColor = null;
    this.smallSquareClass = 'neutral-flag';
    this.setFlagColorAndButtonCopy();
    this.setClasses();
  }

  static fromCitator(citator: any[]) {
    return new TreatmentCollection(citator);
  }

  hasAtLeastOneTreatment(category: TreatmentCategory) {
    if (category === 'negative') {
      for (const treatment of this.treatments) {
        if (treatment.treatmentCategory === 'negative' || !('treatmentCategory' in treatment)) {
          return true;
        }
      }
      return false;
    } else {
      return this.treatments.findIndex((treatment) => treatment.treatmentCategory === category) > -1;
    }
  }

  /*
  When there is more than one treatment,
  the presence of any negative treatment will make the buttonCopy 'Negative Treatment'.
  */
  private setFlagColorAndButtonCopy() {
    if (this.treatments.length === 1) {
      this.buttonCopy = this.getTreatmentCopy(this.treatments[0]).buttonCopy;
    }
    if (this.hasAtLeastOneTreatment('negative')) {
      this.buttonCopy = this.buttonCopy || 'Negative Treatment';
      this.flagColor = 'negative';
    } else if (this.hasAtLeastOneTreatment('modified')) {
      this.buttonCopy = this.buttonCopy || 'Modified';
      this.flagColor = 'modified-caution';
    } else if (this.hasAtLeastOneTreatment('caution')) {
      this.buttonCopy = this.buttonCopy || 'Caution';
      this.flagColor = 'modified-caution';
    } else if (this.hasAtLeastOneTreatment('warning')) {
      this.buttonCopy = this.buttonCopy || 'Warning';
      this.flagColor = 'warning-distinguish-question-comparison';
    } else if (this.hasAtLeastOneTreatment('distinguish')) {
      this.buttonCopy = this.buttonCopy || 'Distinguished';
      this.flagColor = 'warning-distinguish-question-comparison';
    } else if (this.hasAtLeastOneTreatment('question')) {
      this.buttonCopy = this.buttonCopy || 'Questioned';
      this.flagColor = 'warning-distinguish-question-comparison';
    } else if (this.hasAtLeastOneTreatment('comparison')) {
      this.buttonCopy = this.buttonCopy || 'Compared';
      this.flagColor = 'warning-distinguish-question-comparison';
    } else if (this.hasAtLeastOneTreatment('positive')) {
      this.buttonCopy = this.buttonCopy || 'Positive Treatment';
      this.flagColor = 'codified-positive';
    } else if (this.hasAtLeastOneTreatment('codified')) {
      this.buttonCopy = this.buttonCopy || 'Codified';
      this.flagColor = 'codified-positive';
    }
  }
  /*
  To add a new treatment code, instantiate a TreatmentCode class and place it here.
  buttonCopy may be overriden when there is more than one treatment.
  */
  getTreatmentCopy(treatment: CitatorTreatment): TreatmentCode {
    const { treatmentCode, treatmentCategory } = treatment;
    if (treatmentCode in TreatmentCollection.TREATMENT_CODES) {
      return TreatmentCollection.TREATMENT_CODES[treatmentCode];
    } else if (treatmentCategory! in TreatmentCollection.TREATMENT_CODES) {
      return TreatmentCollection.TREATMENT_CODES[treatmentCategory!];
    } else {
      return new TreatmentCode('Negative Treatment', 'Negative Treatment');
    }
  }

  private setClasses() {
    const colors = ['negative', 'warning-distinguish-question-comparison', 'modified-caution', 'codified-positive'];
    if (this.flagColor && colors.includes(this.flagColor)) {
      this.smallSquareClass = this.flagColor;
    }
  }
}
