import React from 'react';

import { Text } from '../design_component_lib';

import './NoResults.scss';

/**
 * Component used for displaying no results messaging
 *
 */
const NoResults = (): JSX.Element => {
  const titleCopy = 'Parallel Search';

  return (
    <div className="ct-no-results">
      <Text size="lg" color="primary" weight="500" className="ct-no-result-title">
        0 results found with {titleCopy}
      </Text>
    </div>
  );
};

export default NoResults;
