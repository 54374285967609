import { ToastState } from '@utils';
import { AppDispatch } from 'store/store';
import { setToast } from 'store/toastSlice';
import { fetchVerificationStatus } from 'store/user';

/** Function to resend email to user and show snackbar  */
export const resendEmail = (dispatch: AppDispatch, email: string) => {
  dispatch(fetchVerificationStatus(email)).then(() =>
    dispatch(setToast({ toastStatus: ToastState.SUCCESS, toastMessage: 'Link Resent' }))
  );
};

/** The current allowed values of the Position field. */
export enum UserPosition {
  /** Practicing attorney */
  ATTORNEY = 'Attorney',
  /** Librarian or Knowledge Management professional */
  KM_PROFESSIONAL = 'Law Librarian or KM Professional',
  /** Legal professional */
  LEGAL_PROFESSIONAL = 'Legal professional',
  /** Law student */
  LAW_STUDENT = 'Law school student or faculty',
  /** Law school faculty */
  FACULTY = 'Faculty',
  /** Law school Librarian */
  LAW_SCHOOL_LIBRARIAN = 'Law school librarian',
  /** Law school Professor */
  LAW_SCHOOL_PROFESSOR = 'Law school professor',
  /** Law school Staff */
  LAW_SCHOOL_STAFF = 'Law school staff',
  /** Law school Other */
  LAW_SCHOOL_OTHER = 'Law school other',
  /** Representing myself in a legal matter */
  PRO_SE_LITIGANT = 'Pro se litigant',
  /** Other */
  OTHER = 'Other'
}

export enum UserVerificationStatus {
  ENTERPRISE_USER_CREATED = 'enterprise-user-created',
  UNKNOWN_USER = 'unknown-user',
  KNOWN_USER = 'known-user'
}
