import React from 'react';
import { ButtonProps, Link, LinkProps } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { styled } from '@mui/system';

import { Text, TextSize } from '../text/Text';

interface LinkButtonProps extends Pick<ButtonProps, 'color'>, Omit<LinkProps, 'color'> {
  chevron?: boolean;
  endIcon?: React.ReactNode;
  label: string;
  size?: TextSize;
  startIcon?: React.ReactNode;
}

const StyledLink = styled(Link)<Pick<ButtonProps, 'color'>>(({ color = 'primary', theme }) => ({
  alignItems: 'center',
  color: color === 'inherit' ? color : theme.palette[color].main,
  display: 'flex',

  '& > svg:first-of-type': {
    marginRight: theme.spacing(0.5)
  },
  ':hover': {
    color: theme.palette.brandblue[600]
  }
})) as typeof Link;

/**
 * Component to render links consistently
 *
 * @param chevron - Hides chevron icon at end of link
 * @param color - MUI color option
 * @param endIcon - Icon to show behind the label (if `chevron` is false)
 * @param label - Label to create a link for
 * @param startIcon - Icon to show in front of the label
 * @param ...LinkProps - All props available to `Link` component
 */
const LinkButton = ({ chevron, endIcon, label, startIcon, ...props }: LinkButtonProps): JSX.Element => (
  <StyledLink
    component={!!props.rel || !!props.target ? 'a' : 'button'}
    underline={props.underline || 'hover'}
    {...props}
  >
    {startIcon}
    <Text color="inherit" size={props.size || 'sm'}>
      {label}
    </Text>
    {!chevron ? endIcon : <ChevronRight color="inherit" fontSize="small" />}
  </StyledLink>
);

export default LinkButton;
