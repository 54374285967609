import React from 'react';
import { styled } from '@mui/system';
import { SearchResultType, SearchType } from '@utils/search';

import colors from '../../utils/themes/colors';
import { LogoParallelSearch } from '../../assets/CustomIcon';
import { Text } from '../design_component_lib';

export interface MetadataSearchProps {
  type: SearchEngine;
  contentType?: SearchResultType;
}

const Container = styled('div')({
  alignItems: 'center',
  color: colors.gray[700],
  display: 'flex'
});

/**
 * Component to show metadata related to a search type
 */
const MetadataSearchType = ({ type }: MetadataSearchProps): JSX.Element => {
  const sapsIcon = <LogoParallelSearch fontSize="small" />;
  const sapsSearch = type === 'parallel-search';
  // Align base text with results found count
  const baseText = !!sapsSearch ? '\xa0recommended by' : 'found with';

  return (
    <Container sx={{ marginTop: '0.25rem' }}>
      {!!sapsSearch && sapsIcon}
      <Text color="inherit" size="sm" sx={{ borderBottom: '0.0625rem solid transparent' }}>
        {baseText}&nbsp;
      </Text>
      <Text bold color="secondary" size="sm">
        {!!sapsSearch ? SearchType.PARALLEL : SearchType.KEYWORD}
      </Text>
    </Container>
  );
};

export default MetadataSearchType;
