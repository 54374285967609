import { createSearchParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { isArray, isBoolean } from 'lodash';

import { LOG } from 'services/logging/LoggingService';

/**
 * Function to format a dateTime to a human readable format
 *
 * @param dateTime - `Date` | `string` (if not supplied, returns 'Unknown')
 */
export const dateTimeFormat = (dateTime?: Date | string | null): string => {
  try {
    return !dateTime
      ? 'Unknown'
      : format(typeof dateTime === 'string' ? parseISO(dateTime) : dateTime, 'M/d/yy, h:mm a');
  } catch (err: any) {
    LOG.error(`Error converting date: ${err && err.message}`);
    return dateTime!.toString();
  }
};

/**
 * Generates absolute URL to internal Angular app page
 * (necessary because for local development apps are running on different ports)
 *
 * @param relativeUrl Relative path (e.g /demo)
 */
export const getAngularAppUrl = (relativeUrl: string) => {
  return `${process.env.REACT_APP_MAAT_DOMAIN || ''}${relativeUrl}`;
};

/**
 * Function to transform a search param filter value to a string.
 *
 * @param val - The value to transform
 */
export const searchParamValueToStr = (val: string | string[] | number | boolean): string => {
  let newValAsStr: string;

  if (isArray(val)) {
    newValAsStr = val.join(',');
  } else if (isBoolean(val)) {
    newValAsStr = val ? 'true' : 'false';
  } else {
    newValAsStr = `${val}`;
  }

  return newValAsStr;
};

/**
 * Function to create a sorted query param string, removing undefined or null params
 *
 * @param queryParams
 */
export const sortedSearchStr = (queryParams: { [key: string]: any }): string => {
  const sortedQueryParamKeys = Object.keys(queryParams).sort();
  const queryParamsStrs: { [key: string]: string } = {};

  for (const key of sortedQueryParamKeys) {
    const hasItems = isArray(queryParams[key]) ? !!queryParams[key].length : true;
    if (queryParams[key] !== null && queryParams[key] !== undefined && key !== 'page' && hasItems) {
      queryParamsStrs[key] = searchParamValueToStr(queryParams[key]);
    }
  }

  return `?${createSearchParams(queryParamsStrs)}`;
};

// Make package import cleaner
export * from './api';
export * from './document-types';
export * from './experiments';
export * from './jurisdiction';
export * from './modal';
export * from './routes';
export * from './search';
export * from './toast';
