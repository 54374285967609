import React from 'react';

import { Text } from '../design_component_lib';

import './SearchError.scss';

/**
 * Component used for displaying results error messaging
 */
const SearchError = (): JSX.Element => {
  return (
    <div className="ct-error-results">
      <div className="ct-error-results-content">
        <Text size="lg" color="primary" weight="500" className="ct-no-result-title">
          Something went wrong
        </Text>
        <Text size="sm" color="secondary">
          We're having trouble showing this page.
        </Text>
        <Text size="sm" color="secondary">
          Please try again in a few minutes.
        </Text>
        <Text size="sm" color="secondary" className="contact-container">
          If the problem persists, email the Casetext support team at{' '}
          <a
            href="mailto:support@casetext.com?subject=Support question"
            target="_new"
            className="contact-support"
            aria-label="contact support"
          >
            <span>support@casetext.com</span>
          </a>
          .
        </Text>
      </div>
    </div>
  );
};

export default SearchError;
