import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import AnalyticsService from 'services/analytics/AnalyticsService';
import { ClientAnalyticsEvent } from '@utils/analytics-events';
import { setSignInModalState } from 'store/modalSlice';
import { ModalState } from '@utils';
import { addToClipboard } from '@utils/copy-cite-utils';

import { copyCiteToClipboard } from '../../store/searchSlice';
import { useAppDispatch } from '../../utils/hooks/redux';

// We need this to take care of IOS and Safari bugs with copy cite
const useFallback =
  !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/) /* eslint-disable-line */ ||
  (/iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream);

/**
 * Component to render Copy Cite button, copy citation with
 * text selection on click, and display a success toast
 *
 * @param doc case data from search result
 * @returns Copy Cite button and success snackbar
 */
const CopyCite = ({ doc, isAuthedUser }: SearchResult) => {
  const dispatch = useAppDispatch();
  const foundCase = doc;
  const [citationString, setCitationString] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(!!useFallback);

  /**
   * Effect to set the citation string on button render. Disables button until citation generated. Fixes bug on Safari.
   */
  useEffect(() => {
    if (useFallback) {
      const hasSuperSnippet = !!foundCase.paragraphs?.superSnippet && !!foundCase.paragraphs.superSnippet.length;
      dispatch(
        copyCiteToClipboard({
          docSlug: foundCase.slug,
          pageNum: hasSuperSnippet ? foundCase.paragraphs.superSnippet[0].page : foundCase.paragraphs?.rows[0]?.page,
          citationText: hasSuperSnippet
            ? foundCase.paragraphs.superSnippet[0].text
            : foundCase.paragraphs?.rows[0]?.matchingText
        })
      )
        .then(({ payload }) => {
          setCitationString(payload as string);
          setButtonDisabled(false);
        })
        .catch((err: any) => {
          console.log('Error building case citation in Safari.', err);
        });
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Renders case citation, copies string to clipboard, and sets snackbar state to open
   */
  const handleClick = async () => {
    AnalyticsService.Instance.track(ClientAnalyticsEvent.CLICKED_COPY_WITH_CITE);
    if (!isAuthedUser) {
      dispatch(setSignInModalState(ModalState.OPEN));
    } else {
      if (!useFallback) {
        const hasSuperSnippet = !!foundCase.paragraphs?.superSnippet && !!foundCase.paragraphs.superSnippet.length;
        dispatch(
          copyCiteToClipboard({
            docSlug: foundCase.slug,
            pageNum: hasSuperSnippet ? foundCase.paragraphs.superSnippet[0].page : foundCase.paragraphs?.rows[0]?.page,
            citationText: hasSuperSnippet
              ? foundCase.paragraphs.superSnippet[0].text
              : foundCase.paragraphs?.rows[0]?.matchingText
          })
        )
          .then(({ payload }) => {
            addToClipboard(dispatch, payload as string);
          })
          .catch((err: any) => {
            console.log('Error building case citation.', err);
          });
      } else {
        addToClipboard(dispatch, citationString);
      }
    }
  };

  return (
    <div>
      <Button disabled={buttonDisabled} onClick={handleClick}>
        Copy Cite
      </Button>
    </div>
  );
};

export default CopyCite;
