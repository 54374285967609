import { ExpandMore } from '@mui/icons-material';
import { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  List,
  ListItemButton,
  ListItemText,
  listItemTextClasses,
  styled,
  Tooltip,
  Typography,
  typographyClasses
} from '@mui/material';
import { SearchResultType } from '@utils';
import AnalyticsService from 'services/analytics/AnalyticsService';
import { ClientAnalyticsEvent } from '@utils/analytics-events';
import { addTeaserHit } from 'store/crmSlice';
import { TeaserType } from 'views/Teaser';

import { useAppDispatch } from '../../utils/hooks/redux';

import './ResultsSidebar.scss';

interface ResultsSidebarProps {
  onChange: (newContentType: SearchResultType) => void;
  currentContentType: SearchResultType;
  hideTooltips?: boolean;
}

interface CategoryMap {
  primary: ResultFilter[];
  secondary: ResultFilter[];
  specialized: ResultFilter[];
}

export interface ResultFilter {
  category: Category;
  id: SearchResultType;
  title: string;
  subtitle?: string;
  tooltip?: string;
}

export enum Category {
  PRIMARY_LAW = 'Primary Law',
  SECONDARY_SOURCES = 'Secondary Sources',
  SPECIALIZED_DBS = 'Specialized Databases'
}

/**
 * List of all filter categories and properties used to generate the filter list
 */
const categoryMap: CategoryMap = {
  primary: [
    {
      category: Category.PRIMARY_LAW,
      id: SearchResultType.CASE,
      title: 'Cases',
      tooltip: 'Federal and state cases'
    },
    {
      category: Category.PRIMARY_LAW,
      id: SearchResultType.STATUTE,
      title: 'Statutes',
      tooltip: 'Federal and state statutes'
    },
    {
      category: Category.PRIMARY_LAW,
      id: SearchResultType.REGULATION,
      title: 'Regulations',
      tooltip: 'Federal and state regulations'
    },
    {
      category: Category.PRIMARY_LAW,
      id: SearchResultType.RULE,
      title: 'Rules',
      tooltip: 'Rules of Court / Procedure'
    },
    {
      category: Category.PRIMARY_LAW,
      id: SearchResultType.ADMIN_LAW,
      title: 'Admin. Materials',
      tooltip: 'Administrative agency decisions'
    }
  ],
  secondary: [
    {
      category: Category.SECONDARY_SOURCES,
      id: SearchResultType.BRIEF,
      title: 'Federal Briefs',
      tooltip: 'Publicly filed briefs'
    },
    {
      category: Category.SECONDARY_SOURCES,
      id: SearchResultType.GAVELYTICS,
      title: 'California Briefs & Forms',
      subtitle: 'By Gavelytics'
    },
    {
      category: Category.SECONDARY_SOURCES,
      id: SearchResultType.GUIDE,
      title: 'Practice Guides',
      subtitle: 'By James Publishing'
    },
    {
      category: Category.SECONDARY_SOURCES,
      id: SearchResultType.ANALYSIS,
      title: 'Legal Analyses',
      tooltip: 'Articles by attorneys'
    }
  ],
  specialized: [
    {
      category: Category.SPECIALIZED_DBS,
      id: SearchResultType.BLL,
      title: 'Black Letter Law',
      tooltip: 'Concise legal principles'
    },
    {
      category: Category.SPECIALIZED_DBS,
      id: SearchResultType.HOLDING,
      title: 'Holdings',
      tooltip: 'Summaries written by judges'
    }
  ]
};

const SidebarListItem = styled(ListItemButton)(({ theme }) => ({
  padding: 0,
  borderRight: '4px solid transparent',
  paddingLeft: '1.5rem',
  borderRadius: '0.25rem 0 0 0.25rem',
  transition: 'background-color 0.2s, border-color 0.2s',
  ':hover,&.Mui-selected': {
    borderRightColor: theme.palette.brandblue[500]!,
    backgroundColor: alpha(theme.palette.brandblue[500]!, 0.075)
  }
})) as typeof ListItemButton;

const SidebarList = styled(List)({
  padding: 0
});

const SidebarListItemText = styled(ListItemText)({
  [`.${typographyClasses.root}`]: {
    fontSize: '14px'
  },
  [`.${listItemTextClasses.secondary}`]: {
    fontSize: '12px'
  }
});

/**
 * Component to display the results sidebar with dynamic properties
 *
 * @param onChange function to handle database changes
 * @param currentContentType the current database the user has selected
 * @param hideTooltips boolean for whether to hide database tooltips
 *
 * @returns An accordian-style sidebar with database categories on the search results page
 */
const ResultsSidebar = ({ onChange, currentContentType, hideTooltips }: ResultsSidebarProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [secondaryExpanded, setSecondaryExpanded] = useState<boolean>(false);
  const [specializedExpanded, setSpecializedExpanded] = useState<boolean>(false);

  /**
   * Helper function to fire the right analytics event
   *
   * @param isExpanded true if accordian is expanded
   * @param category the category to record the analytics event for (Primary, Secondary, Specialized)
   */
  const accordianAnalytics = (isExpanded: boolean, category: Category) => {
    !!isExpanded
      ? AnalyticsService.Instance.track(ClientAnalyticsEvent.EXPANDED_DB_LIST, { list: category })
      : AnalyticsService.Instance.track(ClientAnalyticsEvent.COLLAPSED_DB_LIST, { list: category });
  };

  const handleChange = (database: ResultFilter) => {
    // Record segment analytics
    let eventToFire;
    switch (database.category) {
      case Category.PRIMARY_LAW:
        eventToFire = ClientAnalyticsEvent.CLICKED_PRIMARY_LAW;
        break;
      case Category.SECONDARY_SOURCES:
        eventToFire = ClientAnalyticsEvent.CLICKED_SECONDARY_SOURCE;
        break;
      case Category.SPECIALIZED_DBS:
        eventToFire = ClientAnalyticsEvent.CLICKED_SPECIALIZED_DB;
        break;
    }
    AnalyticsService.Instance.track(eventToFire, { database: database.title });

    // Record Hubspot analytics
    if (database.id !== SearchResultType.CASE) {
      dispatch(addTeaserHit({ teaserType: TeaserType.DATABASE, database }));
    }

    // Change route to correct database
    onChange(database.id);
  };

  return (
    <div className="ct-sidebar-container">
      <Accordion
        classes={{ root: 'result-sidebar-accordion-root' }}
        defaultExpanded
        elevation={0}
        disableGutters
        onChange={(e, isExpanded) => {
          accordianAnalytics(isExpanded, Category.PRIMARY_LAW);
        }}
      >
        <AccordionSummary
          classes={{
            root: 'result-sidebar-accordion-summary-root',
            content: 'result-sidebar-accordion-summary-content'
          }}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{Category.PRIMARY_LAW}</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{ root: 'result-sidebar-accordion-details-root' }}>
          <SidebarList>
            {categoryMap.primary.map((category) => (
              <SidebarListItem
                key={category.id}
                selected={category.id === currentContentType}
                onClick={() => handleChange(category)}
              >
                <Tooltip title={(!hideTooltips && category.tooltip) || ''} arrow placement="right" key={category.id}>
                  <SidebarListItemText primary={category.title} />
                </Tooltip>
              </SidebarListItem>
            ))}
          </SidebarList>
        </AccordionDetails>
      </Accordion>
      <Accordion
        classes={{ root: 'result-sidebar-accordion-root' }}
        elevation={0}
        disableGutters
        expanded={secondaryExpanded}
        onChange={(e, isExpanded) => {
          accordianAnalytics(isExpanded, Category.SECONDARY_SOURCES);
          setSecondaryExpanded(isExpanded);
        }}
      >
        <AccordionSummary
          classes={{
            root: 'result-sidebar-accordion-summary-root',
            content: 'result-sidebar-accordion-summary-content'
          }}
          expandIcon={<ExpandMore />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>{Category.SECONDARY_SOURCES}</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{ root: 'result-sidebar-accordion-details-root' }}>
          {categoryMap.secondary.map((category) => (
            <SidebarListItem
              key={category.id}
              selected={category.id === currentContentType}
              onClick={() => handleChange(category)}
            >
              <Tooltip title={(!hideTooltips && category.tooltip) || ''} arrow placement="right" key={category.id}>
                <SidebarListItemText primary={category.title} secondary={category.subtitle} />
              </Tooltip>
            </SidebarListItem>
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion
        classes={{ root: 'result-sidebar-accordion-root' }}
        elevation={0}
        disableGutters
        expanded={specializedExpanded}
        onChange={(e, isExpanded) => {
          accordianAnalytics(isExpanded, Category.SPECIALIZED_DBS);
          setSpecializedExpanded(isExpanded);
        }}
      >
        <AccordionSummary
          classes={{
            root: 'result-sidebar-accordion-summary-root',
            content: 'result-sidebar-accordion-summary-content'
          }}
          expandIcon={<ExpandMore />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>{Category.SPECIALIZED_DBS}</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{ root: 'result-sidebar-accordion-details-root' }}>
          {categoryMap.specialized.map((category) => (
            <SidebarListItem
              key={category.id}
              selected={category.id === currentContentType}
              onClick={() => handleChange(category)}
            >
              <Tooltip title={(!hideTooltips && category.tooltip) || ''} arrow placement="right" key={category.id}>
                <SidebarListItemText primary={category.title} />
              </Tooltip>
            </SidebarListItem>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ResultsSidebar;
