import React from 'react';

import { HeadingLink } from '../../design_component_lib';
import { ResultTreatment } from './ResultTreatment';

interface ResultHeaderProps {
  /** Function to call when link in the header got clicked */
  onHeaderClick: () => void;
  /** Prompt to show as title */
  prompt: string;
  /** Citator information */
  citator: any;
  /** URL for header link */
  href: string;
  /** Is user signed in */
  isAuthedUser: boolean;
}

/**
 * Component to render a search result's (linked) title, including the citator treatment flag
 *
 */
const ResultHeader = ({ prompt, citator, href, isAuthedUser, onHeaderClick }: ResultHeaderProps): JSX.Element => {
  const baseHeadingLinkProps = {
    size: 'xl',
    weight: 'medium',
    underline: 'hover',
    color: 'primary-blue',
    onClick: () => onHeaderClick()
  };

  const headingLinkProps = isAuthedUser
    ? { ...baseHeadingLinkProps, component: 'a', href, target: '_blank' }
    : { ...baseHeadingLinkProps, component: 'button' };

  return (
    <div className="ct-search-result-header">
      <h4>
        <ResultTreatment citator={citator} />
        <HeadingLink {...headingLinkProps}>{prompt}</HeadingLink>
      </h4>
    </div>
  );
};

export default ResultHeader;
