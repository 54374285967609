import React from 'react';
import { Grid } from '@mui/material';

import { Heading, LinkButton } from '../design_component_lib';

interface JxColumnProps {
  children?: React.ReactNode;
  onClear?: () => void;
  onSelect?: () => void;
  title: string;
}

/** Component to render a column to Jurisdiction selection */
const JxColumn = ({ children, onClear = () => null, onSelect = () => null, title }: JxColumnProps): JSX.Element => (
  <Grid alignContent="flex-start" item sm={6} xs={12}>
    <Grid container item pb={2} spacing={2} xs={12}>
      <Grid item xs={12}>
        <Heading size="xl" weight="bold">
          {title}
        </Heading>
      </Grid>
      <Grid columnGap={2} container item xs={12}>
        <LinkButton data-testid="select-all" label="Select All" onClick={onSelect} />
        <LinkButton data-testid="clear-all" label="Clear All" onClick={onClear} />
      </Grid>
    </Grid>
    {children}
  </Grid>
);

export default JxColumn;
