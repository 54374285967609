import { Close } from '@mui/icons-material';
import { useMediaQuery, Dialog, IconButton, DialogContent, useTheme } from '@mui/material';
import { ModalState } from '@utils';
import { setSearchEdModalState, setShareModalState, setSignInModalState, setTeaserModalState } from 'store/modalSlice';
import { useAppDispatch } from 'utils/hooks/redux';
import Teaser, { TeaserType } from 'views/Teaser';
import { LOG } from 'services/logging/LoggingService';

import ShareContent from './ShareContent';
import SignInContent from './SignInContent';
import SearchEdContent from './SearchEdContent';

import './Modal.scss';

export interface ModalProps {
  isModalOpen: ModalState /* Redux state for if modal is open */;
  teaserType?: TeaserType /* If type is Teaser, then teaserType must be defined */;
  type: ModalType /* Type of modal to know which redux action to dispatch */;
  removeCloseBtn?: boolean /* Option to remove the 'x' button from top right corner */;
}

// Add new modal types here
export enum ModalType {
  SIGN_IN = 'sign-in',
  SEARCH_ED = 'search-ed',
  SHARE = 'share',
  TEASER = 'teaser'
}

// 700px
const STANDARD_MODAL_WIDTH = 43.75;

/**
 * Modal component to generate a modal, work in progress
 *
 * @returns a Modal skeleton populated by content based on ModalType
 */
const Modal = ({ isModalOpen, type, teaserType, removeCloseBtn }: ModalProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  // Error handling: Make sure right props are used
  if (type === ModalType.TEASER && teaserType === undefined) {
    LOG.error('Teaser Modal not given type (either filter or database)');
    return null;
  }

  /**
   * Add new modal classes and content here.
   * Note: Teaser css contained in Teaser.tsx since they're not always in modal form
   */
  const modalSpecifics: Map<ModalType, { modalClassName?: string; content: JSX.Element }> = new Map([
    [ModalType.SEARCH_ED, { modalClassName: 'search-ed-modal', content: <SearchEdContent /> }],
    [ModalType.SHARE, { modalClassName: 'share-modal', content: <ShareContent /> }],
    [ModalType.SIGN_IN, { modalClassName: 'signin-modal', content: <SignInContent /> }],
    [ModalType.TEASER, { content: <Teaser type={teaserType} /> }]
  ]);

  const handleClose = () => {
    switch (type) {
      case ModalType.SHARE:
        dispatch(setShareModalState(ModalState.CLOSED));
        break;
      case ModalType.TEASER:
        dispatch(setTeaserModalState({ modalState: ModalState.CLOSED, teaserType }));
        break;
      case ModalType.SEARCH_ED:
        dispatch(setSearchEdModalState({ modalState: ModalState.CLOSED, userSeen: true }));
        break;
      case ModalType.SIGN_IN:
        dispatch(setSignInModalState(ModalState.CLOSED));
        break;
    }
  };

  return (
    <Dialog
      className="ct-gen-modal"
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: `${STANDARD_MODAL_WIDTH}rem`,
          margin: `${isSmDown ? '1rem' : '2rem'}`
        }
      }}
      open={isModalOpen === ModalState.OPEN}
      maxWidth={false}
      onClose={handleClose}
    >
      {!removeCloseBtn && (
        <IconButton onClick={handleClose} edge="end" data-testid="modal-close" aria-label="close" className="close-btn">
          <Close htmlColor="black" />
        </IconButton>
      )}
      <DialogContent className={modalSpecifics.get(type)?.modalClassName}>
        {modalSpecifics.get(type)?.content}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
