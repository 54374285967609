import React from 'react';
import { ExpandMore } from '@mui/icons-material';
import { usePopupState, bindToggle, bindPopper } from 'material-ui-popup-state/hooks';
import { SearchResultType } from '@utils/search';
import { ChipProps, useMediaQuery, useTheme } from '@mui/material';
import { setTeaserModalState } from 'store/modalSlice';
import { ModalState } from '@utils';
import { TeaserType } from 'views/Teaser';

import ResultsSidebar from './ResultsSidebar';
import { StyledChip } from './ResultsFilters';
import FilterPopper from './FilterPopper';
import { updateActiveSearchFilters } from '../../store/searchSlice';
import { useAppDispatch } from '../../utils/hooks/redux';

import './ContentType.scss';

export interface ContentTypeProps {
  currentContentType: SearchResultType;
}

interface FilterChipProps extends ChipProps {
  open?: boolean;
}

/**
 * Component to display a filter (optionally active or removable)
 *
 * @param open - Boolean to render open state of the filter
 * @param ...props - {@link ChipProps}
 */
const FilterChip = ({ open, ...props }: FilterChipProps) => (
  <StyledChip
    icon={<ExpandMore />}
    classes={{ icon: open ? 'ct-expanded' : undefined }}
    className="ct-filter-chip"
    color="secondary"
    size="small"
    variant="outlined"
    {...props}
  />
);

/**
 * Component to handle content type selections
 */
const ContentType = ({ currentContentType }: ContentTypeProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const popupState = usePopupState({ variant: 'popper', popupId: 'content-type-filter' });
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const navigateToType = (type: SearchResultType) => {
    !!isMdDown
      ? dispatch(setTeaserModalState({ modalState: ModalState.OPEN, teaserType: TeaserType.DATABASE }))
      : dispatch(updateActiveSearchFilters({ type }));
    popupState.close();
  };

  return (
    <div className="ct-content-type-filter">
      <FilterChip
        data-testid="filter-content-type-btn"
        label="Cases"
        open={popupState.isOpen}
        {...bindToggle(popupState)}
      />
      <FilterPopper onClickAway={popupState.close} {...bindPopper(popupState)}>
        <ResultsSidebar
          hideTooltips={true}
          currentContentType={currentContentType}
          onChange={(newContentType: SearchResultType) => navigateToType(newContentType)}
        />
      </FilterPopper>
    </div>
  );
};

export default ContentType;
