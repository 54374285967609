import React from 'react';
import { Chip, chipClasses, styled, useMediaQuery, useTheme } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { ClientAnalyticsEvent } from '@utils/analytics-events';
import AnalyticsService from 'services/analytics/AnalyticsService';
import { TeaserType } from 'views/Teaser';
import { addTeaserHit } from 'store/crmSlice';
import { selectActiveSearchFilters } from 'store/searchSlice';

import { useAppDispatch, useAppSelector } from '../../utils/hooks/redux';
import { ModalState, SearchResultType } from '../../@utils';
import { setTeaserModalState } from '../../store/modalSlice';
import ContentType from './ContentType';

import './ResultsFilters.scss';

export const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  borderWidth: 0,
  flexDirection: 'row-reverse',
  transition: 'color 0.2s',
  [`.${chipClasses.label}.${chipClasses.labelSmall}`]: {
    fontSize: theme.spacing(1.5),
    maxWidth: theme.spacing(25)
  },
  [`&.${chipClasses.filled} .${chipClasses.icon}`]: {
    marginLeft: theme.spacing(-0.5),
    marginRight: theme.spacing(0.5)
  },
  [`&.${chipClasses.outlined}`]: {
    paddingLeft: 0,
    paddingRight: 0,
    [`.${chipClasses.label}.${chipClasses.labelSmall}`]: {
      paddingLeft: 0,
      paddingRight: 0
    },
    ':active': {
      boxShadow: 'none'
    },
    ':hover': {
      backgroundColor: 'transparent',
      color: theme.palette.gray[900]
    }
  }
}));

// Not in alpha order to match research app order (using map function)
export enum FILTER_LABELS {
  PUBLISHED = 'Published and unpublished',
  DATES = 'All dates',
  SEARCH_WITHIN = 'Search within',
  ADVANCED_FILTERS = 'Advanced filters'
}

/**
 * Component to handle and display all results filters
 *
 */
const ResultsFilters = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const activeFilters = useAppSelector(selectActiveSearchFilters);

  /**
   * Function to handle filter click by showing modal.
   */
  const handleFilterClick = (filter: FILTER_LABELS) => {
    dispatch(setTeaserModalState({ modalState: ModalState.OPEN, teaserType: TeaserType.FILTER }));
    AnalyticsService.Instance.track(ClientAnalyticsEvent.CLICKED_SEARCH_FILTER, { filterType: filter });
    // Track teaser hit in hubspot CRM
    dispatch(addTeaserHit({ teaserType: TeaserType.FILTER, filterType: filter }));
  };

  return (
    <div className="ct-results-filters">
      <div className="popover-filters-container">
        {isMdDown && <ContentType currentContentType={activeFilters.type || SearchResultType.CASE} />}
        {Object.values(FILTER_LABELS).map((label) => {
          return (
            <StyledChip
              key={label}
              icon={<ExpandMore />}
              className="ct-filter-chip"
              color="secondary"
              size="small"
              variant="outlined"
              label={label}
              onClick={() => handleFilterClick(label)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ResultsFilters;
