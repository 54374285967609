import { createSlice } from '@reduxjs/toolkit';

import { RootState } from './store';

const initialState: JurisdictionStore = {
  selectedJXs: []
};

/**
 * Search state
 */
export const jurisdictionSlice = createSlice({
  name: 'jurisdiction',
  initialState,
  reducers: {
    setSelectedJXs: (state, { payload }) => {
      state.selectedJXs = [...payload];
      return state;
    },
    resetSelectedJXs: (state) => {
      state.selectedJXs = initialState.selectedJXs;
      return state;
    }
  }
});

export const { resetSelectedJXs, setSelectedJXs } = jurisdictionSlice.actions;
export const selectSelectedJXs = (state: RootState) => state.jurisdictionState.selectedJXs;

export default jurisdictionSlice.reducer;
