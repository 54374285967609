import { ArrowBack } from '@mui/icons-material';
import { Box, Button, DialogActions, DialogContent, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import { useKeyPress, useOnClickOutside } from 'utils/hooks/react';
import { SearchInputStates } from '@utils';
import AnalyticsService from 'services/analytics/AnalyticsService';
import { ClientAnalyticsEvent } from '@utils/analytics-events';

import { JxSelect, SearchInputWithSuggestions } from '../..';
import { selectSelectedJXs, setSelectedJXs } from '../../../store/jurisdictionSlice';
import { selectSearchInputOpenState, setSearchInputOpenState } from '../../../store/modalSlice';
import { selectShowPSPopover, selectUserQuery, setShowPSPopover } from '../../../store/searchSlice';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks/redux';
import ParallelSearchPopover from './ParallelSearchPopover';

import './SearchInput.scss';

interface SearchInputProps {
  isSearchResultsPage?: boolean;
  isHomepage?: boolean;
}

function SearchInput({ isSearchResultsPage = false, isHomepage = false }: SearchInputProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const searchInputOpenState = useAppSelector(selectSearchInputOpenState);
  const showPSPopover = useAppSelector(selectShowPSPopover);
  const selectedJXs = useAppSelector(selectSelectedJXs);
  const userQuery = useAppSelector(selectUserQuery);

  const [selJx, setSelJx] = useState<string[]>(selectedJXs);
  const [searchTerm, setSearchTerm] = useState(userQuery);
  const [isJxFromSearch, setIsJxFromSearch] = useState(false);
  const [searchAutoFocus, setSearchAutoFocus] = useState(false);
  const isSearchInputOpen = searchInputOpenState !== SearchInputStates.NONE;

  const clickRef = useRef<HTMLDivElement>(null);
  const favMenu = useRef<HTMLDivElement>(null);
  useOnClickOutside(clickRef, favMenu, isSearchInputOpen, () => {
    if (isSearchInputOpen) {
      dispatch(setSearchInputOpenState(SearchInputStates.NONE));
    }
  });

  useEffect(() => {
    // Hides the PS popover when the user blurs the search field
    if (searchInputOpenState !== SearchInputStates.DEFAULT) {
      dispatch(setShowPSPopover(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputOpenState]);

  useEffect(() => {
    setSelJx(selectedJXs);
  }, [selectedJXs]);

  useEffect(() => {
    setSearchTerm(userQuery);
  }, [userQuery, setSearchTerm]);

  useEffect(() => {
    // Resetting the search term and JX selection when the modal is closed
    if (searchInputOpenState === SearchInputStates.JX) {
      setSelJx(selectedJXs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputOpenState]);

  const escapePress = useKeyPress('Escape');
  useEffect(() => {
    if (!!escapePress && isSearchInputOpen) {
      dispatch(setSearchInputOpenState(SearchInputStates.NONE));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [escapePress]);

  /**
   * Function to handle jx button clicks.
   */
  const jxButtonClick = () => {
    dispatch(setSearchInputOpenState(SearchInputStates.JX));
    AnalyticsService.Instance.track(ClientAnalyticsEvent.OPENED_JX_MODAL);
    setIsJxFromSearch(true);
  };

  /**
   * Function to handle back button clicks.
   */
  const backToSearchClick = (autoFocus = false) => {
    dispatch(setSearchInputOpenState(SearchInputStates.DEFAULT));
    setSearchAutoFocus(autoFocus);
    setIsJxFromSearch(false);
  };

  /**
   * Function to dialog close
   */
  const handleClose = () => {
    dispatch(setSearchInputOpenState(SearchInputStates.NONE));
    setIsJxFromSearch(false);
  };

  /**
   * Function to keep track of user input changes
   *
   * @param searchTerm - The new search term
   */
  const handleTextChange = (searchTerm: string): void => {
    setSearchTerm(searchTerm);
  };

  /**
   * Function to set selected jxs
   */
  const setJxSelection = () => {
    dispatch(setSelectedJXs(selJx));
    AnalyticsService.Instance.track(ClientAnalyticsEvent.APPLIED_JX_FILTER, {
      jxs: selJx
    });
    if (isJxFromSearch) {
      backToSearchClick();
    } else {
      handleClose();
    }
  };

  /**
   * Function to get the modal content to display
   */
  const getModalContent = () => {
    let content;

    switch (searchInputOpenState) {
      case SearchInputStates.JX:
        content = (
          <>
            <Box>
              <Button
                variant="text"
                classes={{ root: 'action' }}
                startIcon={<ArrowBack />}
                onClick={() => backToSearchClick(true)}
                data-testid="back-button"
              >
                Back to Search
              </Button>
            </Box>
            <DialogContent
              classes={{ root: classnames('jx-modal', { 'is-mobile': isMobile }) }}
              data-testid="jx-modal"
              dividers
            >
              <JxSelect initialJXs={selJx} onSelectionChange={setSelJx} />
            </DialogContent>
            <DialogActions className="ct-search-modal-actions">
              <Button data-testid="search-modal-select-jx-button" onClick={setJxSelection} variant="contained">
                {`Apply${isMobile || isMedium ? '' : ' Jurisdictions'}`}
              </Button>
            </DialogActions>
          </>
        );
        break;
      default:
        content = (
          <>
            {showPSPopover && isSearchInputOpen && isHomepage && (
              <div className="float-above-input">
                <ParallelSearchPopover arrowDirection="bottom" />
              </div>
            )}
            <SearchInputWithSuggestions
              isMobile={isMobile}
              isMedium={isMedium}
              isSearchResultsPage={isSearchResultsPage}
              isHomepage={isHomepage}
              currentSearchText={searchTerm}
              handleTextChange={handleTextChange}
              jxButtonClick={jxButtonClick}
              closeModal={handleClose}
              searchAutoFocus={searchAutoFocus}
            />
            {showPSPopover && isSearchInputOpen && !isHomepage && <ParallelSearchPopover arrowDirection="top" />}
          </>
        );
        break;
    }

    return content;
  };

  return (
    <>
      <div
        className={classnames('ct-search-input', {
          'jx-open': searchInputOpenState === SearchInputStates.JX,
          'wide-search': !isSearchResultsPage,
          'is-serp': isSearchResultsPage
        })}
      >
        <div
          ref={clickRef}
          className={classnames('ct-search-input-container', {
            'jx-open': searchInputOpenState !== SearchInputStates.DEFAULT && isSearchInputOpen,
            'is-mobile': isMobile,
            'is-serp': isSearchResultsPage,
            'is-large': isHomepage,
            'search-open': isSearchInputOpen
          })}
        >
          {getModalContent()}
        </div>
        {isSearchInputOpen && <div className="ct-background-blocker"></div>}
      </div>
    </>
  );
}

export default SearchInput;
