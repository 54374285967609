import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled, Link, ButtonGroup, Button } from '@mui/material';
import { sanitizeSearchResultText, SearchResultType } from '@utils';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { UiLabel } from 'components/design_component_lib';

interface ResultSnippetsProps {
  /** Function to call when link in the snippets got clicked */
  onSnippetsClick: () => void;
  /** List of available snippets */
  snippets: SearchResultSnippet[];
  /** Type of search result */
  type: SearchResultType;
  /** If the user is signed in or not */
  isAuthedUser: boolean;
}

/**
 * Defines the component props you can pass to a `SnippetLink` component
 */
interface SnippetLinkProps extends React.HTMLAttributes<HTMLElement> {
  /** The Snippet HTML text to pass to `dangerouslySetInnerHTML` of the link */
  text: string;
  /**
   * The link to navigate to when the user clicks the snippet (or opens in new tab)
   */
  href?: string;
  /**
   * Allow for tracking click on snippet
   */
  onClick: () => void;
  /** If the user is signed in or not */
  isAuthedUser: boolean;
}

/**
 * Defines the component props you can pass to a `StyledSnippetLink` component
 */
interface StyledSnippetLinkProps extends React.HTMLAttributes<HTMLElement> {
  text: null;
  dangerouslySetInnerHTML: { __html: string };
  component: typeof RouterLink | 'a' | 'button';
  to?: string;
  href?: string;
}

/**
 * Defines the styles of the snippet's text
 */
export const StyledSnippetLink = styled(Link, {
  name: 'SnippetLink',
  slot: 'Wrapper'
})(({ theme }) => ({
  display: 'block',
  lineHeight: 1.5,
  fontSize: theme.spacing(2),
  textAlign: 'left',
  margin: 0,
  color: theme.palette.common.black,
  textDecoration: 'none'
}));

export interface SnippetPages {
  /** The total number of pages */
  pageCount: number;
  /** The current page number of the snippet */
  pageNum: number;
  /** The current snippet */
  currSnippet: SearchResultSnippet;
  /** Function to increment the page num */
  increment: () => void;
  /** Function to decrement the page num */
  decrement: () => void;
}

/**
 * Component to render the snippet text inside a link.
 * This is required in order to support opening the link in a new tab.
 */
const SnippetLink = ({ href, text, isAuthedUser, ...props }: SnippetLinkProps): JSX.Element => {
  const attrs: StyledSnippetLinkProps = {
    ...props,
    text: null,
    component: isAuthedUser ? 'a' : 'button',
    dangerouslySetInnerHTML: { __html: sanitizeSearchResultText(text) },
    href
  };

  if (isAuthedUser) {
    attrs.href = href;
  }

  return <StyledSnippetLink target="_blank" {...attrs} />;
};

/**
 * Component to render one or more snippets for a search result item
 *
 */
const ResultSnippets = ({ snippets, type, onSnippetsClick, isAuthedUser }: ResultSnippetsProps): JSX.Element => {
  const [page, setPage] = React.useState(1);
  const pageCount = (snippets || []).length;
  const pagesNeeded = pageCount > 1;
  const currSnippet = snippets[page - 1];

  /** Function to increment the page of a multi-snippet result */
  const incrementPage = () => {
    setPage(page + 1);
  };

  /** Function to decrement the page of a multi-snippet result */
  const decrementPage = () => {
    setPage(page - 1);
  };

  return !!pageCount ? (
    <div className={`ct-result-snippets ct-result-snippets-${type || ''}`}>
      {!!pagesNeeded && (
        <div className="snippets-pager-container">
          <div className="snippets-pager">
            <ButtonGroup
              className="snippets-pager-buttons"
              variant="outlined"
              size="small"
              color="primary"
              aria-label="snippets pagination"
            >
              <Button
                aria-label="go to previous snippet"
                disabled={page === 1}
                onClick={decrementPage}
                data-testid="snippet-pager-prev"
              >
                <NavigateBefore />
              </Button>
              <Button
                aria-label="go to next snippet"
                disabled={pageCount === page}
                onClick={incrementPage}
                data-testid="snippet-pager-next"
              >
                <NavigateNext />
              </Button>
            </ButtonGroup>
            <UiLabel size="sm" weight="medium" color="secondary">
              {page} of {pageCount}
            </UiLabel>
          </div>
          <div className="snippets-page-info">
            <UiLabel size="sm" weight="medium" color="secondary">
              Page {currSnippet.page}
            </UiLabel>
          </div>
        </div>
      )}
      <div className="result-snippet-content ct-highlightable">
        {
          <SnippetLink
            onClick={() => onSnippetsClick()}
            href={currSnippet.href}
            className="result-snippet-item"
            text={sanitizeSearchResultText(currSnippet.text)}
            isAuthedUser={isAuthedUser}
          />
        }
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ResultSnippets;
