import React, { useEffect, useState } from 'react';
import { JX } from '@casetext/libjx';
import { Divider, Grid } from '@mui/material';
import { get, some, upperFirst } from 'lodash';
import { useSelector } from 'react-redux';
import {
  getChildJxKeys,
  getJxIdsFromKeys,
  jxKeysFromIds,
  JxSpecial,
  JxTopLevel,
  JX_KEYS,
  JX_TREE
} from '@utils/jurisdiction';
import { selectSearchResultsJxCount } from 'store/searchSlice';

import JxColumn from './JxColumn';
import AccordionCheckbox from '../input/AccordionCheckbox';

const SPECIAL: Record<JxTopLevel, Partial<Record<JxSpecial, string>>> = {
  STATE: {
    SUPREME: 'All State Supreme Courts'
  },
  FEDERAL: {
    CIRCUIT: 'All Circuit Courts',
    DISTRICT: 'All District Courts',
    BANKRUPTCY: 'All Bankruptcy Courts'
  }
};

interface JxSelectProps {
  initialJXs?: string[];
  onSelectionChange?: (jxIds: string[]) => void;
}

const columns = Object.entries(JX_TREE);

/**
 * Gets the name of a JX
 *
 * @param jxId
 * @returns
 */
const getJxName = (jxId: string): string => JX.get(jxId)?.name || '';

/**
 * Hydrate JX Select component based on JX list
 *
 * @param jxIds - Array of selected JX IDs
 * @returns Object map with JX keys and checkbox values
 */
const jxKeyHydrate = (jxIds: string[]): Record<string, Tristate> => {
  const jxObj: Record<string, Tristate> = {};
  jxKeysFromIds(jxIds).forEach((jxKey) => {
    jxObj[jxKey] = true;
    jxKeyUpdateChildren(jxObj, jxKey, true);
    jxKeyUpdateParent(jxObj, jxKey);
    jxStatusSpecial(jxObj);
  });
  return jxObj;
};

/**
 * Update children of a JX based on key
 *
 * @param jxKey - Current JX key
 * @param checked - Status of checkbox (`boolean` | `null`)
 */
const jxKeyUpdateChildren = (updates: Record<string, Tristate>, jxKey: string, checked: Tristate) => {
  (getChildJxKeys(jxKey) || []).forEach((childKey) => {
    updates[childKey] = checked;
  });
};

/**
 * Update parents of a JX based on key (and propagate changes)
 *
 * @param jxKey - Current JX key
 */
const jxKeyUpdateParent = (updates: Record<string, Tristate>, jxKey: string) => {
  const parent = jxKey.split('-');
  parent.pop();
  if (!!parent.length) {
    const parentKey = parent.join('-');
    const childKeys = getChildJxKeys(parentKey) || [];
    const selected = childKeys.filter((key) => updates[key]);
    const allChildren = childKeys.length === selected.length;

    updates[parentKey] = allChildren || (some(selected) ? null : false);
    jxKeyUpdateParent(updates, parentKey);
  }
};

/**
 * Determine status of a SPECIAL JX for checked or unchecked
 *
 * @param jxKey - Current JX key
 */
const jxStatusSpecial = (updates: Record<string, Tristate>) => {
  Object.keys(updates).forEach((jxKey) => {
    Object.keys(get(SPECIAL, jxKey.replace(/-.+/, ''), {})).forEach((special) => {
      const jxSpecial = special as JxSpecial;
      const childKeys = JX_KEYS[jxSpecial];
      if (childKeys.includes(jxKey)) {
        const selected = childKeys.filter((key) => updates[key]);
        const allChildren = childKeys.length === selected.length;
        updates[jxSpecial] = allChildren || (some(selected) ? null : false);
      }
    });
  });
};

/**
 * Component to allow selection of a Jurisdiction
 */
const JxSelect = ({ initialJXs = [], onSelectionChange = () => null }: JxSelectProps): JSX.Element => {
  const [jxs, setJxs] = useState<CheckboxList>(!initialJXs.length ? {} : jxKeyHydrate(initialJXs));
  const jxCount = useSelector(selectSearchResultsJxCount);
  const jxIdCount = JX.calcCounts(jxCount);

  useEffect(() => {
    onSelectionChange(getJxIdsFromKeys(jxs));
  }, [jxs, onSelectionChange]);

  /**
   * Set multiple JX based on key to a single value
   *
   * @param jxKeys - Array of JX keys
   * @param checked - Status of checkbox (`boolean` | `null`)
   */
  const jxSetMany = (jxKeys: string[], checked: Tristate) =>
    setJxs((sel) => ({ ...sel, ...jxKeys.reduce((obj, jxKey) => ({ ...obj, [jxKey]: checked }), {}) }));

  /**
   * Set multiple values of a JX based on key
   *
   * @param jxIdVals - Object with jxKeys (and corresponding values) to update
   */
  const jxSetMulti = (jxIdVals: Record<string, Tristate>) => {
    setJxs((sel) => ({ ...sel, ...jxIdVals }));
  };

  /**
   * Set or clear ALL fields from the top level
   *
   * @param jxKey - Current JX key
   * @param checked - Status of checkbox (`boolean`)
   */
  const allSet = (jxKey: string, checked: boolean) => {
    const topLevel = jxKey.toUpperCase() as JxTopLevel;
    jxSetMany([...JX_KEYS[topLevel], ...Object.keys(SPECIAL[topLevel])], checked);
  };

  /**
   * Update a JX checkbox and propagate changes to children, parents, and special
   *
   * @param jxKey - Current JX key
   * @param checked - Status of checkbox (`boolean` | `null`)
   */
  const jxKeyUpdate = (jxKey: string, checked: Tristate) => {
    const updates = { ...jxs, [jxKey]: checked };
    jxKeyUpdateChildren(updates, jxKey, checked);
    jxKeyUpdateParent(updates, jxKey);
    jxStatusSpecial(updates);
    jxSetMulti(updates);
  };

  /**
   * Update checkbox status of a SPECIAL JX
   *
   * @param jxKey - Current JX key
   * @param checked - Status of checkbox (`boolean` | `null`)
   */
  const jxUpdateSpecial = (jxKey: string, checked: Tristate) => {
    const updates = { ...jxs, [jxKey]: checked };
    JX_KEYS[jxKey as JxSpecial].forEach((key) => {
      updates[key] = checked;
      jxKeyUpdateParent(updates, key);
    });
    jxSetMulti(updates);
  };

  return (
    <Grid container columnSpacing={2} rowSpacing={8}>
      {columns.map(([top, jxObj]) => (
        <JxColumn
          key={`column-${top}`}
          onClear={() => allSet(top, false)}
          onSelect={() => allSet(top, true)}
          title={upperFirst(top.toLowerCase())}
        >
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {Object.entries(SPECIAL[top as JxTopLevel] || {}).map(([court, label]) => (
            <AccordionCheckbox
              id={court}
              isJxFilter
              isRoot
              key={court}
              label={label}
              onClick={jxUpdateSpecial}
              state={jxs}
            />
          ))}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {Object.entries(jxObj || {}).map(([jxId, jxChild]) => (
            <AccordionCheckbox
              childIds={jxChild}
              countMap={jxIdCount}
              id={jxId}
              isJxFilter
              key={jxId}
              isRoot
              labelMap={getJxName}
              onClick={jxKeyUpdate}
              parentId={top}
              state={jxs}
            />
          ))}
        </JxColumn>
      ))}
    </Grid>
  );
};

export default JxSelect;
